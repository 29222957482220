<template>
    <div class="wrapper">
        <NavBar />
        <Menu />
        <!-- Spinner Start -->
        <div id="spinner" v-if="halamanloading"
            class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
            style="opacity: 0.5;">
            <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <!-- Spinner End -->
        <div class="content-wrapper py-5">
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-12 text-center">
                            <h5>Hello, Welcome {{ namauser }}, Selamat Datang di <br>
                                <img src="../../../assets/img/berandaNgumahNew.png" alt="Admin Logo"
                                    class="animation__shake" style="opacity: .8;height: 80px;"> <br>
                                Jumlah Data Total Kerjasama: <span class="text-success">{{ G_numFormat(jumlahdataTotal)
                                    }}</span> <br>
                                Jumlah Total Mitra: <span class="text-success">{{ G_numFormat(jumlahmitra) }}</span>
                            </h5>
                        </div><!-- /.col -->
                    </div><!-- /.row -->
                </div><!-- /.container-fluid -->
            </div>
            <!-- /.content-header -->
            <div class="content">
                <div class="container-fluid">
                    <ul class="nav nav-tabs justify-content-center" id="custom-tabs-four-tab" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" id="custom-tabs-four-home-tab" data-toggle="pill"
                                href="#custom-tabs-four-home" role="tab" aria-controls="custom-tabs-four-home"
                                aria-selected="true">
                                Data Kerjasama Tahun {{ maxtahun }}
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="custom-tabs-four-profile-tab" data-toggle="pill"
                                href="#custom-tabs-four-profile" role="tab" aria-controls="custom-tabs-four-profile"
                                aria-selected="false">
                                Data Kerjasama Berakhir di Tahun {{ maxtahun }}
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="custom-tabs-four-statistik-tab" data-toggle="pill"
                                href="#custom-tabs-four-statistik" role="tab" aria-controls="custom-tabs-four-statistik"
                                aria-selected="false">
                                Statistik
                            </a>
                        </li>
                    </ul>
                    <div class="tab-content" id="custom-tabs-four-tabContent">
                        <div class="tab-pane fade show active" id="custom-tabs-four-home" role="tabpanel"
                            aria-labelledby="custom-tabs-four-home-tab">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="card cardputih collapsed-card">
                                        <div class="card-header border-transparent" data-card-widget="collapse"
                                            style="cursor: pointer;">
                                            <label>Filter Kolom</label>
                                            <div class="card-tools">
                                                <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                                    <i class="fas fa-plus"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="card-body px-3">
                                            <div class="row">
                                                <div class="col-sm-2">
                                                    <div class="custom-control custom-checkbox">
                                                        <input class="custom-control-input custom-control-input-warning"
                                                            type="checkbox" id="customCheckboxTahun" value="optionTahun"
                                                            @click="showColumnTahun = !showColumnTahun" checked>
                                                        <label for="customCheckboxTahun" class="custom-control-label">
                                                            Tahun</label>
                                                    </div>
                                                </div>
                                                <div class="col-sm-2">
                                                    <div class="custom-control custom-checkbox">
                                                        <input class="custom-control-input custom-control-input-warning"
                                                            type="checkbox" id="customCheckboxDaerah"
                                                            value="optionDaerah"
                                                            @click="showColumnDaerah = !showColumnDaerah" checked>
                                                        <label for="customCheckboxDaerah" class="custom-control-label">
                                                            Daerah</label>
                                                    </div>
                                                </div>
                                                <div class="col-sm-2">
                                                    <div class="custom-control custom-checkbox">
                                                        <input class="custom-control-input custom-control-input-warning"
                                                            type="checkbox" id="customCheckboxMitra" value="optionMitra"
                                                            @click="showColumnMitra = !showColumnMitra" checked>
                                                        <label for="customCheckboxMitra" class="custom-control-label">
                                                            Mitra</label>
                                                    </div>
                                                </div>
                                                <div class="col-sm-2">
                                                    <div class="custom-control custom-checkbox">
                                                        <input class="custom-control-input custom-control-input-warning"
                                                            type="checkbox" id="customCheckboxJudul" value="optionJudul"
                                                            @click="showColumnJudul = !showColumnJudul" checked>
                                                        <label for="customCheckboxJudul" class="custom-control-label">
                                                            Judul</label>
                                                    </div>
                                                </div>
                                                <div class="col-sm-2">
                                                    <div class="custom-control custom-checkbox">
                                                        <input class="custom-control-input custom-control-input-warning"
                                                            type="checkbox" id="customCheckboxJenisKerjasama"
                                                            value="optionJenisKerjasama"
                                                            @click="showColumnJenisKerjasama = !showColumnJenisKerjasama"
                                                            checked>
                                                        <label for="customCheckboxJenisKerjasama"
                                                            class="custom-control-label">
                                                            Jenis Kerjasama</label>
                                                    </div>
                                                </div>
                                                <div class="col-sm-2">
                                                    <div class="custom-control custom-checkbox">
                                                        <input class="custom-control-input custom-control-input-warning"
                                                            type="checkbox" id="customCheckboxJenisDokumen"
                                                            value="optionJenisDokumen"
                                                            @click="showColumnJenisDokumen = !showColumnJenisDokumen"
                                                            checked>
                                                        <label for="customCheckboxJenisDokumen"
                                                            class="custom-control-label">
                                                            Jenis Dokumen</label>
                                                    </div>
                                                </div>
                                                <div class="col-sm-2">
                                                    <div class="custom-control custom-checkbox">
                                                        <input class="custom-control-input custom-control-input-warning"
                                                            type="checkbox" id="customCheckboxUrusan"
                                                            value="optionUrusan"
                                                            @click="showColumnUrusan = !showColumnUrusan" checked>
                                                        <label for="customCheckboxUrusan" class="custom-control-label">
                                                            Urusan</label>
                                                    </div>
                                                </div>
                                                <div class="col-sm-2">
                                                    <div class="custom-control custom-checkbox">
                                                        <input class="custom-control-input custom-control-input-warning"
                                                            type="checkbox" id="customCheckboxMulai" value="optionMulai"
                                                            @click="showColumnMulai = !showColumnMulai" checked>
                                                        <label for="customCheckboxMulai" class="custom-control-label">
                                                            Mulai</label>
                                                    </div>
                                                </div>
                                                <div class="col-sm-2">
                                                    <div class="custom-control custom-checkbox">
                                                        <input class="custom-control-input custom-control-input-warning"
                                                            type="checkbox" id="customCheckboxBerakhir"
                                                            value="optionBerakhir"
                                                            @click="showColumnBerakhir = !showColumnBerakhir" checked>
                                                        <label for="customCheckboxBerakhir"
                                                            class="custom-control-label">
                                                            Berakhir</label>
                                                    </div>
                                                </div>
                                                <div class="col-sm-2">
                                                    <div class="custom-control custom-checkbox">
                                                        <input class="custom-control-input custom-control-input-warning"
                                                            type="checkbox" id="customCheckboxJangkaWaktu"
                                                            value="optionJangkaWaktu"
                                                            @click="showColumnJangkaWaktu = !showColumnJangkaWaktu"
                                                            checked>
                                                        <label for="customCheckboxJangkaWaktu"
                                                            class="custom-control-label">
                                                            Jangka Waktu</label>
                                                    </div>
                                                </div>
                                                <div class="col-sm-2">
                                                    <div class="custom-control custom-checkbox">
                                                        <input class="custom-control-input custom-control-input-warning"
                                                            type="checkbox" id="customCheckboxFile" value="optionFile"
                                                            @click="showColumnFile = !showColumnFile" checked>
                                                        <label for="customCheckboxFile" class="custom-control-label">
                                                            File</label>
                                                    </div>
                                                </div>
                                                <div class="col-sm-2">
                                                    <div class="custom-control custom-checkbox">
                                                        <input class="custom-control-input custom-control-input-warning"
                                                            type="checkbox" id="customCheckboxMonev" value="optionMonev"
                                                            @click="showColumnMonev = !showColumnMonev" checked>
                                                        <label for="customCheckboxMonev" class="custom-control-label">
                                                            Monev</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12">
                                    <div class="card cardputih collapsed-card">
                                        <div class="card-header border-transparent" data-card-widget="collapse"
                                            style="cursor: pointer;">
                                            <label>Filter Data Tabel</label>
                                            <div class="card-tools">
                                                <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                                    <i class="fas fa-plus"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="card-body px-3">
                                            <div class="row">
                                                <div class="col-sm-6">
                                                    <label>Daerah</label>
                                                    <v-select :options="daerah" :reduce="(label) => label.code"
                                                        label="label" v-model="defaultSelecteddaerah"
                                                        @update:modelValue="ondaerahChange"
                                                        style="background-color: #ffffff;"></v-select>
                                                </div>
                                                <div class="col-sm-6">
                                                    <label>Mitra</label>
                                                    <input type="text" v-model="carikatamitra"
                                                        class="form-control form-control-sm"
                                                        placeholder="Masukkan Kata Kunci Pencarian Mitra"
                                                        @keyup="ambildata()">
                                                </div>
                                                <div class="col-sm-6">
                                                    <label>Judul</label>
                                                    <input type="text" v-model="carikata"
                                                        class="form-control form-control-sm"
                                                        placeholder="Masukkan Kata Kunci Pencarian Judul"
                                                        @keyup="ambildata()">
                                                </div>
                                                <div class="col-sm-6">
                                                    <label>Jenis Kerjasama</label>
                                                    <v-select :options="jeniskerjasama" :reduce="(label) => label.code"
                                                        label="label" v-model="defaultSelectedjeniskerjasama"
                                                        @update:modelValue="onjeniskerjasamaChange"
                                                        style="background-color: #ffffff;"></v-select>
                                                </div>
                                                <div class="col-sm-6">
                                                    <label>Jenis Dokumen</label>
                                                    <v-select :options="jenisdokumen" :reduce="(label) => label.code"
                                                        label="label" v-model="defaultSelectedjenisdokumen"
                                                        @update:modelValue="onjenisdokumenChange"
                                                        style="background-color: #ffffff;"></v-select>
                                                </div>
                                                <div class="col-sm-6">
                                                    <label>Urusan</label>
                                                    <v-select :options="urusan" :reduce="(label) => label.code"
                                                        label="label" v-model="defaultSelectedurusan"
                                                        @update:modelValue="onurusanChange"
                                                        style="background-color: #ffffff;"></v-select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12">
                                    <div class="card cardputih">
                                        <div class="card-header border-transparent">
                                            <label>Data Kerjasama</label>
                                            <div class="card-tools">
                                                <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                                    <i class="fas fa-minus"></i>
                                                </button>
                                            </div>
                                            <div class="col-sm-12 text-center">
                                                <button type="button" class="btn btn-success btn-sm text-white lebar2"
                                                    @click="G_tableCSV('_DataKerjasama_')" data-toggle="tooltip"
                                                    data-placement="left" title="Unduh Data .xlsx">
                                                    <i class="far fa-file-excel"></i> Unduh Data .xlsx
                                                </button>
                                            </div>
                                            <div class="col-sm-12 float-left pb-2 px-3">
                                                Jumlah Data {{ G_numFormat(jumlahdata) }}
                                                <span style="float: right;">
                                                    <span>Halaman ke: </span>
                                                    <select v-model="halaman" @change="onChangeHalaman($event)">
                                                        <option v-for="index in jumlahhalaman" :key="index">
                                                            {{ index }}</option>
                                                    </select>
                                                    <span> dari </span> {{ jumlahhalaman }}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="card-body p-0">
                                            <div class="table-responsive table-wrapper">
                                                <table class="table m-0" id="tabeldata" style="font-size: smaller;">
                                                    <thead>
                                                        <tr>
                                                            <th style="width: 50px;">No</th>
                                                            <th style="width: 50px;" v-if="showColumnTahun">Tahun</th>
                                                            <th style="width: 150px;" v-if="showColumnDaerah">Daerah
                                                            </th>
                                                            <th style="width: 150px;" v-if="showColumnMitra">Mitra</th>
                                                            <th style="width: 250px;" v-if="showColumnJudul">Judul</th>
                                                            <th style="width: 100px;" v-if="showColumnJenisKerjasama">
                                                                Jenis
                                                                Kerjasama</th>
                                                            <th style="width: 100px;" v-if="showColumnJenisDokumen">
                                                                Jenis
                                                                Dokumen</th>
                                                            <th style="width: 100px;" v-if="showColumnUrusan">Urusan
                                                            </th>
                                                            <th style="width: 100px;" v-if="showColumnMulai">Mulai</th>
                                                            <th style="width: 100px;" v-if="showColumnBerakhir">Berakhir
                                                            </th>
                                                            <th style="width: 100px;" v-if="showColumnJangkaWaktu">
                                                                Jangka Waktu
                                                            </th>
                                                            <th style="width: 30px;" v-if="showColumnFile">File</th>
                                                            <th style="width: 30px;" v-if="showColumnMonev">Monev</th>
                                                        </tr>
                                                    </thead>
                                                    <template v-for="(datalist, urutlist) in datamaster"
                                                        :key="urutlist">
                                                        <tbody>
                                                            <tr>
                                                                <td class="text-center">{{ (urutlist + 1) + (20 *
                                                                    halaman) - 20
                                                                    }}</td>
                                                                <td class="text-center" v-if="showColumnTahun">
                                                                    {{ datalist.tahun }}
                                                                </td>
                                                                <td v-if="showColumnDaerah">
                                                                    {{ datalist.KABKOT }}
                                                                </td>
                                                                <td v-if="showColumnMitra">
                                                                    <span
                                                                        v-if="datalist.pihak2 != '' && datalist.pihak2 != null">Kerjasama
                                                                        Antara </span><span>{{ datalist.pihak1
                                                                        }}</span>&nbsp;<span
                                                                        v-if="datalist.pihak2 != '' && datalist.pihak2 != null">dan
                                                                        {{ datalist.pihak2 }}</span>
                                                                </td>
                                                                <td v-if="showColumnJudul">
                                                                    {{ datalist.judul }}
                                                                </td>
                                                                <td v-if="showColumnJenisKerjasama">
                                                                    {{ datalist.JENISKERJASAMA }}
                                                                </td>
                                                                <td v-if="showColumnJenisDokumen">
                                                                    {{ datalist.JENISDOKUMEN }}
                                                                </td>
                                                                <td v-if="showColumnUrusan">
                                                                    {{ datalist.URUSAN }}
                                                                </td>
                                                                <td v-if="showColumnMulai">
                                                                    {{ G_formatDateNonWIB(datalist.tanggalmulai) }}
                                                                </td>
                                                                <td v-if="showColumnBerakhir">
                                                                    {{ G_formatDateNonWIB(datalist.tanggalselesai) }}
                                                                </td>
                                                                <td v-if="showColumnJangkaWaktu">
                                                                    {{ datalist.jangkawaktu }}
                                                                </td>
                                                                <td v-if="showColumnFile">
                                                                    <a :href="folder_foto + datalist.lampiran"
                                                                        v-if="datalist.lampiran != '' && datalist.lampiran != null">
                                                                        <i class="far fa-file-pdf"></i>
                                                                    </a>
                                                                </td>
                                                                <td v-if="showColumnMonev">
                                                                    <a :href="folder_monev + datalist.filenya_monev"
                                                                        target="_blank"
                                                                        v-if="datalist.filenya_monev != '' && datalist.filenya_monev != null">
                                                                        <i class="far fa-file-image"></i>
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </template>
                                                    <tbody v-if="(datapesan == 'data kosong')">
                                                        <tr>
                                                            <td colspan="4">Data masih kosong</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="card-footer p-3">

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- /.lockscreen-item -->
                        </div>
                        <div class="tab-pane fade" id="custom-tabs-four-profile" role="tabpanel"
                            aria-labelledby="custom-tabs-four-profile-tab">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="card cardputih collapsed-card">
                                        <div class="card-header border-transparent" data-card-widget="collapse"
                                            style="cursor: pointer;">
                                            <label>Filter Kolom</label>
                                            <div class="card-tools">
                                                <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                                    <i class="fas fa-plus"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="card-body px-3">
                                            <div class="row">
                                                <div class="col-sm-2">
                                                    <div class="custom-control custom-checkbox">
                                                        <input class="custom-control-input custom-control-input-warning"
                                                            type="checkbox" id="customCheckboxTahunakhir"
                                                            value="optionTahunakhir"
                                                            @click="showColumnTahunakhir = !showColumnTahunakhir"
                                                            checked>
                                                        <label for="customCheckboxTahunakhir"
                                                            class="custom-control-label">
                                                            Tahun</label>
                                                    </div>
                                                </div>
                                                <div class="col-sm-2">
                                                    <div class="custom-control custom-checkbox">
                                                        <input class="custom-control-input custom-control-input-warning"
                                                            type="checkbox" id="customCheckboxDaerahakhir"
                                                            value="optionDaerahakhir"
                                                            @click="showColumnDaerahakhir = !showColumnDaerahakhir"
                                                            checked>
                                                        <label for="customCheckboxDaerahakhir"
                                                            class="custom-control-label">
                                                            Daerah</label>
                                                    </div>
                                                </div>
                                                <div class="col-sm-2">
                                                    <div class="custom-control custom-checkbox">
                                                        <input class="custom-control-input custom-control-input-warning"
                                                            type="checkbox" id="customCheckboxMitraakhir"
                                                            value="optionMitraakhir"
                                                            @click="showColumnMitraakhir = !showColumnMitraakhir"
                                                            checked>
                                                        <label for="customCheckboxMitraakhir"
                                                            class="custom-control-label">
                                                            Mitra</label>
                                                    </div>
                                                </div>
                                                <div class="col-sm-2">
                                                    <div class="custom-control custom-checkbox">
                                                        <input class="custom-control-input custom-control-input-warning"
                                                            type="checkbox" id="customCheckboxJudulakhir"
                                                            value="optionJudulakhir"
                                                            @click="showColumnJudulakhir = !showColumnJudulakhir"
                                                            checked>
                                                        <label for="customCheckboxJudulakhir"
                                                            class="custom-control-label">
                                                            Judul</label>
                                                    </div>
                                                </div>
                                                <div class="col-sm-2">
                                                    <div class="custom-control custom-checkbox">
                                                        <input class="custom-control-input custom-control-input-warning"
                                                            type="checkbox" id="customCheckboxJenisKerjasamaakhir"
                                                            value="optionJenisKerjasamaakhir"
                                                            @click="showColumnJenisKerjasamaakhir = !showColumnJenisKerjasamaakhir"
                                                            checked>
                                                        <label for="customCheckboxJenisKerjasamaakhir"
                                                            class="custom-control-label">
                                                            Jenis Kerjasama</label>
                                                    </div>
                                                </div>
                                                <div class="col-sm-2">
                                                    <div class="custom-control custom-checkbox">
                                                        <input class="custom-control-input custom-control-input-warning"
                                                            type="checkbox" id="customCheckboxJenisDokumenakhir"
                                                            value="optionJenisDokumenakhir"
                                                            @click="showColumnJenisDokumenakhir = !showColumnJenisDokumenakhir"
                                                            checked>
                                                        <label for="customCheckboxJenisDokumenakhir"
                                                            class="custom-control-label">
                                                            Jenis Dokumen</label>
                                                    </div>
                                                </div>
                                                <div class="col-sm-2">
                                                    <div class="custom-control custom-checkbox">
                                                        <input class="custom-control-input custom-control-input-warning"
                                                            type="checkbox" id="customCheckboxUrusanakhir"
                                                            value="optionUrusanakhir"
                                                            @click="showColumnUrusanakhir = !showColumnUrusanakhir"
                                                            checked>
                                                        <label for="customCheckboxUrusanakhir"
                                                            class="custom-control-label">
                                                            Urusan</label>
                                                    </div>
                                                </div>
                                                <div class="col-sm-2">
                                                    <div class="custom-control custom-checkbox">
                                                        <input class="custom-control-input custom-control-input-warning"
                                                            type="checkbox" id="customCheckboxMulaiakhir"
                                                            value="optionMulaiakhir"
                                                            @click="showColumnMulaiakhir = !showColumnMulaiakhir"
                                                            checked>
                                                        <label for="customCheckboxMulaiakhir"
                                                            class="custom-control-label">
                                                            Mulai</label>
                                                    </div>
                                                </div>
                                                <div class="col-sm-2">
                                                    <div class="custom-control custom-checkbox">
                                                        <input class="custom-control-input custom-control-input-warning"
                                                            type="checkbox" id="customCheckboxBerakhirakhir"
                                                            value="optionBerakhirakhir"
                                                            @click="showColumnBerakhirakhir = !showColumnBerakhirakhir"
                                                            checked>
                                                        <label for="customCheckboxBerakhirakhir"
                                                            class="custom-control-label">
                                                            Berakhir</label>
                                                    </div>
                                                </div>
                                                <div class="col-sm-2">
                                                    <div class="custom-control custom-checkbox">
                                                        <input class="custom-control-input custom-control-input-warning"
                                                            type="checkbox" id="customCheckboxJangkaWaktuakhir"
                                                            value="optionJangkaWaktuakhir"
                                                            @click="showColumnJangkaWaktuakhir = !showColumnJangkaWaktuakhir"
                                                            checked>
                                                        <label for="customCheckboxJangkaWaktuakhir"
                                                            class="custom-control-label">
                                                            Jangka Waktu</label>
                                                    </div>
                                                </div>
                                                <div class="col-sm-2">
                                                    <div class="custom-control custom-checkbox">
                                                        <input class="custom-control-input custom-control-input-warning"
                                                            type="checkbox" id="customCheckboxFileakhir"
                                                            value="optionFileakhir"
                                                            @click="showColumnFileakhir = !showColumnFileakhir" checked>
                                                        <label for="customCheckboxFileakhir"
                                                            class="custom-control-label">
                                                            File</label>
                                                    </div>
                                                </div>
                                                <div class="col-sm-2">
                                                    <div class="custom-control custom-checkbox">
                                                        <input class="custom-control-input custom-control-input-warning"
                                                            type="checkbox" id="customCheckboxMonevakhir"
                                                            value="optionMonevakhir"
                                                            @click="showColumnMonevakhir = !showColumnMonevakhir"
                                                            checked>
                                                        <label for="customCheckboxMonevakhir"
                                                            class="custom-control-label">
                                                            Monev</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12">
                                    <div class="card cardputih collapsed-card">
                                        <div class="card-header border-transparent" data-card-widget="collapse"
                                            style="cursor: pointer;">
                                            <label>Filter Data Tabel</label>
                                            <div class="card-tools">
                                                <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                                    <i class="fas fa-plus"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="card-body px-3">
                                            <div class="row">
                                                <div class="col-sm-6">
                                                    <label>Daerah</label>
                                                    <v-select :options="daerahakhir" :reduce="(label) => label.code"
                                                        label="label" v-model="defaultSelecteddaerahakhir"
                                                        @update:modelValue="ondaerahakhirChange"
                                                        style="background-color: #ffffff;"></v-select>
                                                </div>
                                                <div class="col-sm-6">
                                                    <label>Mitra</label>
                                                    <input type="text" v-model="carikatamitraakhir"
                                                        class="form-control form-control-sm"
                                                        placeholder="Masukkan Kata Kunci Pencarian Mitra"
                                                        @keyup="ambildataakhir()">
                                                </div>
                                                <div class="col-sm-6">
                                                    <label>Judul</label>
                                                    <input type="text" v-model="carikataakhir"
                                                        class="form-control form-control-sm"
                                                        placeholder="Masukkan Kata Kunci Pencarian Judul"
                                                        @keyup="ambildataakhir()">
                                                </div>
                                                <div class="col-sm-6">
                                                    <label>Jenis Kerjasama</label>
                                                    <v-select :options="jeniskerjasamaakhir"
                                                        :reduce="(label) => label.code" label="label"
                                                        v-model="defaultSelectedjeniskerjasamaakhir"
                                                        @update:modelValue="onjeniskerjasamaakhirChange"
                                                        style="background-color: #ffffff;"></v-select>
                                                </div>
                                                <div class="col-sm-6">
                                                    <label>Jenis Dokumen</label>
                                                    <v-select :options="jenisdokumenakhir"
                                                        :reduce="(label) => label.code" label="label"
                                                        v-model="defaultSelectedjenisdokumenakhir"
                                                        @update:modelValue="onjenisdokumenakhirChange"
                                                        style="background-color: #ffffff;"></v-select>
                                                </div>
                                                <div class="col-sm-6">
                                                    <label>Urusan</label>
                                                    <v-select :options="urusanakhir" :reduce="(label) => label.code"
                                                        label="label" v-model="defaultSelectedurusanakhir"
                                                        @update:modelValue="onurusanakhirChange"
                                                        style="background-color: #ffffff;"></v-select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12">
                                    <div class="card cardputih">
                                        <div class="card-header border-transparent">
                                            <label>Data Kerjasama</label>
                                            <div class="card-tools">
                                                <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                                    <i class="fas fa-minus"></i>
                                                </button>
                                            </div>
                                            <div class="col-sm-12 text-center">
                                                <button type="button" class="btn btn-success btn-sm text-white lebar2"
                                                    @click="G_tableCSVAkhir('_DataKerjasamaBerakhir_')"
                                                    data-toggle="tooltip" data-placement="left"
                                                    title="Unduh Data .xlsx">
                                                    <i class="far fa-file-excel"></i> Unduh Data .xlsx
                                                </button>
                                            </div>
                                            <div class="col-sm-12 float-left pb-2 px-3">
                                                Jumlah Data {{ G_numFormat(jumlahdataakhir) }}
                                                <span style="float: right;">
                                                    <span>Halaman ke: </span>
                                                    <select v-model="halamanakhir"
                                                        @change="onChangeHalamanakhir($event)">
                                                        <option v-for="index in jumlahhalamanakhir" :key="index">
                                                            {{ index }}</option>
                                                    </select>
                                                    <span> dari </span> {{ jumlahhalamanakhir }}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="card-body p-0">
                                            <div class="table-responsive table-wrapper">
                                                <table class="table m-0" id="tabeldata" style="font-size: smaller;">
                                                    <thead>
                                                        <tr>
                                                            <th style="width: 50px;">No</th>
                                                            <th style="width: 50px;" v-if="showColumnTahunakhir">Tahun
                                                            </th>
                                                            <th style="width: 150px;" v-if="showColumnDaerahakhir">
                                                                Daerah
                                                            </th>
                                                            <th style="width: 150px;" v-if="showColumnMitraakhir">Mitra
                                                            </th>
                                                            <th style="width: 250px;" v-if="showColumnJudulakhir">Judul
                                                            </th>
                                                            <th style="width: 100px;"
                                                                v-if="showColumnJenisKerjasamaakhir">
                                                                Jenis
                                                                Kerjasama</th>
                                                            <th style="width: 100px;"
                                                                v-if="showColumnJenisDokumenakhir">
                                                                Jenis
                                                                Dokumen</th>
                                                            <th style="width: 100px;" v-if="showColumnUrusanakhir">
                                                                Urusan
                                                            </th>
                                                            <th style="width: 100px;" v-if="showColumnMulaiakhir">Mulai
                                                            </th>
                                                            <th style="width: 100px;" v-if="showColumnBerakhirakhir">
                                                                Berakhir
                                                            </th>
                                                            <th style="width: 100px;" v-if="showColumnJangkaWaktuakhir">
                                                                Jangka Waktu
                                                            </th>
                                                            <th style="width: 30px;" v-if="showColumnFileakhir">File
                                                            </th>
                                                            <th style="width: 30px;" v-if="showColumnMonevakhir">Monev
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <template v-for="(datalist, urutlist) in datamasterakhir"
                                                        :key="urutlist">
                                                        <tbody>
                                                            <tr>
                                                                <td class="text-center">{{ (urutlist + 1) + (20 *
                                                                    halaman) - 20
                                                                    }}</td>
                                                                <td class="text-center" v-if="showColumnTahunakhir">
                                                                    {{ datalist.tahun }}
                                                                </td>
                                                                <td v-if="showColumnDaerahakhir">
                                                                    {{ datalist.KABKOT }}
                                                                </td>
                                                                <td v-if="showColumnMitraakhir">
                                                                    <span
                                                                        v-if="datalist.pihak2 != '' && datalist.pihak2 != null">Kerjasama
                                                                        Antara </span><span>{{ datalist.pihak1
                                                                        }}</span>&nbsp;<span
                                                                        v-if="datalist.pihak2 != '' && datalist.pihak2 != null">dan
                                                                        {{ datalist.pihak2 }}</span>
                                                                </td>
                                                                <td v-if="showColumnJudulakhir">
                                                                    {{ datalist.judul }}
                                                                </td>
                                                                <td v-if="showColumnJenisKerjasamaakhir">
                                                                    {{ datalist.JENISKERJASAMA }}
                                                                </td>
                                                                <td v-if="showColumnJenisDokumenakhir">
                                                                    {{ datalist.JENISDOKUMEN }}
                                                                </td>
                                                                <td v-if="showColumnUrusanakhir">
                                                                    {{ datalist.URUSAN }}
                                                                </td>
                                                                <td v-if="showColumnMulaiakhir">
                                                                    {{ G_formatDateNonWIB(datalist.tanggalmulai) }}
                                                                </td>
                                                                <td v-if="showColumnBerakhirakhir">
                                                                    {{ G_formatDateNonWIB(datalist.tanggalselesai) }}
                                                                </td>
                                                                <td v-if="showColumnJangkaWaktuakhir">
                                                                    {{ datalist.jangkawaktu }}
                                                                </td>
                                                                <td v-if="showColumnFileakhir">
                                                                    <a :href="folder_foto + datalist.lampiran"
                                                                        v-if="datalist.lampiran != '' && datalist.lampiran != null">
                                                                        <i class="far fa-file-pdf"></i>
                                                                    </a>
                                                                </td>
                                                                <td v-if="showColumnMonevakhir">
                                                                    <a :href="folder_monev + datalist.filenya_monev"
                                                                        target="_blank"
                                                                        v-if="datalist.filenya_monev != '' && datalist.filenya_monev != null">
                                                                        <i class="far fa-file-image"></i>
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </template>
                                                    <tbody v-if="(datapesan == 'data kosong')">
                                                        <tr>
                                                            <td colspan="4">Data masih kosong</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="card-footer p-3">

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="custom-tabs-four-statistik" role="tabpanel"
                            aria-labelledby="custom-tabs-four-statistik-tab">
                            <div class="row">
                                <div class="col-sm-12"
                                    style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInUp;">
                                    <BarChartKerjasama type="bar" :options="chartOptions" :series="series" height="500">
                                    </BarChartKerjasama>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import $ from 'jquery';
import axios from 'axios';
import vSelect from 'vue-select';
import xlsx from 'xlsx/dist/xlsx.full.min';
import swal from 'sweetalert2';
import Footer from "../componen/Footer.vue";
import NavBar from "../componen/NavBar.vue";
import Menu from "../componen/Menu.vue";

import VueApexCharts from "vue3-apexcharts";
window.Apex.chart = { fontFamily: "Poppins-SemiBold, Arial, sans-serif" };
export default {
    components: {
        Footer, NavBar, Menu, vSelect, apexchart: VueApexCharts, swal, BarChartKerjasama: VueApexCharts
    },
    data() {
        return {
            halamanloading: true,
            showFilter: true,
            iduser: JSON.parse(localStorage.getItem("usistem")),
            leveluser: JSON.parse(localStorage.getItem("lsistem")),
            namauser: JSON.parse(localStorage.getItem("nmusistem")),
            kewenangan: JSON.parse(localStorage.getItem("kewsistem")),
            d5sistem: JSON.parse(localStorage.getItem("d5sistem")),
            datapesan: '',
            datamaster: [],
            carikata: '',
            maxtahun: new Date().getFullYear(),
            jumlahdataTotal: 0,
            jumlahmitra: 0,
            jumlahdata: 0,
            jumlahhalaman: 0,
            mulai: 0,
            halaman: 1,
            folder_foto: process.env.VUE_APP_URL_API + 'parastapainnovation_produk-PDF?f=',
            folder_monev: process.env.VUE_APP_URL_API + 'parastapainnovation_monev-Gambar?f=',
            showColumnTahun: true,
            showColumnDaerah: true,
            showColumnMitra: true,
            showColumnJudul: true,
            showColumnJenisKerjasama: true,
            showColumnJenisDokumen: true,
            showColumnUrusan: true,
            showColumnMulai: true,
            showColumnBerakhir: true,
            showColumnJangkaWaktu: true,
            showColumnFile: true,
            showColumnMonev: true,
            ipnya: '',

            tahun: [],
            datatahun: [],
            defaultSelectedtahun: {
                code: 0,
                label: 'Semua Data',
                tag: '',
            },
            selectedtahun: '',
            daerah: [],
            datadaerah: [],
            defaultSelecteddaerah: {
                code: 0,
                label: 'Semua Data',
                tag: '',
            },
            selecteddaerah: '',

            carikatamitra: '',

            jeniskerjasama: [],
            datajeniskerjasama: [],
            defaultSelectedjeniskerjasama: {
                code: 0,
                label: 'Semua Data',
                tag: '',
            },
            selectedjeniskerjasama: '',

            jenisdokumen: [],
            datajenisdokumen: [],
            defaultSelectedjenisdokumen: {
                code: 0,
                label: 'Semua Data',
                tag: '',
            },
            selectedjenisdokumen: '',

            urusan: [],
            dataurusan: [],
            defaultSelectedurusan: {
                code: 0,
                label: 'Semua Data',
                tag: '',
            },
            selectedurusan: '',
            tempexcel: [],
            ekportexcel: [],

            datapesanakhir: '',
            datamasterakhir: [],
            carikataakhir: '',
            jumlahdataakhir: 0,
            jumlahhalamanakhir: 0,
            mulaiakhir: 0,
            halamanakhir: 1,
            folder_fotoakhir: process.env.VUE_APP_URL_API + 'parastapainnovation_produk-PDF?f=',
            folder_monevakhir: process.env.VUE_APP_URL_API + 'parastapainnovation_monev-Gambar?f=',
            showColumnTahunakhir: true,
            showColumnDaerahakhir: true,
            showColumnMitraakhir: true,
            showColumnJudulakhir: true,
            showColumnJenisKerjasamaakhir: true,
            showColumnJenisDokumenakhir: true,
            showColumnUrusanakhir: true,
            showColumnMulaiakhir: true,
            showColumnBerakhirakhir: true,
            showColumnJangkaWaktuakhir: true,
            showColumnFileakhir: true,
            showColumnMonevakhir: true,

            tahunakhir: [],
            datatahunakhir: [],
            defaultSelectedtahunakhir: {
                code: 0,
                label: 'Semua Data',
                tag: '',
            },
            selectedtahunakhir: '',
            daerahakhir: [],
            datadaerahakhir: [],
            defaultSelecteddaerahakhir: {
                code: 0,
                label: 'Semua Data',
                tag: '',
            },
            selecteddaerahakhir: '',

            carikatamitraakhir: '',

            jeniskerjasamaakhir: [],
            datajeniskerjasamaakhir: [],
            defaultSelectedjeniskerjasamaakhir: {
                code: 0,
                label: 'Semua Data',
                tag: '',
            },
            selectedjeniskerjasamaakhir: '',

            jenisdokumenakhir: [],
            datajenisdokumenakhir: [],
            defaultSelectedjenisdokumenakhir: {
                code: 0,
                label: 'Semua Data',
                tag: '',
            },
            selectedjenisdokumenakhir: '',

            urusanakhir: [],
            dataurusanakhir: [],
            defaultSelectedurusanakhir: {
                code: 0,
                label: 'Semua Data',
                tag: '',
            },
            selectedurusanakhir: '',
            tempexcelakhir: [],
            ekportexcelakhir: [],
            datapesangrafik: '',
            datagrafik: [],
            series: [],
            chartOptions: {
                chart: {
                    type: 'bar',
                    height: 350
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '55%',
                        endingShape: 'rounded',
                        dataLabels: {
                            position: 'top', // top, center, bottom
                        },
                    },
                },
                dataLabels: {
                    enabled: true,
                    offsetY: -20,
                    style: {
                        fontSize: '5px',
                        fontWeight: 'bold',
                        colors: undefined
                    },
                    background: {
                        enabled: true,
                        foreColor: '#000',
                        padding: 4,
                        borderRadius: 2,
                        borderWidth: 1,
                        borderColor: '#000',
                        opacity: 0.3,
                        dropShadow: {
                            enabled: false,
                            top: 1,
                            left: 1,
                            blur: 1,
                            color: '#000',
                            opacity: 0.45
                        }
                    },
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                xaxis: {
                    categories: [],
                },
                yaxis: {
                    title: {
                        text: 'Jumlah Kerjasama'
                    }
                },
                fill: {
                    opacity: 1
                },
                tooltip: {
                    enabled: true,
                    formatter: function (value) {
                        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                    }
                },
            },
        }
    },
    methods: {
        async G_tableCSVAkhir(namefile) {
            this.ekportexcelakhir = [];
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPI.get("parastapainnovation_kerjasama-AllAkhirKerjasama?random=" + random
                + "&leveluser=" + this.leveluser + "&kewenangan=" + this.kewenangan + "&d5sistem="
                + this.d5sistem + "&iduser=" + this.iduser + "&judul=" + this.carikataakhir
                + "&tahun=" + this.maxtahun + "&daerah=" + this.selecteddaerahakhir + "&mitra=" + this.carikatamitraakhir
                + "&jeniskerjasama=" + this.selectedjeniskerjasamaakhir + "&jenisdokumen=" + this.selectedjenisdokumenakhir
                + "&urusan=" + this.selectedurusanakhir).then(
                    Response => {
                        this.datapesanakhir = Response.data.message;
                        //alert(this.datapesan);
                        if (this.datapesanakhir == 'data diketemukan') {
                            this.tempexcelakhir = [];
                            this.tempexcelakhir = Response.data.content.data;
                            for (let i = 0; i < this.tempexcelakhir.length; i++) {
                                this.ekportexcelakhir.push({
                                    "No": i + 1,
                                    "Tahun": this.tempexcelakhir[i].tahun,
                                    "Daerah": this.tempexcelakhir[i].KABKOT,
                                    "Mitra": this.tempexcelakhir[i].pihak1,
                                    "Judul": this.tempexcelakhir[i].judul,
                                    "Jenis Kerjasama": this.tempexcelakhir[i].JENISKERJASAMA,
                                    "Jenis Dokumen": this.tempexcelakhir[i].JENISDOKUMEN,
                                    "Urusan": this.tempexcelakhir[i].URUSAN,
                                    "Mulai": this.tempexcelakhir[i].tanggalmulai,
                                    "Berakhir": this.tempexcelakhir[i].tanggalselesai,
                                    "Jangka Waktu": this.tempexcelakhir[i].jangkawaktu,
                                });
                            }
                            function formatColumn(worksheet, col, fmt) {
                                const range = XLSX.utils.decode_range(worksheet['!ref'])
                                // note: range.s.r + 1 skips the header row
                                for (let row = range.s.r + 1; row <= range.e.r; ++row) {
                                    const ref = XLSX.utils.encode_cell({ r: row, c: col })
                                    if (worksheet[ref] && worksheet[ref].t === 'n') {
                                        worksheet[ref].z = fmt
                                    }
                                }
                            }
                            const currency = '0.00';
                            const XLSX = xlsx;
                            const workbook = XLSX.utils.book_new();
                            const worksheet = XLSX.utils.json_to_sheet(this.ekportexcelakhir);

                            // ARRAY NUMBER SILAHKAN MASUKKAN NOMOR KOLOM
                            for (let col of [0, 1]) {
                                formatColumn(worksheet, col, currency)
                            }
                            XLSX.utils.book_append_sheet(workbook, worksheet, "Data Kerjasama Berakhir");
                            XLSX.writeFile(workbook, this.G_formatDate(Date(), 'd-M-Y') + namefile + ".xlsx");
                            this.halamanloading = false;
                        }
                        else {
                            swal.fire('Peringatan', 'Jumlah data yang hendak diunduh terlalu banyak, Pilih data dengan jumlah yang lebih kecil', 'error');
                        }
                        this.halamanloading = false;
                    }
                ).catch(error => {
                    console.log(error);
                    swal.fire('Peringatan', 'Jumlah data yang hendak diunduh terlalu banyak, Pilih data dengan jumlah yang lebih kecil', 'error');
                    this.halamanloading = false;
                });
            //console.log(this.datamaster);
            //return false;
        },
        async G_tableCSV(namefile) {
            this.ekportexcel = [];
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPI.get("parastapainnovation_kerjasama-GetAll?random=" + random + "&leveluser="
                + this.leveluser + "&kewenangan=" + this.kewenangan
                + "&d5sistem=" + this.d5sistem + "&iduser=" + this.iduser + "&judul=" + this.carikata
                + "&tahun=" + this.maxtahun + "&daerah=" + this.selecteddaerah + "&mitra=" + this.carikatamitra
                + "&jeniskerjasama=" + this.selectedjeniskerjasama + "&jenisdokumen=" + this.selectedjenisdokumen
                + "&urusan=" + this.selectedurusan
            ).then(
                Response => {
                    this.datapesan = Response.data.message;
                    //alert(this.datapesan);
                    if (this.datapesan == 'data diketemukan') {
                        this.tempexcel = [];
                        this.tempexcel = Response.data.content.data;
                        for (let i = 0; i < this.tempexcel.length; i++) {
                            this.ekportexcel.push({
                                "No": i + 1,
                                "Tahun": this.tempexcel[i].tahun,
                                "Daerah": this.tempexcel[i].KABKOT,
                                "Mitra": this.tempexcel[i].pihak1,
                                "Judul": this.tempexcel[i].judul,
                                "Jenis Kerjasama": this.tempexcel[i].JENISKERJASAMA,
                                "Jenis Dokumen": this.tempexcel[i].JENISDOKUMEN,
                                "Urusan": this.tempexcel[i].URUSAN,
                                "Mulai": this.tempexcel[i].tanggalmulai,
                                "Berakhir": this.tempexcel[i].tanggalselesai,
                                "Jangka Waktu": this.tempexcel[i].jangkawaktu,
                            });
                        }
                        function formatColumn(worksheet, col, fmt) {
                            const range = XLSX.utils.decode_range(worksheet['!ref'])
                            // note: range.s.r + 1 skips the header row
                            for (let row = range.s.r + 1; row <= range.e.r; ++row) {
                                const ref = XLSX.utils.encode_cell({ r: row, c: col })
                                if (worksheet[ref] && worksheet[ref].t === 'n') {
                                    worksheet[ref].z = fmt
                                }
                            }
                        }
                        const currency = '0.00';
                        const XLSX = xlsx;
                        const workbook = XLSX.utils.book_new();
                        const worksheet = XLSX.utils.json_to_sheet(this.ekportexcel);

                        // ARRAY NUMBER SILAHKAN MASUKKAN NOMOR KOLOM
                        for (let col of [0, 1]) {
                            formatColumn(worksheet, col, currency)
                        }
                        XLSX.utils.book_append_sheet(workbook, worksheet, "Data Kerjasama");
                        XLSX.writeFile(workbook, this.G_formatDate(Date(), 'd-M-Y') + namefile + ".xlsx");
                        this.halamanloading = false;
                    }
                    else {
                        swal.fire('Peringatan', 'Jumlah data yang hendak diunduh terlalu banyak, Pilih data dengan jumlah yang lebih kecil', 'error');
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', 'Jumlah data yang hendak diunduh terlalu banyak, Pilih data dengan jumlah yang lebih kecil', 'error');
                this.halamanloading = false;
            });
            //console.log(this.datamaster);
            //return false;
        },
        toggleShow() {
            this.showFilter = !this.showFilter;
        },
        getOtentifikasi() {
            var time = Date.parse(localStorage.getItem("tsistem"));
            var timeakhir = time + (3 * 60 * 60 * 1000);
            var timesekarang = Date.parse(new Date());
            if (timesekarang > timeakhir) {
                this.ClearlocalStorage();
            }
        },
        onChangeHalaman(event) {
            this.pindahhalaman(event.target.value);
        },
        async pindahhalaman(halamannya) {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPI.get("parastapainnovation_kerjasama-GetKerjasama?random=" + random + "&halaman=" + halamannya
                + "&leveluser=" + this.leveluser + "&kewenangan=" + this.kewenangan + "&d5sistem="
                + this.d5sistem + "&iduser=" + this.iduser + "&judul=" + this.carikata
                + "&tahun=" + this.maxtahun + "&daerah=" + this.selecteddaerah + "&mitra=" + this.carikatamitra
                + "&jeniskerjasama=" + this.selectedjeniskerjasama + "&jenisdokumen=" + this.selectedjenisdokumen
                + "&urusan=" + this.selectedurusan).then(
                    Response => {
                        this.datapesan = Response.data.message;
                        //alert(this.datapesan);
                        if (this.datapesan == 'data diketemukan') {
                            this.datamaster = [];
                            this.datamaster = Response.data.content.data;
                            this.jumlahdata = Response.data.content.jumlahdata;
                            this.jumlahhalaman = Response.data.content.jumlahhalaman;
                            this.mulai = Response.data.content.mulai;
                            if (this.mulai == 0) {
                                this.halaman = 1;
                            }
                            else {
                                this.halaman = (this.mulai / 20) + 1;
                            }
                        }
                        else {
                            this.datamaster = [];
                            this.jumlahdata = 0;
                            this.jumlahhalaman = 0;
                            this.mulai = 0;
                        }
                        this.halamanloading = false;
                    }
                ).catch(error => {
                    //console.log(error);
                    //this.ClearlocalStorage();
                    this.halamanloading = false;
                });
        },
        onChangeHalamanakhir(event) {
            this.pindahhalamanakhir(event.target.value);
        },
        async pindahhalamanakhir(halamannya) {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPI.get("parastapainnovation_kerjasama-GetAkhirKerjasama?random=" + random + "&halaman=" + halamannya
                + "&leveluser=" + this.leveluser + "&kewenangan=" + this.kewenangan + "&d5sistem="
                + this.d5sistem + "&iduser=" + this.iduser + "&judul=" + this.carikataakhir
                + "&tahun=" + this.maxtahun + "&daerah=" + this.selecteddaerahakhir + "&mitra=" + this.carikatamitraakhir
                + "&jeniskerjasama=" + this.selectedjeniskerjasamaakhir + "&jenisdokumen=" + this.selectedjenisdokumenakhir
                + "&urusan=" + this.selectedurusanakhir).then(
                    Response => {
                        this.datapesanakhir = Response.data.message;
                        //alert(this.datapesan);
                        if (this.datapesanakhir == 'data diketemukan') {
                            this.datamasterakhir = [];
                            this.datamasterakhir = Response.data.content.data;
                            this.jumlahdataakhir = Response.data.content.jumlahdata;
                            this.jumlahhalamanakhir = Response.data.content.jumlahhalaman;
                            this.mulaiakhir = Response.data.content.mulai;
                            if (this.mulaiakhir == 0) {
                                this.halamanakhir = 1;
                            }
                            else {
                                this.halamanakhir = (this.mulai / 20) + 1;
                            }
                        }
                        else {
                            this.datamasterakhir = [];
                            this.jumlahdataakhir = 0;
                            this.jumlahhalamanakhir = 0;
                            this.mulaiakhir = 0;
                        }
                        this.halamanloading = false;
                    }
                ).catch(error => {
                    //console.log(error);
                    //this.ClearlocalStorage();
                    this.halamanloading = false;
                });
        },

        async ambildatareff() {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            var random = Math.random();

            await mainAPI.get("parastapainnovationip-Key?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.ipnya = Response.data.randkey;
                    }
                    else {
                        this.ipnya = '';
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datapesan = [];
                console.log(error);
            });
            this.halamanloading = false;
            this.halamanloading = true;
            const mainAPI2 = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2" + this.ipnya,
                },
            });
            var random = Math.random();
            await mainAPI2.get("parastapainnovationreff-GetTahun?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datatahun = [];
                        this.tahun = [];
                        this.datatahun = Response.data.content.data;
                        this.datatahun.forEach((item) => {
                            this.tahun.push({
                                label: item.tahun,
                                code: this.datatahun.indexOf(item) + 1,
                                tag: item.tahun,
                            });
                        });
                        this.tahun.unshift({
                            label: 'Semua Data',
                            code: 0,
                            tag: '',
                        });


                        this.datatahunakhir = [];
                        this.tahunakhir = [];
                        this.datatahunakhir = Response.data.content.data;
                        this.datatahunakhir.forEach((item) => {
                            this.tahunakhir.push({
                                label: item.tahun,
                                code: this.datatahunakhir.indexOf(item) + 1,
                                tag: item.tahun,
                            });
                        });
                        this.tahunakhir.unshift({
                            label: 'Semua Data',
                            code: 0,
                            tag: '',
                        });
                    }
                    else {
                        this.datatahun = [];
                        this.tahun = [];
                        this.datatahunakhir = [];
                        this.tahunakhir = [];
                    }
                    this.halamanloading = false;

                }
            ).catch(error => {
                this.datatahun = [];
                this.tahun = [];
                this.datatahunakhir = [];
                this.tahunakhir = [];
                this.halamanloading = false;
            });

            await mainAPI2.get("parastapainnovationreff-GetKabkotdanJateng?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datadaerah = [];
                        this.daerah = [];
                        this.datadaerah = Response.data.content.data;
                        this.datadaerah.forEach((item) => {
                            this.daerah.push({
                                label: item.NAMA,
                                code: this.datadaerah.indexOf(item) + 1,
                                tag: item.KD_KAB,
                            });
                        });
                        this.daerah.unshift({
                            label: 'Semua Data',
                            code: 0,
                            tag: '',
                        });

                        this.datadaerahakhir = [];
                        this.daerahakhir = [];
                        this.datadaerahakhir = Response.data.content.data;
                        this.datadaerahakhir.forEach((item) => {
                            this.daerahakhir.push({
                                label: item.NAMA,
                                code: this.datadaerahakhir.indexOf(item) + 1,
                                tag: item.KD_KAB,
                            });
                        });
                        this.daerahakhir.unshift({
                            label: 'Semua Data',
                            code: 0,
                            tag: '',
                        });
                    }
                    else {
                        this.datadaerah = [];
                        this.daerah = [];
                        this.datadaerahakhir = [];
                        this.daerahakhir = [];
                    }
                    this.halamanloading = false;

                }
            ).catch(error => {
                this.datadaerah = [];
                this.daerah = [];
                this.datadaerahakhir = [];
                this.daerahakhir = [];
                this.halamanloading = false;
            });

            await mainAPI2.get("parastapainnovationreff-GetJenisKerjasama?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datajeniskerjasama = [];
                        this.jeniskerjasama = [];
                        this.datajeniskerjasama = Response.data.content.data;
                        this.datajeniskerjasama.forEach((item) => {
                            this.jeniskerjasama.push({
                                label: item.nama,
                                code: this.datajeniskerjasama.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.jeniskerjasama.unshift({
                            label: 'Semua Data',
                            code: 0,
                            tag: '',
                        });

                        this.datajeniskerjasamaakhir = [];
                        this.jeniskerjasamaakhir = [];
                        this.datajeniskerjasamaakhir = Response.data.content.data;
                        this.datajeniskerjasamaakhir.forEach((item) => {
                            this.jeniskerjasamaakhir.push({
                                label: item.nama,
                                code: this.datajeniskerjasamaakhir.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.jeniskerjasamaakhir.unshift({
                            label: 'Semua Data',
                            code: 0,
                            tag: '',
                        });
                    }
                    else {
                        this.datajeniskerjasama = [];
                        this.jeniskerjasama = [];
                        this.datajeniskerjasamaakhir = [];
                        this.jeniskerjasamaakhir = [];
                    }
                    this.halamanloading = false;

                }
            ).catch(error => {
                this.datajeniskerjasama = [];
                this.jeniskerjasama = [];
                this.datajeniskerjasamaakhir = [];
                this.jeniskerjasamaakhir = [];
                this.halamanloading = false;
            });

            await mainAPI2.get("parastapainnovationreff-GetJenisDokumen?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datajenisdokumen = [];
                        this.jenisdokumen = [];
                        this.datajenisdokumen = Response.data.content.data;
                        this.datajenisdokumen.forEach((item) => {
                            this.jenisdokumen.push({
                                label: item.nama,
                                code: this.datajenisdokumen.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.jenisdokumen.unshift({
                            label: 'Semua Data',
                            code: 0,
                            tag: '',
                        });

                        this.datajenisdokumenakhir = [];
                        this.jenisdokumenakhir = [];
                        this.datajenisdokumenakhir = Response.data.content.data;
                        this.datajenisdokumenakhir.forEach((item) => {
                            this.jenisdokumenakhir.push({
                                label: item.nama,
                                code: this.datajenisdokumenakhir.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.jenisdokumenakhir.unshift({
                            label: 'Semua Data',
                            code: 0,
                            tag: '',
                        });
                    }
                    else {
                        this.datajenisdokumen = [];
                        this.jenisdokumen = [];
                        this.datajenisdokumenakhir = [];
                        this.jenisdokumenakhir = [];
                    }
                    this.halamanloading = false;

                }
            ).catch(error => {
                this.datajenisdokumen = [];
                this.jenisdokumen = [];
                this.datajenisdokumenakhir = [];
                this.jenisdokumenakhir = [];
                this.halamanloading = false;
            });

            await mainAPI2.get("parastapainnovationreff-GetUrusan?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.dataurusan = [];
                        this.urusan = [];
                        this.dataurusan = Response.data.content.data;
                        this.dataurusan.forEach((item) => {
                            this.urusan.push({
                                label: item.nama,
                                code: this.dataurusan.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.urusan.unshift({
                            label: 'Semua Data',
                            code: 0,
                            tag: '',
                        });


                        this.dataurusanakhir = [];
                        this.urusanakhir = [];
                        this.dataurusanakhir = Response.data.content.data;
                        this.dataurusanakhir.forEach((item) => {
                            this.urusanakhir.push({
                                label: item.nama,
                                code: this.dataurusanakhir.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.urusanakhir.unshift({
                            label: 'Semua Data',
                            code: 0,
                            tag: '',
                        });
                    }
                    else {
                        this.dataurusan = [];
                        this.urusan = [];
                        this.dataurusanakhir = [];
                        this.urusanakhir = [];
                    }
                    this.halamanloading = false;

                }
            ).catch(error => {
                this.dataurusan = [];
                this.urusan = [];
                this.dataurusanakhir = [];
                this.urusanakhir = [];
                this.halamanloading = false;
            });
        },
        ontahunChange(a) {
            try {
                this.selectedtahun = this.tahun[a].tag;
            }
            catch (err) {
                return;
            }
            this.ambildata();
        },
        ontahunakhirChange(a) {
            try {
                this.selectedtahunakhir = this.tahunakhir[a].tag;
            }
            catch (err) {
                return;
            }
            this.ambildataakhir();
        },
        ondaerahChange(a) {
            try {
                this.selecteddaerah = this.daerah[a].tag;
            }
            catch (err) {
                return;
            }
            this.ambildata();
        },
        ondaerahakhirChange(a) {
            try {
                this.selecteddaerahakhir = this.daerahakhir[a].tag;
            }
            catch (err) {
                return;
            }
            this.ambildataakhir();
        },
        onjeniskerjasamaChange(a) {
            try {
                this.selectedjeniskerjasama = this.jeniskerjasama[a].tag;
            }
            catch (err) {
                return;
            }
            this.ambildata();
        },
        onjeniskerjasamaakhirChange(a) {
            try {
                this.selectedjeniskerjasamaakhir = this.jeniskerjasamaakhir[a].tag;
            }
            catch (err) {
                return;
            }
            this.ambildataakhir();
        },
        onjenisdokumenChange(a) {
            try {
                this.selectedjenisdokumen = this.jenisdokumen[a].tag;
            }
            catch (err) {
                return;
            }
            this.ambildata();
        },
        onjenisdokumenakhirChange(a) {
            try {
                this.selectedjenisdokumenakhir = this.jenisdokumenakhir[a].tag;
            }
            catch (err) {
                return;
            }
            this.ambildataakhir();
        },
        onurusanChange(a) {
            try {
                this.selectedurusan = this.urusan[a].tag;
            }
            catch (err) {
                return;
            }
            this.ambildata();
        },
        onurusanakhirChange(a) {
            try {
                this.selectedurusanakhir = this.urusanakhir[a].tag;
            }
            catch (err) {
                return;
            }
            this.ambildataakhir();
        },
        async ambildata() {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPI.get("parastapainnovation_kerjasama-GetKerjasama?random=" + random + "&leveluser="
                + this.leveluser + "&kewenangan=" + this.kewenangan
                + "&d5sistem=" + this.d5sistem + "&iduser=" + this.iduser + "&judul=" + this.carikata
                + "&tahun=" + this.selectedtahun + "&daerah=" + this.selecteddaerah + "&mitra=" + this.carikatamitra
                + "&jeniskerjasama=" + this.selectedjeniskerjasama + "&jenisdokumen=" + this.selectedjenisdokumen
                + "&urusan=" + this.selectedurusan).then(
                    Response => {
                        this.datapesan = Response.data.message;
                        if (this.datapesan == 'data diketemukan') {
                            this.jumlahdataTotal = Response.data.content.jumlahdata;
                            this.jumlahmitra = Response.data.content.jumlahmitra;
                            this.maxtahun = Response.data.content.maxtahun;

                            this.halamanloading = true;
                            const mainAPI2 = axios.create({
                                baseURL: process.env.VUE_APP_URL_API,
                                headers: {
                                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                                },
                            });
                            var random = Math.random();
                            mainAPI2.get("parastapainnovation_kerjasama-GetKerjasama?random=" + random + "&leveluser="
                                + this.leveluser + "&kewenangan=" + this.kewenangan
                                + "&d5sistem=" + this.d5sistem + "&iduser=" + this.iduser + "&judul=" + this.carikata
                                + "&tahun=" + this.maxtahun + "&daerah=" + this.selecteddaerah + "&mitra=" + this.carikatamitra
                                + "&jeniskerjasama=" + this.selectedjeniskerjasama + "&jenisdokumen=" + this.selectedjenisdokumen
                                + "&urusan=" + this.selectedurusan).then(
                                    Response => {
                                        this.datapesan = Response.data.message;
                                        if (this.datapesan == 'data diketemukan') {
                                            this.datamaster = [];
                                            this.datamaster = Response.data.content.data;
                                            this.jumlahdata = Response.data.content.jumlahdata;
                                            this.jumlahhalaman = Response.data.content.jumlahhalaman;
                                            this.mulai = Response.data.content.mulai;
                                            if (this.mulai == 0) {
                                                this.halaman = 1;
                                            }
                                            else {
                                                this.halaman = (this.mulai / 20) + 1;
                                            }
                                            //alert(this.maxtahun);
                                        }
                                        else {
                                            this.datamaster = [];
                                            this.jumlahdata = 0;
                                            this.jumlahhalaman = 0;
                                            this.mulai = 0;
                                        }
                                        this.halamanloading = false;
                                    }
                                ).catch(error => {
                                    console.log(error);
                                    this.ClearlocalStorage();
                                    this.halamanloading = false;
                                });
                            //alert(this.maxtahun);
                        }
                        else {
                            this.jumlahdataTotal = 0;
                            this.jumlahmitra = 0;
                        }
                        this.halamanloading = false;
                    }
                ).catch(error => {
                    console.log(error);
                    this.ClearlocalStorage();
                    this.halamanloading = false;
                });
            this.ambildataakhir();
        },
        async ambildataakhir() {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPI.get("parastapainnovation_kerjasama-GetAkhirKerjasama?random=" + random + "&leveluser="
                + this.leveluser + "&kewenangan=" + this.kewenangan
                + "&d5sistem=" + this.d5sistem + "&iduser=" + this.iduser + "&judul=" + this.carikataakhir
                + "&tahun=" + this.maxtahun + "&daerah=" + this.selecteddaerahakhir + "&mitra=" + this.carikatamitraakhir
                + "&jeniskerjasama=" + this.selectedjeniskerjasamaakhir + "&jenisdokumen=" + this.selectedjenisdokumenakhir
                + "&urusan=" + this.selectedurusanakhir).then(
                    Response => {
                        this.datapesanakhir = Response.data.message;
                        //alert(this.datapesanakhir);
                        if (this.datapesanakhir == 'data diketemukan') {
                            this.datamasterakhir = [];
                            this.datamasterakhir = Response.data.content.data;
                            this.jumlahdataakhir = Response.data.content.jumlahdata;
                            this.jumlahhalamanakhir = Response.data.content.jumlahhalaman;
                            this.mulaiakhir = Response.data.content.mulai;
                            if (this.mulaiakhir == 0) {
                                this.halamanakhir = 1;
                            }
                            else {
                                this.halamanakhir = (this.mulaiakhir / 20) + 1;
                            }
                            //alert(this.maxtahun);
                        }
                        else {
                            this.datamasterakhir = [];
                            this.jumlahdataakhir = 0;
                            this.jumlahhalamanakhir = 0;
                            this.mulaiakhir = 0;
                        }
                        this.halamanloading = false;
                    }
                ).catch(error => {
                    console.log(error);
                    this.ClearlocalStorage();
                    this.halamanloading = false;
                });
            this.ambildatagrafik();
        },
        async ambildatagrafik() {
            var kodekabkot = '';
            var kewenangan = ''
            if (this.leveluser == '1') {
                kodekabkot = '';
                kewenangan = ''
            }
            else {
                kodekabkot = this.kewenangan;
                kewenangan = this.kewenangan;
            }

            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            var random = Math.random();
            //alert(this.kewenangan);

            await mainAPI.get("parastapainnovationip-Key?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.ipnya = Response.data.randkey;
                    }
                    else {
                        this.ipnya = '';
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datapesan = "";
                console.log(error);
            });

            if (this.leveluser == '1') {
                const mainAPINew = axios.create({
                    baseURL: process.env.VUE_APP_URL_API,
                    headers: {
                        Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2" + this.ipnya,
                    },
                });
                await mainAPINew.get("sikejam-Grafik?random=" + random).then(
                    Response => {
                        this.series = [];
                        var datagrafik = [];
                        var seriestahun = [];
                        var datajumlah = [];
                        this.datapesangrafik = Response.data.message;
                        //alert(this.datapesangrafik);
                        this.chartOptions = {
                            xaxis: {
                                categories: [],
                            },
                        };
                        if (this.datapesangrafik == 'data diketemukan') {
                            datagrafik = Response.data.content.tahun;
                            datajumlah = Response.data.content.data;
                            for (let i = 0; i < datagrafik.length; i++) {
                                seriestahun.push(datagrafik[i].TAHUN);
                            }
                            this.chartOptions = {
                                xaxis: {
                                    categories: seriestahun,
                                },
                            };
                            //alert(JSON.stringify(datajumlah));
                            for (let i = 0; i < datajumlah.length; i++) {
                                this.series.push({
                                    name: datajumlah[i].Jenis,
                                    data: datajumlah[i].Jumlah,
                                });
                            }
                        }
                        else {
                            this.datapesangrafik = "";
                            this.series = [];
                        }
                        this.halamanloading = false;
                    }
                ).catch(error => {
                    console.log(error);
                });
            }
            else {
                const mainAPINew = axios.create({
                    baseURL: process.env.VUE_APP_URL_API,
                    headers: {
                        Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2" + this.ipnya,
                    },
                });
                await mainAPINew.get("sikejam-Grafik?random=" + random + "&kodekabkot=" + kodekabkot + "&leveluser=" + this.leveluser + "&d5sistem=" + this.d5sistem + "&kewenangan=" + kewenangan).then(
                    Response => {
                        this.series = [];
                        var datagrafik = [];
                        var seriestahun = [];
                        var datajumlah = [];
                        this.datapesangrafik = Response.data.message;
                        //alert(this.datapesangrafik);
                        this.chartOptions = {
                            xaxis: {
                                categories: [],
                            },
                        };
                        if (this.datapesangrafik == 'data diketemukan') {
                            datagrafik = Response.data.content.tahun;
                            datajumlah = Response.data.content.data;
                            for (let i = 0; i < datagrafik.length; i++) {
                                seriestahun.push(datagrafik[i].TAHUN);
                            }
                            this.chartOptions = {
                                xaxis: {
                                    categories: seriestahun,
                                },
                            };
                            //alert(JSON.stringify(datajumlah));
                            for (let i = 0; i < datajumlah.length; i++) {
                                this.series.push({
                                    name: datajumlah[i].Jenis,
                                    data: datajumlah[i].Jumlah,
                                });
                            }
                        }
                        else {
                            this.datapesangrafik = "";
                            this.series = [];
                        }
                        this.halamanloading = false;
                    }
                ).catch(error => {
                    console.log(error);
                });
            }
        },
    },
    mounted() {
        this.getOtentifikasi();
        //alert(this.leveluser);
        this.ambildatareff();
        this.ambildata();
        this.ambildatagrafik();
    }
}
</script>
<style></style>