<template>
    <div class="wrapper">
        <NavBar />
        <Menu />
        <!-- Spinner Start -->
        <div id="spinner" v-if="halamanloading"
            class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
            style="opacity: 0.5;">
            <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <!-- Spinner End -->
        <div class="content-wrapper py-3">
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-3 wow fadeInDown">
                            <h5>Pengaturan</h5>
                        </div>
                        <div class="col-sm-9 wow fadeInDown">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a href="/Dashboard">Home </a></li>
                                <li class="breadcrumb-item"><a href="/PengajuanKerjasama">Data Pengajuan Kerjasama</a>
                                </li>
                                <li class="breadcrumb-item active">Pengaturan Data Pengajuan Kerjasama</li>
                            </ol>
                        </div>
                    </div><!-- /.row -->
                </div><!-- /.container-fluid -->
            </div>
            <!-- /.content-header -->
            <div class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-12 wow fadeInDown">
                            <div class="card cardputih2">
                                <div class="card-header border-transparent">
                                    <h3 class="card-title">Form Update Data Pengajuan Kerjasama</h3>
                                    <div class="card-tools">
                                        <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                            <i class="fas fa-minus"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="card-body p-3">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <label>1. Tahun Permohonan: <span class="text-success">{{ tahun }}</span></label>
                                        </div>
                                        <div class="col-sm-12">
                                            <label>2. Nama Instansi Pemohon: <span class="text-success">{{ nama }}</span></label>
                                        </div>
                                        <div class="col-sm-12">
                                            <label>3. Alamat Instansi Pemohon: <span class="text-success">{{ alamat }}</span></label>
                                        </div>
                                        <div class="col-sm-12">
                                            <label>4. Provinsi: <span class="text-success">{{ NAMAPROVINSI }}</span></label>
                                        </div>
                                        <div class="col-sm-12">
                                            <label>5. Kabupaten / Kota: <span class="text-success">{{ NAMAKAB_KOTA }}</span></label>
                                        </div>
                                        <div class="col-sm-12">
                                            <label>6. Nomor Telp. Instansi Pemohon: <span class="text-success">{{ telp }}</span></label>
                                        </div>
                                        <div class="col-sm-12">
                                            <label>7. Email Instansi Pemohon: <span class="text-success">{{ email }}</span></label>
                                        </div>
                                        <div class="col-sm-12">
                                            <label>8. Nama Pemohon: <span class="text-success">{{ namapemohon }}</span></label>
                                        </div>
                                        <div class="col-sm-12">
                                            <label>9. Jabatan Pemohon: <span class="text-success">{{ jabatanpemohon }}</span></label>
                                        </div>
                                        <div class="col-sm-12">
                                            <label>10. Unit Kerja Pemohon: <span class="text-success">{{ unitkerjapemohon }}</span></label>
                                        </div>
                                        <div class="col-sm-12">
                                            <label>11. Nomor Telp. Pemohon: <span class="text-success">{{ nomortelppemohon }}</span></label>
                                        </div>
                                        <div class="col-sm-12">
                                            <label>12. Rencana Judul Kerjasama: <span class="text-success">{{ kegiatan }}</span></label>
                                        </div>
                                        <div class="col-sm-12">
                                            <label>13. Dinas / OPD Pelaksana Kerjasama: <span class="text-success">{{ namaOPD }}</span></label>
                                        </div>
                                        <div class="col-sm-12">
                                            <label>14. Lampiran:</label>
                                        </div>
                                        <div class="col-sm-12">
                                            <span v-if="filenya_permohonan != ''">
                                                <a :href="folder_permohonan + filenya_permohonan"
                                                    v-if="filenya_permohonan != '' && filenya_permohonan != null">
                                                    &nbsp;<i class="far fa-file-pdf"></i> Lihat Lampiran Surat Permohonan
                                                </a>
                                            </span>
                                        </div>
                                        <div class="col-sm-12">
                                            <span v-if="filenya_kak != ''">
                                                <a :href="folder_kak + filenya_kak"
                                                    v-if="filenya_kak != '' && filenya_kak != null">
                                                    &nbsp;<i class="far fa-file-pdf"></i> Lihat Lampiran KAK / Studi
                                                </a>
                                            </span>
                                        </div>
                                        <div class="col-sm-12">&nbsp;</div>
                                        <div class="col-sm-12">
                                            <label>15. Status Pengajuan Kerjasama
                                                <small class="text-info"> (*wajib dipilih)</small>
                                            </label>
                                            <v-select :options="jeniskerjasama" :reduce="(label) => label.code"
                                                label="label" v-model="defaultSelectedjeniskerjasama"
                                                @update:modelValue="onjeniskerjasamaChange"
                                                style="background-color: #ffffff;padding-bottom: 10px;"></v-select>
                                        </div>
                                        <div class="col-sm-12">
                                            <label>16. Keterangan</label>
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <quill-editor v-model:value="keterangan"
                                                        style="height: 25vh;background-color: #ffffff;">
                                                    </quill-editor>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-footer p-3">
                                    <button type="button" class="btn btn-sm btn-success3 float-left text-white lebar2"
                                        v-if="(secretencData != 'Tambah')" @click="update()" data-toggle="tooltip"
                                        data-placement="left" title="Update data.."><i class="fa fa-check-circle"></i>
                                        Update</button>
                                    <button type="button" class="btn btn-sm btn-danger3 float-left text-white lebar2"
                                        @click="batalproses()" data-toggle="tooltip" data-placement="left"
                                        title="Batalkan proses.."><i class="fas fa-window-close"></i> Batal</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid">&nbsp;</div>
        <div class="container-fluid">&nbsp;</div>
        <Footer />
    </div>
</template>

<script>
import $ from 'jquery';
import axios from 'axios';
import vSelect from 'vue-select';
import swal from 'sweetalert2';
import CryptoJS from 'crypto-js';
import { useRoute } from 'vue-router';
import { watch } from 'vue';
import { quillEditor } from 'vue3-quill';
import Footer from "../../componen/Footer.vue";
import NavBar from "../../componen/NavBar.vue";
import Menu from "../../componen/Menu.vue";

import VueApexCharts from "vue3-apexcharts";
window.Apex.chart = { fontFamily: "Poppins-SemiBold, Arial, sans-serif" };
export default {
    setup() {
        const route = useRoute()
        watch(() => route.name, () => {
        });

        return { route };
    },
    components: {
        Footer, NavBar, Menu, vSelect, swal, quillEditor
    },
    data() {
        return {
            halamanloading: true,
            iduser: JSON.parse(localStorage.getItem("usistem")),
            leveluser: JSON.parse(localStorage.getItem("lsistem")),
            namauser: JSON.parse(localStorage.getItem("nmusistem")),
            kewsistem: JSON.parse(localStorage.getItem("kewsistem")),
            secretencData: decodeURIComponent(CryptoJS.AES.decrypt(this.route.params.id, 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString(CryptoJS.enc.Utf8)),
            filenya_permohonan: '',
            filenya_kak: '',
            validasi: false,
            linknya: process.env.VUE_APP_URL_API,
            ipnya: '',
            folder_kak: process.env.VUE_APP_URL_API + 'parastapainnovation_kak-PDF?f=',
            folder_permohonan: process.env.VUE_APP_URL_API + 'parastapainnovation_permohonan-PDF?f=',
            jeniskerjasama: [],
            datajeniskerjasama: [],
            defaultSelectedjeniskerjasama: {
                code: 0,
                label: 'Pilih Data',
                tag: '',
            },
            selectedjeniskerjasama: '',
            tahun: '',
            nama: '',
            alamat: '',
            telp: '',
            email: '',
            NAMAPROVINSI: '',
            NAMAKAB_KOTA: '',
            namapemohon: '',
            jabatanpemohon: '',
            unitkerjapemohon: '',
            nomortelppemohon: '',
            kegiatan: '',
            namaOPD: '',
            nomortelppemohon: '',
            createddate: '',
            updatedate: '',
            keterangan: '',
        }
    },
    methods: {
        initialize() {
            //Initialize Select2 Elements
            $(".select2").select2();

            //Initialize Select2 Elements
            $(".select2bs4").select2({
                theme: "bootstrap4",
            });
            $('[data-mask]').inputmask();
            //$('#summernote').summernote();
        },
        getOtentifikasi() {
            var time = Date.parse(localStorage.getItem("tsistem"));
            var timeakhir = time + (3 * 60 * 60 * 1000);
            var timesekarang = Date.parse(new Date());
            if (timesekarang > timeakhir) {
                this.ClearlocalStorage();
            }
            this.halamanloading = false;
        },
        ontabelChange(a) {
            try {
                this.selectedtabel = this.tabel[a].tag;
            }
            catch (err) {
                return;
            }
        },
        batalproses() {
            window.location.replace("/PengajuanKerjasama");
        },
        async ambildatareff() {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            var random = Math.random();

            await mainAPI.get("parastapainnovationip-Key?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.ipnya = Response.data.randkey;
                    }
                    else {
                        this.ipnya = '';
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datapesan = [];
                console.log(error);
            });
            this.halamanloading = false;
            this.halamanloading = true;
            const mainAPI2 = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2" + this.ipnya,
                },
            });
            var random = Math.random();
            
            await mainAPI2.get("parastapainnovationreff-GetStatusPengajuanKerjasama?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datajeniskerjasama = [];
                        this.jeniskerjasama = [];
                        this.datajeniskerjasama = Response.data.content.data;
                        this.datajeniskerjasama.forEach((item) => {
                            this.jeniskerjasama.push({
                                label: item.nama,
                                code: this.datajeniskerjasama.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.jeniskerjasama.unshift({
                            label: 'Pilih Data',
                            code: 0,
                            tag: '',
                        });
                    }
                    else {
                        this.datajeniskerjasama = [];
                        this.jeniskerjasama = [];
                    }
                    this.halamanloading = false;

                }
            ).catch(error => {
                this.datajeniskerjasama = [];
                this.jeniskerjasama = [];
                this.halamanloading = false;
            });
        },
        onjeniskerjasamaChange(a) {
            try {
                this.selectedjeniskerjasama = this.jeniskerjasama[a].tag;
            }
            catch (err) {
                return;
            }
        },
        async AmbilDataSingel() {
            this.halamanloading = true;
            var mainAPIDataSingel = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPIDataSingel.get("parastapainnovation_pengajuankerjasama-GetPengajuanKerjasamaByID?id=" + this.secretencData + "&random=" + random).then(
                Response => {
                    this.selectedjeniskerjasama = Response.data.content.data[0].status;
                    this.defaultSelectedjeniskerjasama = {
                        code: Response.data.content.data[0].status,
                        label: Response.data.content.data[0].NAMASTATUS,
                        tag: Response.data.content.data[0].status,
                    };
                    this.tahun= parseInt(Response.data.content.data[0].createddate);
                    this.nama= Response.data.content.data[0].nama;
                    this.alamat= Response.data.content.data[0].alamat;
                    this.telp= Response.data.content.data[0].telp;
                    this.email= Response.data.content.data[0].email;
                    this.NAMAPROVINSI= Response.data.content.data[0].NAMAPROVINSI;
                    this.NAMAKAB_KOTA= Response.data.content.data[0].NAMAKAB_KOTA;
                    this.namapemohon= Response.data.content.data[0].namapemohon;
                    this.jabatanpemohon= Response.data.content.data[0].jabatanpemohon;
                    this.unitkerjapemohon= Response.data.content.data[0].unitkerjapemohon;
                    this.nomortelppemohon= Response.data.content.data[0].nomortelppemohon;
                    this.kegiatan= Response.data.content.data[0].kegiatan;
                    this.namaOPD= Response.data.content.data[0].namaOPD;
                    this.nomortelppemohon= Response.data.content.data[0].nomortelppemohon;
                    this.createddate= Response.data.content.data[0].createddate;
                    this.updatedate= Response.data.content.data[0].updatedate;

                    this.keterangan = Response.data.content.data[0].keterangan;
                    this.filenya_permohonan = Response.data.content.data[0].filenya_permohonan;
                    this.filenya_kak = Response.data.content.data[0].filenya_kak;
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })
        },
        validasidata() {
            //alert(this.subsubbidang);
            //return false;
            if (this.selectedjeniskerjasama == '' || this.selectedjeniskerjasama == null || this.selectedjeniskerjasama == undefined) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Pilih Status Pengajuan Kerjasama',
                });
                return false;
            }
            this.validasi = true;
            return;
        },
        update() {
            this.validasidata();
            if (this.validasi == false) {
                return false;
            }
            var fd = new FormData();
            fd.append("status", this.selectedjeniskerjasama);
            fd.append("keterangan", this.keterangan);
            fd.append("updatedby", JSON.parse(localStorage.getItem("usistem")));
            fd.append("id", this.secretencData);
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    "Content-Type": "form-data",
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            mainAPI.post("parastapainnovation_pengajuankerjasama-UpdatePengajuanKerjasama", fd).then(
                Response => {
                    if (Response.data.response == 'error') {
                        swal.fire('Peringatan', Response.data.message, 'error');
                    }
                    else {
                        swal.fire('Informasi', 'Data telah terupdate..', 'success').then(function () {
                            window.location.replace("/PengajuanKerjasama");
                        });
                    }
                }
            ).catch(function (error) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: error
                });
            }
            )
            return false;
        },
    },
    mounted() {
        this.getOtentifikasi();
        this.initialize();
        this.ambildatareff();
        if (this.secretencData != 'Tambah') {
            this.AmbilDataSingel();
        }
    }
}
</script>
<style></style>