<template>
    <div class="wrapper">
        <NavBar />
        <Menu />
        <!-- Spinner Start -->
        <div id="spinner" v-if="halamanloading"
            class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
            style="opacity: 0.5;">
            <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <!-- Spinner End -->
        <div class="content-wrapper py-3">
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6 wow fadeInDown">
                            <h5>Data Pengajuan Kerjasama</h5>
                        </div>
                        <div class="col-sm-6 wow fadeInDown">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a href="/Dashboard">Home </a></li>
                                <li class="breadcrumb-item active">Data Pengajuan Kerjasama</li>
                            </ol>
                        </div>
                    </div><!-- /.row -->
                </div><!-- /.container-fluid -->
            </div>
            <!-- /.content-header -->
            <div class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-12 wow fadeInDown">
                            <div class="card cardputih collapsed-card">
                                <div class="card-header border-transparent" data-card-widget="collapse"
                                    style="cursor: pointer;">
                                    <label>Filter Data Tabel</label>
                                    <div class="card-tools">
                                        <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                            <i class="fas fa-plus"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="card-body px-3">
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <label>Tahun</label>
                                            <v-select :options="tahun" :reduce="(label) => label.code" label="label"
                                                v-model="defaultSelectedtahun" @update:modelValue="ontahunChange"
                                                style="background-color: #ffffff;"></v-select>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Instansi</label>
                                            <input type="text" v-model="carikata" class="form-control form-control-sm"
                                                placeholder="Masukkan Kata Kunci Pencarian Instansi"
                                                @keyup="ambildata()">
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Rencana Judul Kerjasama</label>
                                            <input type="text" v-model="carikatamitra"
                                                class="form-control form-control-sm"
                                                placeholder="Masukkan Kata Kunci Pencarian Rencana Judul Kerjasama"
                                                @keyup="ambildata()">
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Status</label>
                                            <v-select :options="jeniskerjasama" :reduce="(label) => label.code"
                                                label="label" v-model="defaultSelectedjeniskerjasama"
                                                @update:modelValue="onjeniskerjasamaChange"
                                                style="background-color: #ffffff;"></v-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 wow fadeInDown">
                            <div class="card cardputih">
                                <div class="card-header border-transparent">
                                    <label>Data Pengajuan Kerjasama</label>
                                    <div class="card-tools">
                                        <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                            <i class="fas fa-minus"></i>
                                        </button>
                                    </div>
                                    <div class="col-sm-12 text-center">
                                        <button type="button" class="btn btn-success btn-sm text-white lebar2"
                                            @click="G_tableCSV('_DataPengajuanKerjasama_')" data-toggle="tooltip"
                                            data-placement="left" title="Unduh Data .xlsx">
                                            <i class="far fa-file-excel"></i> Unduh Data .xlsx
                                        </button>
                                    </div>
                                    <div class="col-sm-12 float-left pb-2 px-3 wow fadeInDown">
                                        Jumlah Data {{ G_numFormat(jumlahdata) }}
                                        <span style="float: right;">
                                            <span>Halaman ke: </span>
                                            <select v-model="halaman" @change="onChangeHalaman($event)">
                                                <option v-for="index in jumlahhalaman" :key="index">
                                                    {{ index }}</option>
                                            </select>
                                            <span> dari </span> {{ jumlahhalaman }}
                                        </span>
                                    </div>
                                </div>
                                <div class="card-body p-0">
                                    <div class="table-responsive table-wrapper">
                                        <table class="table m-0" id="tabeldata" style="font-size: smaller;">
                                            <thead>
                                                <tr>
                                                    <th style="width: 50px;">No</th>
                                                    <th style="width: 50px;">Opsi</th>
                                                    <th style="width: 50px;">Tahun</th>
                                                    <th style="width: 100px;">Instansi</th>
                                                    <th style="width: 150px;">Rencana Judul Kerjasama</th>
                                                    <th style="width: 100px;">Tanggal Pengajuan</th>
                                                    <th style="width: 100px;">Tanggal Update</th>
                                                    <th style="width: 100px;">Status</th>
                                                    <th style="width: 200px;">Keterangan</th>
                                                    <th style="width: 150px;">Lampiran</th>
                                                </tr>
                                            </thead>
                                            <template v-for="(datalist, urutlist) in datamaster" :key="urutlist">
                                                <tbody>
                                                    <tr>
                                                        <td class="text-center">{{ (urutlist + 1) + (20 * halaman) - 20
                                                        }}</td>
                                                        <td class="text-center">
                                                            <button type="button"
                                                                class="btn btn-primary3 btn-sm text-white"
                                                                @click="prosesdata(datalist.id)" data-toggle="tooltip"
                                                                data-placement="left" title="Edit data.."><i
                                                                    class="fas fa-edit"></i></button>
                                                            <button type="button"
                                                                class="btn btn-danger3 btn-sm text-white"
                                                                @click="hapus(datalist.id)" data-toggle="tooltip"
                                                                data-placement="left" title="Hapus data.."><i
                                                                    class="fas fa-trash-alt"></i></button>
                                                        </td>
                                                        <td class="text-center">
                                                            {{ datalist.tahun }}
                                                        </td>
                                                        <td class="text-justify">
                                                            {{ datalist.nama }}
                                                        </td>
                                                        <td class="text-justify">
                                                            {{ datalist.kegiatan }}
                                                        </td>
                                                        <td class="text-center">
                                                            {{ G_formatDateNonWIB(datalist.createddate) }}
                                                        </td>
                                                        <td class="text-center">
                                                            {{ G_formatDateNonWIB(datalist.updatedate) }}
                                                        </td>
                                                        <td class="text-justify">
                                                            {{ datalist.NAMASTATUS }}
                                                        </td>
                                                        <td class="text-justify">
                                                            <span v-html="datalist.keterangan"></span>
                                                        </td>
                                                        <td>
                                                            <li v-if="datalist.filenya_permohonan != '' && datalist.filenya_permohonan != null">
                                                                <a :href="folder_permohonan + datalist.filenya_permohonan"
                                                                    v-if="datalist.filenya_permohonan != '' && datalist.filenya_permohonan != null">
                                                                    <i class="far fa-file-pdf"></i> Permohonan
                                                                </a>
                                                            </li>
                                                            <li v-if="datalist.filenya_kak != '' && datalist.filenya_kak != null">
                                                                <a :href="folder_kak + datalist.filenya_kak"
                                                                    v-if="datalist.filenya_kak != '' && datalist.filenya_kak != null">
                                                                    <i class="far fa-file-pdf"></i> KAK / Studi
                                                                </a>
                                                            </li>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                            <tbody v-if="(datapesan == 'data kosong')">
                                                <tr>
                                                    <td colspan="4">Data masih kosong</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="card-footer p-3">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import $ from 'jquery';
import axios from 'axios';
import vSelect from 'vue-select';
import swal from 'sweetalert2';
import CryptoJS from 'crypto-js';
import xlsx from 'xlsx/dist/xlsx.full.min';
import Footer from "../../componen/Footer.vue";
import NavBar from "../../componen/NavBar.vue";
import Menu from "../../componen/Menu.vue";

import VueApexCharts from "vue3-apexcharts";
window.Apex.chart = { fontFamily: "Poppins-SemiBold, Arial, sans-serif" };
export default {
    components: {
        Footer, NavBar, Menu, vSelect, swal
    },
    data() {
        return {
            halamanloading: true,
            iduser: JSON.parse(localStorage.getItem("usistem")),
            leveluser: JSON.parse(localStorage.getItem("lsistem")),
            namauser: JSON.parse(localStorage.getItem("nmusistem")),
            kewenangan: JSON.parse(localStorage.getItem("kewsistem")),
            d5sistem: JSON.parse(localStorage.getItem("d5sistem")),
            datapesan: '',
            datamaster: [],
            carikata: '',
            jumlahdata: 0,
            jumlahhalaman: 0,
            mulai: 0,
            halaman: 1,
            folder_kak: process.env.VUE_APP_URL_API + 'parastapainnovation_kak-PDF?f=',
            folder_permohonan: process.env.VUE_APP_URL_API + 'parastapainnovation_permohonan-PDF?f=',
            ipnya: '',

            tahun: [],
            datatahun: [],
            defaultSelectedtahun: {
                code: 0,
                label: 'Semua Data',
                tag: '',
            },
            selectedtahun: '',

            daerah: [],
            datadaerah: [],
            defaultSelecteddaerah: {
                code: 0,
                label: 'Semua Data',
                tag: '',
            },
            selecteddaerah: '',

            carikatamitra: '',

            jeniskerjasama: [],
            datajeniskerjasama: [],
            defaultSelectedjeniskerjasama: {
                code: 0,
                label: 'Semua Data',
                tag: '',
            },
            selectedjeniskerjasama: '',

            jenisdokumen: [],
            datajenisdokumen: [],
            defaultSelectedjenisdokumen: {
                code: 0,
                label: 'Semua Data',
                tag: '',
            },
            selectedjenisdokumen: '',

            urusan: [],
            dataurusan: [],
            defaultSelectedurusan: {
                code: 0,
                label: 'Semua Data',
                tag: '',
            },
            selectedurusan: '',
            tempexcel: [],
            ekportexcel: [],
        }
    },
    computed: {
        filteredKataData() {
            const filteredWorkers = this.carikata === ""
                ? this.datamaster
                : this.datamaster.filter(wo => Object.values(wo).join("").toLowerCase().indexOf(this.carikata.toLowerCase()) !== -1);
            return filteredWorkers;
        },
        JumlahfilteredKataData() {
            var jumlah = 0;
            try {
                if (this.filteredKataData === undefined) {
                    jumlah = 0;
                } else {
                    jumlah = this.filteredKataData.length;
                }
            }
            catch {
                jumlah = 0;
            }
            return jumlah;
        },
    },
    methods: {
        async G_tableCSV(namefile) {
            this.ekportexcel = [];
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPI.get("parastapainnovation_pengajuankerjasama-GetAll?random=" + random + "&leveluser="
                + this.leveluser + "&kewenangan=" + this.kewenangan
                + "&d5sistem=" + this.d5sistem + "&iduser=" + this.iduser + "&judul=" + this.carikata
                + "&tahun=" + this.selectedtahun + "&daerah=" + this.selecteddaerah + "&mitra=" + this.carikatamitra
                + "&jeniskerjasama=" + this.selectedjeniskerjasama + "&jenisdokumen=" + this.selectedjenisdokumen
                + "&urusan=" + this.selectedurusan
            ).then(
                Response => {
                    this.datapesan = Response.data.message;
                    //alert(this.datapesan);
                    if (this.datapesan == 'data diketemukan') {
                        this.tempexcel = [];
                        this.tempexcel = Response.data.content.data;
                        for (let i = 0; i < this.tempexcel.length; i++) {
                            this.ekportexcel.push({
                                "No": i + 1,
                                "Tahun": this.tempexcel[i].tahun,
                                "Instansi": this.tempexcel[i].nama,
                                "Rencana Judul Kerjasama": this.tempexcel[i].kegiatan,
                                "Tanggal Pengajuan": this.G_formatDateNonWIB(this.tempexcel[i].createddate),
                                "Tanggal Update": this.G_formatDateNonWIB(this.tempexcel[i].updatedate),
                                "Status": this.tempexcel[i].NAMASTATUS,
                                "Keterangan": this.tempexcel[i].keterangan,
                            });
                        }
                        function formatColumn(worksheet, col, fmt) {
                            const range = XLSX.utils.decode_range(worksheet['!ref'])
                            // note: range.s.r + 1 skips the header row
                            for (let row = range.s.r + 1; row <= range.e.r; ++row) {
                                const ref = XLSX.utils.encode_cell({ r: row, c: col })
                                if (worksheet[ref] && worksheet[ref].t === 'n') {
                                    worksheet[ref].z = fmt
                                }
                            }
                        }
                        const currency = '0.00';
                        const XLSX = xlsx;
                        const workbook = XLSX.utils.book_new();
                        const worksheet = XLSX.utils.json_to_sheet(this.ekportexcel);

                        // ARRAY NUMBER SILAHKAN MASUKKAN NOMOR KOLOM
                        for (let col of [0]) {
                            formatColumn(worksheet, col, currency)
                        }
                        XLSX.utils.book_append_sheet(workbook, worksheet, "Data Pengajuan Kerjasama");
                        XLSX.writeFile(workbook, this.G_formatDate(Date(), 'd-M-Y') + namefile + ".xlsx");
                        this.halamanloading = false;
                    }
                    else {
                        swal.fire('Peringatan', 'Jumlah data yang hendak diunduh terlalu banyak, Pilih data dengan jumlah yang lebih kecil', 'error');
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', 'Jumlah data yang hendak diunduh terlalu banyak, Pilih data dengan jumlah yang lebih kecil', 'error');
                this.halamanloading = false;
            });
            //console.log(this.datamaster);
            //return false;
        },
        getOtentifikasi() {
            var time = Date.parse(localStorage.getItem("tsistem"));
            var timeakhir = time + (3 * 60 * 60 * 1000);
            var timesekarang = Date.parse(new Date());
            if (timesekarang > timeakhir) {
                this.ClearlocalStorage();
            }
        },
        onChangeHalaman(event) {
            this.pindahhalaman(event.target.value);
        },
        async pindahhalaman(halamannya) {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPI.get("parastapainnovation_pengajuankerjasama-GetPengajuanKerjasama?random=" + random + "&halaman=" + halamannya
                + "&leveluser=" + this.leveluser + "&kewenangan=" + this.kewenangan + "&d5sistem="
                + this.d5sistem + "&iduser=" + this.iduser + "&judul=" + this.carikata
                + "&tahun=" + this.selectedtahun + "&daerah=" + this.selecteddaerah + "&mitra=" + this.carikatamitra
                + "&jeniskerjasama=" + this.selectedjeniskerjasama + "&jenisdokumen=" + this.selectedjenisdokumen
                + "&urusan=" + this.selectedurusan).then(
                    Response => {
                        this.datapesan = Response.data.message;
                        //alert(this.datapesan);
                        if (this.datapesan == 'data diketemukan') {
                            this.datamaster = [];
                            this.datamaster = Response.data.content.data;
                            this.jumlahdata = Response.data.content.jumlahdata;
                            this.jumlahhalaman = Response.data.content.jumlahhalaman;
                            this.mulai = Response.data.content.mulai;
                            if (this.mulai == 0) {
                                this.halaman = 1;
                            }
                            else {
                                this.halaman = (this.mulai / 20) + 1;
                            }
                        }
                        else {
                            this.datamaster = [];
                            this.jumlahdata = 0;
                            this.jumlahhalaman = 0;
                            this.mulai = 0;
                        }
                        this.halamanloading = false;
                    }
                ).catch(error => {
                    //console.log(error);
                    //this.ClearlocalStorage();
                    this.halamanloading = false;
                });
        },
        async ambildatareff() {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            var random = Math.random();

            await mainAPI.get("parastapainnovationip-Key?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.ipnya = Response.data.randkey;
                    }
                    else {
                        this.ipnya = '';
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datapesan = [];
                console.log(error);
            });
            this.halamanloading = false;
            this.halamanloading = true;
            const mainAPI2 = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2" + this.ipnya,
                },
            });
            var random = Math.random();
            await mainAPI2.get("parastapainnovationreff-GetPengajuanTahun?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datatahun = [];
                        this.tahun = [];
                        this.datatahun = Response.data.content.data;
                        this.datatahun.forEach((item) => {
                            this.tahun.push({
                                label: item.tahun,
                                code: this.datatahun.indexOf(item) + 1,
                                tag: item.tahun,
                            });
                        });
                        this.tahun.unshift({
                            label: 'Semua Data',
                            code: 0,
                            tag: '',
                        });
                    }
                    else {
                        this.datatahun = [];
                        this.tahun = [];
                    }
                    this.halamanloading = false;

                }
            ).catch(error => {
                this.datatahun = [];
                this.tahun = [];
                this.halamanloading = false;
            });

            await mainAPI2.get("parastapainnovationreff-GetKabkotdanJateng?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datadaerah = [];
                        this.daerah = [];
                        this.datadaerah = Response.data.content.data;
                        this.datadaerah.forEach((item) => {
                            this.daerah.push({
                                label: item.NAMA,
                                code: this.datadaerah.indexOf(item) + 1,
                                tag: item.KD_KAB,
                            });
                        });
                        this.daerah.unshift({
                            label: 'Semua Data',
                            code: 0,
                            tag: '',
                        });
                    }
                    else {
                        this.datadaerah = [];
                        this.daerah = [];
                    }
                    this.halamanloading = false;

                }
            ).catch(error => {
                this.datadaerah = [];
                this.daerah = [];
                this.halamanloading = false;
            });

            await mainAPI2.get("parastapainnovationreff-GetStatusPengajuanKerjasama?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datajeniskerjasama = [];
                        this.jeniskerjasama = [];
                        this.datajeniskerjasama = Response.data.content.data;
                        this.datajeniskerjasama.forEach((item) => {
                            this.jeniskerjasama.push({
                                label: item.nama,
                                code: this.datajeniskerjasama.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.jeniskerjasama.unshift({
                            label: 'Semua Data',
                            code: 0,
                            tag: '',
                        });
                    }
                    else {
                        this.datajeniskerjasama = [];
                        this.jeniskerjasama = [];
                    }
                    this.halamanloading = false;

                }
            ).catch(error => {
                this.datajeniskerjasama = [];
                this.jeniskerjasama = [];
                this.halamanloading = false;
            });

            await mainAPI2.get("parastapainnovationreff-GetJenisDokumen?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datajenisdokumen = [];
                        this.jenisdokumen = [];
                        this.datajenisdokumen = Response.data.content.data;
                        this.datajenisdokumen.forEach((item) => {
                            this.jenisdokumen.push({
                                label: item.nama,
                                code: this.datajenisdokumen.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.jenisdokumen.unshift({
                            label: 'Semua Data',
                            code: 0,
                            tag: '',
                        });
                    }
                    else {
                        this.datajenisdokumen = [];
                        this.jenisdokumen = [];
                    }
                    this.halamanloading = false;

                }
            ).catch(error => {
                this.datajenisdokumen = [];
                this.jenisdokumen = [];
                this.halamanloading = false;
            });

            await mainAPI2.get("parastapainnovationreff-GetUrusan?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.dataurusan = [];
                        this.urusan = [];
                        this.dataurusan = Response.data.content.data;
                        this.dataurusan.forEach((item) => {
                            this.urusan.push({
                                label: item.nama,
                                code: this.dataurusan.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.urusan.unshift({
                            label: 'Semua Data',
                            code: 0,
                            tag: '',
                        });
                    }
                    else {
                        this.dataurusan = [];
                        this.urusan = [];
                    }
                    this.halamanloading = false;

                }
            ).catch(error => {
                this.dataurusan = [];
                this.urusan = [];
                this.halamanloading = false;
            });
        },
        ontahunChange(a) {
            try {
                this.selectedtahun = this.tahun[a].tag;
            }
            catch (err) {
                return;
            }
            this.ambildata();
        },
        ondaerahChange(a) {
            try {
                this.selecteddaerah = this.daerah[a].tag;
            }
            catch (err) {
                return;
            }
            this.ambildata();
        },
        onjeniskerjasamaChange(a) {
            try {
                this.selectedjeniskerjasama = this.jeniskerjasama[a].tag;
            }
            catch (err) {
                return;
            }
            this.ambildata();
        },
        onjenisdokumenChange(a) {
            try {
                this.selectedjenisdokumen = this.jenisdokumen[a].tag;
            }
            catch (err) {
                return;
            }
            this.ambildata();
        },
        onurusanChange(a) {
            try {
                this.selectedurusan = this.urusan[a].tag;
            }
            catch (err) {
                return;
            }
            this.ambildata();
        },
        async ambildata() {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPI.get("parastapainnovation_pengajuankerjasama-GetPengajuanKerjasama?random=" + random + "&leveluser="
                + this.leveluser + "&kewenangan=" + this.kewenangan
                + "&d5sistem=" + this.d5sistem + "&iduser=" + this.iduser + "&judul=" + this.carikata
                + "&tahun=" + this.selectedtahun + "&daerah=" + this.selecteddaerah + "&mitra=" + this.carikatamitra
                + "&jeniskerjasama=" + this.selectedjeniskerjasama + "&jenisdokumen=" + this.selectedjenisdokumen
                + "&urusan=" + this.selectedurusan).then(
                    Response => {
                        this.datapesan = Response.data.message;
                        if (this.datapesan == 'data diketemukan') {
                            this.datamaster = [];
                            this.datamaster = Response.data.content.data;
                            this.jumlahdata = Response.data.content.jumlahdata;
                            this.jumlahhalaman = Response.data.content.jumlahhalaman;
                            this.mulai = Response.data.content.mulai;
                            if (this.mulai == 0) {
                                this.halaman = 1;
                            }
                            else {
                                this.halaman = (this.mulai / 20) + 1;
                            }
                        }
                        else {
                            this.datamaster = [];
                            this.jumlahdata = 0;
                            this.jumlahhalaman = 0;
                            this.mulai = 0;
                        }
                        this.halamanloading = false;
                    }
                ).catch(error => {
                    console.log(error);
                    this.ClearlocalStorage();
                    this.halamanloading = false;
                });
        },
        prosesdata(kode) {
            //return false;
            var bytes = encodeURIComponent(CryptoJS.AES.encrypt(kode, 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString());
            window.location.replace("/ProsesPengajuanKerjasama/" + bytes);
        },
        hapus(kode) {
            //return false;
            swal.fire({
                title: "Konfirmasi",
                text: "Yakin Hendak Hapus Data?",
                icon: "warning",
                showCancelButton: true,
                cancelButtonText: "Batal",
                confirmButtonColor: "#7e3806",
                cancelButtonColor: "#ffaa05",
                confirmButtonText: "Hapus"
            }).then((result) => {
                if (result.isConfirmed) {
                    const data = { kode: kode, deletedby: JSON.parse(localStorage.getItem("usistem")) }
                    const mainAPI = axios.create({
                        baseURL: process.env.VUE_APP_URL_API,
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                            "Content-Type": "application/x-www-form-urlencoded",
                        },
                    });
                    mainAPI.post("parastapainnovation_pengajuankerjasama-DeletePengajuanKerjasama", data).then(
                        Response => {
                            if (Response.data.response == 'error') {
                                swal.fire('Peringatan', Response.data.message, 'error');
                            }
                            else {
                                swal.fire('Informasi', 'Data telah dihapus..', 'success').then(function () {
                                    window.location.replace("/PengajuanKerjasama");
                                });
                            }
                        }
                    ).catch(
                        error => {
                            swal.fire('Peringatan', 'Terjadi Kesalahan Format Data', 'error');
                        }
                    )

                }
            });
            return false;
        },
    },
    mounted() {
        this.getOtentifikasi();
        this.ambildatareff();
        this.ambildata();
    }
}
</script>
<style></style>