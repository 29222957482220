<template>
    <Top />
    <!-- Spinner Start -->
    <div id="spinner" v-if="halamanloading"
        class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        &nbsp;<img src="../../../../src/assets/img/BerandaNgumahLogo.png" height="40">
        &nbsp;<span class="text-danger">..Loading...</span>
    </div>
    <!-- Spinner End -->
    <!-- Header Start -->
    <div class="container-fluid bg-breadcrumb">
        <div class="container text-center py-5" style="max-width: 900px;">
            <h3 class="text-white display-3 mb-4 wow fadeInDown pmisputih" data-wow-delay="0.1s">
                <span v-if="(bahasa === null) || (bahasa == 'ina')">PENGAJUAN KERJASAMA</span>
                <span v-else>PENGAJUAN KERJASAMA</span>
            </h3>
            <ol class="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s">
                <li class="breadcrumb-item">
                    <a href="/">
                        <span v-if="(bahasa === null) || (bahasa == 'ina')">Beranda</span>
                        <span v-else>Beranda</span>
                    </a>
                </li>
                <li class="breadcrumb-item active text-primary">
                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Pengajuan Kerjasama</span>
                    <span v-else>Pengajuan Kerjasama</span>
                </li>
            </ol>
        </div>
    </div>
    <!-- Header End -->
    <!-- Contact Start -->
    <div class="container-fluid bg-breadcrumbberanda px-5">
        <div class="row">
            <ul class="nav nav-tabs justify-content-center" id="custom-tabs-four-tab" role="tablist">
                <li class="nav-item" style="display: none;">
                    <a class="nav-link active" id="custom-tabs-four-home-tab" data-toggle="pill"
                        href="#custom-tabs-four-home" role="tab" aria-controls="custom-tabs-four-home"
                        aria-selected="true">
                        Data Pemohon
                    </a>
                </li>
                <li class="nav-item" style="display: none;">
                    <a class="nav-link" id="custom-tabs-four-profile-tab" data-toggle="pill"
                        href="#custom-tabs-four-profile" role="tab" aria-controls="custom-tabs-four-profile"
                        aria-selected="false">
                        Data Lokasi
                    </a>
                </li>
                <li class="nav-item" style="display: none;">
                    <a class="nav-link" id="custom-tabs-four-risk-tab" data-toggle="pill" href="#custom-tabs-four-risk"
                        role="tab" aria-controls="custom-tabs-four-risk" aria-selected="false">
                        Kirim Data
                    </a>
                </li>
            </ul>
            <div class="tab-content" id="custom-tabs-four-tabContent">
                <div class="tab-pane fade show active" id="custom-tabs-four-home" role="tabpanel"
                    aria-labelledby="custom-tabs-four-home-tab">
                    <div class="row px-2">
                        <div class="col-sm-12">&nbsp;</div>
                        <div class="container-fluid feature py-3">
                            <div class="section-title mb-0">
                                <div class="sub-style">
                                    <h4 class="sub-title px-3 mb-0">
                                        DATA PERMOHONAN KERJASAMA
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="help-block text-center wow fadeInLeft">
                                Silahkan masukkan identitas dan kelengkapan, silahkan mengisikan data
                                identitas diri dan kelengkapan secara lengkap dan tepat. Kekeliruan mengisikan data
                                identitas dan kelengkapan
                                mengakibatkan kesalahan informasi dalam pengajuan kerjasama.
                            </div>
                        </div>
                        <div class="col-sm-12">&nbsp;</div>
                        <div class="col-sm-12 float-left pb-2">
                            <label>Nama Instansi<small class="text-info"> (*wajib diisi)</small></label>
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="fas fa-user-circle"></i></span>
                                </div>
                                <input type="text" class="form-control form-control-sm" id="nama" name="nama"
                                    placeholder="Nama Instansi" v-model="nama">
                            </div>
                        </div>
                        <div class="col-sm-12 float-left pb-2">
                            <label>Alamat Instansi<small class="text-info"> (*wajib diisi)</small></label>
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="far fa-address-card"></i></span>
                                </div>
                                <input type="text" class="form-control form-control-sm" id="alamat" name="alamat"
                                    placeholder="Alamat Instansi" v-model="alamat">
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <label>Provinsi
                                <small class="text-info"> (*wajib dipilih)</small>
                            </label>
                            <v-select :options="provinsi" :reduce="(label) => label.code" label="label"
                                v-model="defaultSelectedprovinsi" @update:modelValue="onprovinsiChange"
                                style="background-color: #ffffff;"></v-select>
                        </div>
                        <div class="col-sm-6" style="padding-bottom: 20px;">
                            <label>Kabupaten / Kota
                                <small class="text-info"> (*wajib dipilih)</small>
                            </label>
                            <v-select :options="kabkot" :reduce="(label) => label.code" label="label"
                                v-model="defaultSelectedkabkot" @update:modelValue="onkabkotChange"
                                style="background-color: #ffffff;"></v-select>
                        </div>
                        <div class="col-sm-6" style="padding-bottom: 20px;">
                            <label>Kecamatan
                                <small class="text-info"> (*wajib dipilih)</small>
                            </label>
                            <v-select :options="kecamatan" :reduce="(label) => label.code" label="label"
                                v-model="defaultSelectedkecamatan" @update:modelValue="onkecamatanChange"
                                style="background-color: #ffffff;"></v-select>
                        </div>
                        <div class="col-sm-6">
                            <label>Desa / Kelurahan
                                <small class="text-info"> (*wajib dipilih)</small>
                            </label>
                            <v-select :options="desa" :reduce="(label) => label.code" label="label"
                                v-model="defaultSelecteddesa" @update:modelValue="ondesaChange"
                                style="background-color: #ffffff;"></v-select>
                        </div>
                        <div class="col-sm-6 float-left pb-2">
                            <label>Nomor Telp. Instansi<small class="text-info"> (*wajib diisi)</small></label>
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="far fa-address-card"></i></span>
                                </div>
                                <input type="text" class="form-control form-control-sm" id="telp" name="telp"
                                    placeholder="Nomor Telp. Instansi" v-model="telp">
                            </div>
                        </div>
                        <div class="col-sm-6 float-left pb-2">
                            <label>Email Instansi<small class="text-info"> (*wajib diisi)</small></label>
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="far fa-address-card"></i></span>
                                </div>
                                <input type="text" class="form-control form-control-sm" id="email" name="email"
                                    placeholder="Email Instansi" v-model="email">
                            </div>
                        </div>
                        <div class="col-sm-6 float-left pb-2">
                            <label>Nama Pemohon<small class="text-info"> (*wajib diisi)</small></label>
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="far fa-address-card"></i></span>
                                </div>
                                <input type="text" class="form-control form-control-sm" id="namapemohon"
                                    name="namapemohon" placeholder="Nama Pemohon" v-model="namapemohon">
                            </div>
                        </div>
                        <div class="col-sm-6 float-left pb-2">
                            <label>Jabatan Pemohon<small class="text-info"> (*wajib diisi)</small></label>
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="far fa-address-card"></i></span>
                                </div>
                                <input type="text" class="form-control form-control-sm" id="jabatanpemohon"
                                    name="jabatanpemohon" placeholder="Jabatan Pemohon" v-model="jabatanpemohon">
                            </div>
                        </div>
                        <div class="col-sm-6 float-left pb-2">
                            <label>Unit Kerja Pemohon<small class="text-info"> (*wajib diisi)</small></label>
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="far fa-address-card"></i></span>
                                </div>
                                <input type="text" class="form-control form-control-sm" id="unitkerjapemohon"
                                    name="unitkerjapemohon" placeholder="Unit Kerja Pemohon" v-model="unitkerjapemohon">
                            </div>
                        </div>
                        <div class="col-sm-6 float-left pb-2">
                            <label>Nomor Telp. Pemohon<small class="text-info"> (*wajib diisi)</small></label>
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="far fa-address-card"></i></span>
                                </div>
                                <input type="text" class="form-control form-control-sm" id="nomortelppemohon"
                                    name="nomortelppemohon" placeholder="Nomor Telp. Pemohon"
                                    v-model="nomortelppemohon">
                            </div>
                        </div>
                        <div class="col-sm-12 float-left pb-2">&nbsp;</div>
                        <div class="col-sm-5 float-left pb-2"></div>
                        <div class="col-sm-2 float-left pb-2">
                            <button @click="bukapeta()" class="btn btn-secondary btn-block text-white"
                                placeholder="Selanjutnya" data-toggle="tooltip" data-placement="left"
                                title="Kirim aduan.." style="color: black;">
                                <b><i class="far fa-arrow-alt-circle-right"></i>
                                    Selanjutnya
                                </b>
                            </button>
                        </div>
                        <div class="col-sm-5 float-left pb-2"></div>
                    </div>
                </div>
                <div class="tab-pane fade" id="custom-tabs-four-profile" role="tabpanel"
                    aria-labelledby="custom-tabs-four-profile-tab">
                    <div class="col-sm-12">&nbsp;</div>
                    <div class="container-fluid feature py-3">
                        <div class="section-title mb-0">
                            <div class="sub-style">
                                <h4 class="sub-title px-3 mb-0">
                                    DATA KELENGKAPAN DOKUMEN
                                </h4>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <div class="help-block text-center">
                            Silahkan lakukan drag drop pada kolom kelengkapan dokumen, dokumen berupa file pdf antara
                            lain adalah: rencana judul kerjasama, surat permohonan kerjasama dan KAK / Studi Kelayakan
                            Kerjasama
                        </div>
                    </div>
                    <div class="col-sm-12">&nbsp;</div>
                    <div class="row px-0">
                        <div class="col-sm-12 float-left pb-2">
                            <label>Rencana Judul Kerjasama<small class="text-info"> (*wajib diisi)</small></label>
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="fas fa-user-circle"></i></span>
                                </div>
                                <input type="text" class="form-control form-control-sm" id="kegiatan" name="kegiatan"
                                    placeholder="Rencana Judul Kerjasama" v-model="kegiatan">
                            </div>
                        </div>
                        <div class="col-sm-12" style="padding-bottom: 20px;">
                            <label>Dinas / OPD Pelaksana Kerjasama
                                <small class="text-info"> (*wajib dipilih)</small>
                            </label>
                            <v-select :options="dinas" :reduce="(label) => label.code" label="label"
                                v-model="defaultSelecteddinas" @update:modelValue="ondinasChange"
                                style="background-color: #ffffff;"></v-select>
                        </div>
                        <div class="col-sm-6">
                            <input type="hidden" class="form-control form-control-sm" id="filenya_permohonan"
                                name="filenya_permohonan" placeholder="File Produk">
                            <label>Surat Permohonan Kerjasama<small class="text-info"> (*pdf)</small>
                            </label>
                            <div class="col-sm-12">
                                <iframe :src="sourcenya" class="col-sm-12 text-center" style="min-height: 80vh;"
                                    frameborder="0" id="maps" scrolling="no" @load="loadpeta()"></iframe>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <input type="hidden" class="form-control form-control-sm" id="filenya_kak"
                                name="filenya_kak" placeholder="File Produk">
                            <label>KAK / Studi Kelayakan Kerjasama<small class="text-info"> (*pdf)</small>
                            </label>
                            <div class="col-sm-12">
                                <iframe :src="sourcenyamonev" class="col-sm-12 text-center" style="min-height: 80vh;"
                                    frameborder="0" id="mapsmonev" scrolling="no" @load="loadpetamonev()"></iframe>
                            </div>
                        </div>
                        <div class="col-sm-12">&nbsp;</div>
                        <div class="col-sm-12 float-left pb-2">&nbsp;</div>
                        <div class="col-sm-4 float-left pb-2"></div>
                        <div class="col-sm-2 float-left pb-2">
                            <button @click="bukapemohon()" class="btn btn-secondary btn-block text-white"
                                placeholder="Selanjutnya" data-toggle="tooltip" data-placement="left"
                                title="Kirim aduan.." style="color: black;">
                                <b><i class="far fa-arrow-alt-circle-left"></i>
                                    Sebelumnya
                                </b>
                            </button>
                        </div>
                        <div class="col-sm-2 float-left pb-2">
                            <button @click="bukakirim()" class="btn btn-secondary btn-block text-white"
                                placeholder="Selanjutnya" data-toggle="tooltip" data-placement="left"
                                title="Kirim aduan.." style="color: black;">
                                <b><i class="far fa-arrow-alt-circle-right"></i>
                                    Selanjutnya
                                </b>
                            </button>
                        </div>
                        <div class="col-sm-4 float-left pb-2"></div>
                    </div>
                </div>
                <div class="tab-pane fade" id="custom-tabs-four-risk" role="tabpanel"
                    aria-labelledby="custom-tabs-four-risk-tab">
                    <div class="col-sm-12">&nbsp;</div>
                    <div class="container-fluid feature py-3">
                        <div class="section-title mb-0">
                            <div class="sub-style">
                                <h4 class="sub-title px-3 mb-0">
                                    KONFIRMASI DATA
                                </h4>
                            </div>
                        </div>
                        <div class="col-sm-12 text-center">
                            <img src="../../../../src/assets/img/berandaNgumahNew.png" height="160">
                        </div>
                    </div>
                    <div class="row px-2">
                        <div class="col-sm-6 float-left pb-2">
                            <label>Kode Keamanan</label>
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="fas fa-clipboard-list"></i></span>
                                </div>
                                <input type="text" class="form-control form-control-sm" id="capcha" name="capcha"
                                    placeholder="Kode Keamanan" v-model="capcha" disabled>
                            </div>
                        </div>
                        <div class="col-sm-6 float-left pb-2">
                            <label>Ulangi Kode Keamanan</label>
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="far fa-clone"></i></span>
                                </div>
                                <input type="text" class="form-control form-control-sm" id="capchainput"
                                    name="capchainput" placeholder="Ulangi Kode Keamanan" v-model="capchainput"
                                    style="text-transform:uppercase">
                            </div>
                        </div>
                        <div class="col-sm-12 float-left pb-2">
                            <div class="lockscreen-item" style="background: transparent;">
                                <button @click="bukapeta()" class="btn btn-secondary btn-block text-white"
                                    placeholder="Selanjutnya" data-toggle="tooltip" data-placement="left"
                                    title="Kirim aduan.." style="color: black;">
                                    <b><i class="far fa-arrow-alt-circle-left"></i>
                                        Sebelumnya
                                    </b>
                                </button>
                                <div class="col-sm-12 float-left pb-2">&nbsp;</div>
                                <div class="col-sm-12">&nbsp;</div>
                                <div class="col-sm-12 text-center">{{ selamat }}</div>
                                <div class="col-sm-12">&nbsp;</div>
                                <div class="col-sm-12 text-center">{{ tulisregister }}</div>
                                <div class="col-sm-12">&nbsp;</div>
                                <div class="col-sm-12">&nbsp;</div>
                                <div class="input-group px-5 py-5" style="border: solid 1px #cccccc;">
                                    <div class="custom-control custom-switch custom-switch-on-primary">
                                        <input type="checkbox" class="custom-control-input" id="customSwitchAuth">
                                        <label class="custom-control-label" for="customSwitchAuth" @click="rubahisi()"
                                            style="font-size: small;">
                                            <span v-if="(bahasa === null) || (bahasa == 'ina')">Autentikasi Pengguna
                                                Manusia</span>
                                            <span v-else>Human User Authentication</span>
                                        </label>
                                    </div>
                                    <div class="help-block text-center">&nbsp;</div>
                                    <button class="btn btn-danger2 btn-block text-white" placeholder="Username"
                                        data-toggle="tooltip" data-placement="left" title="Kirim aduan.."
                                        style="color: black;" v-on:click="itrAct" :disabled="showHuman == false">
                                        <b><i class="	fas fa-mail-bulk"></i>
                                            Kirim
                                        </b>
                                    </button>
                                    <div class="col-sm-12">&nbsp;</div>
                                </div>

                            </div>
                            <!-- /.lockscreen-item -->
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-12">&nbsp;</div>
            <div class="col-sm-12">&nbsp;</div>

        </div>
    </div>
    <div class="container-fluid blog py-2">&nbsp;</div>
    <!-- Contact End -->
    <a href="#" class="btn btn-dangerbulet btn-lg-square back-to-top text-white"><i class="fa fa-arrow-up"></i></a>
    <Footer />
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import swal from 'sweetalert2';
import vSelect from 'vue-select';
import VueApexCharts from "vue3-apexcharts";
window.Apex.chart = { fontFamily: "Poppins-SemiBold, Arial, sans-serif" };
import Top from "./Template/Top.vue";
import Footer from "./Template/Footer.vue";

export default {
    components: {
        Top, Footer, swal, vSelect
    },
    data() {
        return {
            halamanloading: true,
            linknya: process.env.VUE_APP_URL_API,
            tahun: new Date().getFullYear(),
            bahasa: localStorage.getItem("bahasasistem"),
            datapesan: '',
            datanya: [],
            carikata: '',
            jumlahdata: 0,
            jumlahhalaman: 0,
            mulai: 0,
            halaman: 1,
            folder_foto: process.env.VUE_APP_URL_API + 'parastapainnovation_dokumen-PDF?f=',
            nama: '',
            alamat: '',
            ipnya: '',
            provinsi: [],
            dataprovinsi: [],
            defaultSelectedprovinsi: {
                code: 0,
                label: 'Pilih Data',
                tag: '',
            },
            selectedprovinsi: '',

            kabkot: [],
            datakabkot: [],
            defaultSelectedkabkot: {
                code: 0,
                label: 'Pilih Data',
                tag: '',
            },
            selectedkabkot: '',

            kecamatan: [],
            datakecamatan: [],
            defaultSelectedkecamatan: {
                code: 0,
                label: 'Pilih Data',
                tag: '',
            },
            selectedkecamatan: '',

            desa: [],
            datadesa: [],
            defaultSelecteddesa: {
                code: 0,
                label: 'Pilih Data',
                tag: '',
            },
            selecteddesa: '',

            telp: '',
            email: '',
            namapemohon: '',
            jabatanpemohon: '',
            unitkerjapemohon: '',
            nomortelppemohon: '',

            dinas: [],
            datadinas: [],
            defaultSelecteddinas: {
                code: 0,
                label: 'Pilih Data',
                tag: '',
            },
            selecteddinas: '',
            kegiatan: '',

            capcha: this.makeid(5), capchainput: "",
            showHuman: false,
            showPassword: false,
        };
    },
    methods: {
        loadpeta() {
            this.halamanloading = true;
            const message = this.linknya + 'main/FilesUpload/upload.php|filenya_permohonan/';
            const iframe = document.getElementById("maps");
            this.sourcenya = '';
            this.sourcenya = '/dragfiles.html';
            iframe.contentWindow.postMessage(message, "*");
            this.halamanloading = false;
        },
        loadpetamonev() {
            this.halamanloading = true;
            const message = this.linknya + 'main/FilesUpload/upload.php|filenya_kak/';
            const iframe = document.getElementById("mapsmonev");
            this.sourcenyamonev = '';
            this.sourcenyamonev = '/dragfiles.html';
            iframe.contentWindow.postMessage(message, "*");
            this.halamanloading = false;
        },
        rubahisi() {
            if (this.showHuman == false) {
                this.showHuman = true;
            }
            else {
                this.showHuman = false;
            }
        },
        bukapemohon() {
            $('#custom-tabs-four-home-tab').trigger('click');
        },
        bukakirim() {
            if (this.kegiatan == "") {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Isikan kolom rencana judul kerjasama..',
                });
                document.getElementById('customSwitchAuth').checked = false;
                document.getElementById("kegiatan").focus();
                this.showHuman = false;
                document.getElementById('customSwitchAuth').checked = false;
                return false;
            }
            if ((this.selecteddinas == '') || (this.selecteddinas === null) || (this.selecteddinas === undefined)) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Pilih OPD..',
                });
                document.getElementById('customSwitchAuth').checked = false;
                this.showHuman = false;
                document.getElementById('customSwitchAuth').checked = false;
                return false;
            }
            if (document.getElementById('filenya_permohonan').value == "") {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Isikan lampiran surat permohonan..',
                });
                document.getElementById('customSwitchAuth').checked = false;
                this.showHuman = false;
                document.getElementById('customSwitchAuth').checked = false;
                return false;
            }
            if (document.getElementById('filenya_kak').value == "") {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Isikan lampiran KAK/ Studi Kelayakan Kerjasama ..',
                });
                document.getElementById('customSwitchAuth').checked = false;
                this.showHuman = false;
                document.getElementById('customSwitchAuth').checked = false;
                return false;
            }
            $('#custom-tabs-four-risk-tab').trigger('click');
        },
        bukapeta() {
            if (this.nama == "") {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Isikan kolom nama..',
                });
                document.getElementById('customSwitchAuth').checked = false;
                document.getElementById("nama").focus();
                this.showHuman = false;
                document.getElementById('customSwitchAuth').checked = false;
                return false;
            }
            if (this.alamat == "") {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Isikan kolom alamat..',
                });
                document.getElementById('customSwitchAuth').checked = false;
                document.getElementById("alamat").focus();
                this.showHuman = false;
                document.getElementById('customSwitchAuth').checked = false;
                return false;
            }
            if ((this.selectedprovinsi == '') || (this.selectedprovinsi === null) || (this.selectedprovinsi === undefined)) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Pilih Provinsi..',
                });
                document.getElementById('customSwitchAuth').checked = false;
                this.showHuman = false;
                document.getElementById('customSwitchAuth').checked = false;
                return false;
            }
            if ((this.selectedkabkot == '') || (this.selectedkabkot === null) || (this.selectedkabkot === undefined)) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Pilih Kabupaten / Kota..',
                });
                document.getElementById('customSwitchAuth').checked = false;
                this.showHuman = false;
                document.getElementById('customSwitchAuth').checked = false;
                return false;
            }
            if ((this.selectedkecamatan == '') || (this.selectedkecamatan === null) || (this.selectedkecamatan === undefined)) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Pilih Kecamatan..',
                });
                document.getElementById('customSwitchAuth').checked = false;
                this.showHuman = false;
                document.getElementById('customSwitchAuth').checked = false;
                return false;
            }
            if ((this.selecteddesa == '') || (this.selecteddesa === null) || (this.selecteddesa === undefined)) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Pilih Desa..',
                });
                document.getElementById('customSwitchAuth').checked = false;
                this.showHuman = false;
                document.getElementById('customSwitchAuth').checked = false;
                return false;
            }
            if (this.telp == "") {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Isikan kolom telp..',
                });
                document.getElementById('customSwitchAuth').checked = false;
                document.getElementById("telp").focus();
                this.showHuman = false;
                document.getElementById('customSwitchAuth').checked = false;
                return false;
            }
            if (this.email == "") {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Isikan kolom email..',
                });
                document.getElementById('customSwitchAuth').checked = false;
                document.getElementById("email").focus();
                this.showHuman = false;
                document.getElementById('customSwitchAuth').checked = false;
                return false;
            }
            if (this.namapemohon == "") {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Isikan kolom nama pemohon..',
                });
                document.getElementById('customSwitchAuth').checked = false;
                document.getElementById("namapemohon").focus();
                this.showHuman = false;
                document.getElementById('customSwitchAuth').checked = false;
                return false;
            }
            if (this.jabatanpemohon == "") {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Isikan kolom jabatan pemohon..',
                });
                document.getElementById('customSwitchAuth').checked = false;
                document.getElementById("jabatanpemohon").focus();
                this.showHuman = false;
                document.getElementById('customSwitchAuth').checked = false;
                return false;
            }
            if (this.unitkerjapemohon == "") {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Isikan kolom unit kerja pemohon..',
                });
                document.getElementById('customSwitchAuth').checked = false;
                document.getElementById("unitkerjapemohon").focus();
                this.showHuman = false;
                document.getElementById('customSwitchAuth').checked = false;
                return false;
            }
            if (this.nomortelppemohon == "") {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Isikan kolom nomor telp pemohon..',
                });
                document.getElementById('customSwitchAuth').checked = false;
                document.getElementById("nomortelppemohon").focus();
                this.showHuman = false;
                document.getElementById('customSwitchAuth').checked = false;
                return false;
            }

            $('#custom-tabs-four-profile-tab').trigger('click');
        },
        onprovinsiChange(a) {
            try {
                this.selectedprovinsi = this.provinsi[a].tag;
            }
            catch (err) {
                return;
            }
            this.ambildatareffkabkot();
            this.ambildatareffkecamatan();
            this.ambildatareffdesa();
        },
        onkabkotChange(a) {
            try {
                this.selectedkabkot = this.kabkot[a].tag;
            }
            catch (err) {
                return;
            }
            this.ambildatareffkecamatan();
            this.ambildatareffdesa();
        },
        onkecamatanChange(a) {
            try {
                this.selectedkecamatan = this.kecamatan[a].tag;
            }
            catch (err) {
                return;
            }
            this.ambildatareffdesa();
        },
        ondesaChange(a) {
            try {
                this.selecteddesa = this.desa[a].tag;
            }
            catch (err) {
                return;
            }
        },
        ondinasChange(a) {
            try {
                this.selecteddinas = this.dinas[a].tag;
            }
            catch (err) {
                return;
            }
        },
        async ambildatareffkabkot() {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            var random = Math.random();
            await mainAPI.get("parastapainnovationip-Key?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.ipnya = Response.data.randkey;
                    }
                    else {
                        this.ipnya = '';
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datapesan = [];
                console.log(error);
            });
            this.halamanloading = false;
            this.halamanloading = true;
            const mainAPI2 = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2" + this.ipnya,
                },
            });
            await mainAPI2.get("parastapainnovationreff-GetMasterKabupatenKota?random=" + random + "&kodeprov=" + this.selectedprovinsi).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datakabkot = [];
                        this.kabkot = [];
                        this.datakabkot = Response.data.content.data;
                        this.datakabkot.forEach((item) => {
                            this.kabkot.push({
                                label: item.NAMA,
                                code: this.datakabkot.indexOf(item) + 1,
                                tag: item.KODE_KK,
                            });
                        });
                        this.kabkot.unshift({
                            label: 'Pilih Data',
                            code: 0,
                            tag: '',
                        });

                        this.defaultSelectedkabkot = {
                            code: 0,
                            label: 'Pilih Data',
                            tag: '',
                        };
                        this.selectedkabkot = '';

                        this.defaultSelectedkecamatan = {
                            code: 0,
                            label: 'Pilih Data',
                            tag: '',
                        };
                        this.selectedkecamatan = '';

                        this.defaultSelecteddesa = {
                            code: 0,
                            label: 'Pilih Data',
                            tag: '',
                        };
                        this.selecteddesa = '';
                    }
                    else {
                        this.datakabkot = [];
                        this.kabkot = [];
                    }
                    this.halamanloading = false;

                }
            ).catch(error => {
                this.datakabkot = [];
                this.kabkot = [];
                this.halamanloading = false;
            });
        },
        async ambildatareffkecamatan() {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            var random = Math.random();
            await mainAPI.get("parastapainnovationip-Key?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.ipnya = Response.data.randkey;
                    }
                    else {
                        this.ipnya = '';
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datapesan = [];
                console.log(error);
            });
            this.halamanloading = false;
            this.halamanloading = true;
            const mainAPI2 = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2" + this.ipnya,
                },
            });
            await mainAPI2.get("parastapainnovationreff-GetMasterKecamatan?random=" + random + "&kodeprov=" + this.selectedprovinsi + "&kodekabkot=" + this.selectedkabkot).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datakecamatan = [];
                        this.kecamatan = [];
                        this.datakecamatan = Response.data.content.data;
                        this.datakecamatan.forEach((item) => {
                            this.kecamatan.push({
                                label: item.NAMA,
                                code: this.datakecamatan.indexOf(item) + 1,
                                tag: item.KODE_KEC,
                            });
                        });
                        this.kecamatan.unshift({
                            label: 'Pilih Data',
                            code: 0,
                            tag: '',
                        });
                        this.defaultSelectedkecamatan = {
                            code: 0,
                            label: 'Pilih Data',
                            tag: '',
                        };
                        this.selectedkecamatan = '';

                        this.defaultSelecteddesa = {
                            code: 0,
                            label: 'Pilih Data',
                            tag: '',
                        };
                        this.selecteddesa = '';
                    }
                    else {
                        this.datakecamatan = [];
                        this.kecamatan = [];
                    }
                    this.halamanloading = false;

                }
            ).catch(error => {
                this.datakecamatan = [];
                this.kecamatan = [];
                this.halamanloading = false;
            });
        },
        async ambildatareffdesa() {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            var random = Math.random();
            await mainAPI.get("parastapainnovationip-Key?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.ipnya = Response.data.randkey;
                    }
                    else {
                        this.ipnya = '';
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datapesan = [];
                console.log(error);
            });
            this.halamanloading = false;
            this.halamanloading = true;
            const mainAPI2 = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2" + this.ipnya,
                },
            });
            await mainAPI2.get("parastapainnovationreff-GetMasterDesa?random=" + random + "&kodeprov=" + this.selectedprovinsi + "&kodekabkot=" + this.selectedkabkot + "&kodekecamatan=" + this.selectedkecamatan).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datadesa = [];
                        this.desa = [];
                        this.datadesa = Response.data.content.data;
                        this.datadesa.forEach((item) => {
                            this.desa.push({
                                label: item.NAMA,
                                code: this.datadesa.indexOf(item) + 1,
                                tag: item.KODE_KD,
                            });
                        });
                        this.desa.unshift({
                            label: 'Pilih Data',
                            code: 0,
                            tag: '',
                        });

                        this.defaultSelecteddesa = {
                            code: 0,
                            label: 'Pilih Data',
                            tag: '',
                        };
                        this.selecteddesa = '';
                    }
                    else {
                        this.datadesa = [];
                        this.desa = [];
                    }
                    this.halamanloading = false;

                }
            ).catch(error => {
                this.datadesa = [];
                this.desa = [];
                this.halamanloading = false;
            });
        },
        async ambildatareff() {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            var random = Math.random();

            await mainAPI.get("parastapainnovationip-Key?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.ipnya = Response.data.randkey;
                    }
                    else {
                        this.ipnya = '';
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datapesan = [];
                console.log(error);
            });
            this.halamanloading = false;
            this.halamanloading = true;
            const mainAPI2 = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2" + this.ipnya,
                },
            });
            var random = Math.random();
            await mainAPI2.get("parastapainnovationreff-GetMasterProvinsi?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.dataprovinsi = [];
                        this.provinsi = [];
                        this.dataprovinsi = Response.data.content.data;
                        this.dataprovinsi.forEach((item) => {
                            this.provinsi.push({
                                label: item.NAMA,
                                code: this.dataprovinsi.indexOf(item) + 1,
                                tag: item.KODE_PROV,
                            });
                        });
                        this.provinsi.unshift({
                            label: 'Pilih Data',
                            code: 0,
                            tag: '',
                        });
                    }
                    else {
                        this.dataprovinsi = [];
                        this.provinsi = [];
                    }
                    this.halamanloading = false;

                }
            ).catch(error => {
                this.dataprovinsi = [];
                this.provinsi = [];
                this.halamanloading = false;
            });

            this.halamanloading = true;
            await mainAPI2.get("parastapainnovationreff-GetMasterKabupatenKota?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datakabkot = [];
                        this.kabkot = [];
                        this.datakabkot = Response.data.content.data;
                        this.datakabkot.forEach((item) => {
                            this.kabkot.push({
                                label: item.NAMA,
                                code: this.datakabkot.indexOf(item) + 1,
                                tag: item.KODE_KK,
                            });
                        });
                        this.kabkot.unshift({
                            label: 'Pilih Data',
                            code: 0,
                            tag: '',
                        });
                    }
                    else {
                        this.datakabkot = [];
                        this.kabkot = [];
                    }
                    this.halamanloading = false;

                }
            ).catch(error => {
                this.datakabkot = [];
                this.kabkot = [];
                this.halamanloading = false;
            });

            this.halamanloading = true;
            await mainAPI2.get("parastapainnovationreff-GetMasterKecamatan?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datakecamatan = [];
                        this.kecamatan = [];
                        this.datakecamatan = Response.data.content.data;
                        this.datakecamatan.forEach((item) => {
                            this.kecamatan.push({
                                label: item.NAMA,
                                code: this.datakecamatan.indexOf(item) + 1,
                                tag: item.KODE_KEC,
                            });
                        });
                        this.kecamatan.unshift({
                            label: 'Pilih Data',
                            code: 0,
                            tag: '',
                        });
                    }
                    else {
                        this.datakecamatan = [];
                        this.kecamatan = [];
                    }
                    this.halamanloading = false;

                }
            ).catch(error => {
                this.datakecamatan = [];
                this.kecamatan = [];
                this.halamanloading = false;
            });

            await mainAPI2.get("parastapainnovationreff-GetMasterDesa?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datadesa = [];
                        this.desa = [];
                        this.datadesa = Response.data.content.data;
                        this.datadesa.forEach((item) => {
                            this.desa.push({
                                label: item.NAMA,
                                code: this.datadesa.indexOf(item) + 1,
                                tag: item.KODE_KD,
                            });
                        });
                        this.desa.unshift({
                            label: 'Pilih Data',
                            code: 0,
                            tag: '',
                        });

                        this.defaultSelecteddesa = {
                            code: 0,
                            label: 'Pilih Data',
                            tag: '',
                        };
                        this.selecteddesa = '';
                    }
                    else {
                        this.datadesa = [];
                        this.desa = [];
                    }
                    this.halamanloading = false;

                }
            ).catch(error => {
                this.datadesa = [];
                this.desa = [];
                this.halamanloading = false;
            });

            await mainAPI2.get("parastapainnovationreff-GetOPDProvinsi?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datadinas = [];
                        this.dinas = [];
                        this.datadinas = Response.data.content.data;
                        this.datadinas.forEach((item) => {
                            this.dinas.push({
                                label: item.nama,
                                code: this.datadinas.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.dinas.unshift({
                            label: 'Pilih Data',
                            code: 0,
                            tag: '',
                        });

                        this.defaultSelecteddinas = {
                            code: 0,
                            label: 'Pilih Data',
                            tag: '',
                        };
                        this.selecteddinas = '';
                    }
                    else {
                        this.datadinas = [];
                        this.dinas = [];
                    }
                    this.halamanloading = false;

                }
            ).catch(error => {
                this.datadinas = [];
                this.dinas = [];
                this.halamanloading = false;
            });
        },
        async itrAct() {
            if (this.capchainput == "") {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Isikan kolom capcha..',
                });
                document.getElementById('customSwitchAuth').checked = false;
                document.getElementById("capchainput").focus();
                this.showHuman = false;
                document.getElementById('customSwitchAuth').checked = false;
                return false;
            }
            if (this.capchainput.toUpperCase() != this.capcha) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Kode Keamanan Tidak Sama..',
                });
                document.getElementById('customSwitchAuth').checked = false;
                document.getElementById("capchainput").focus();
                this.showHuman = false;
                document.getElementById('customSwitchAuth').checked = false;
                return false;
            }
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            var random = Math.random();
            await mainAPI.get("parastapainnovationip-Key?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.ipnya = Response.data.randkey;
                    }
                    else {
                        this.ipnya = '';
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datapesan = [];
                console.log(error);
            });
            var tahunregister = this.pad2(new Date().getFullYear());
            var bulanregister = this.pad2(new Date().getMonth() + 1);
            var tanggalregister = this.pad2(new Date().getDate());
            var jamregister = this.pad2(new Date().getHours());
            var menitregister = this.pad2(new Date().getMinutes());
            var detikregister = this.pad2(new Date().getSeconds());

            var koderegistrasi = this.makeid(5) + tahunregister + bulanregister + tanggalregister + jamregister + menitregister + detikregister;

            const data = {
                nama: this.nama,
                alamat: this.alamat,
                provinsi: this.selectedprovinsi,
                kabkot: this.selectedkabkot,
                kecamatan: this.selectedkecamatan,
                desa: this.selecteddesa,
                telp: this.telp,
                email: this.email,
                namapemohon: this.namapemohon,
                jabatanpemohon: this.jabatanpemohon,
                unitkerjapemohon: this.unitkerjapemohon,
                nomortelppemohon: this.nomortelppemohon,
                kegiatan: this.kegiatan,
                dinas: this.selecteddinas,
                filenya_permohonan: document.getElementById('filenya_permohonan').value,
                filenya_kak: document.getElementById('filenya_kak').value,
                koderegistrasi: koderegistrasi,
                createdby: this.nama,
            };
            const mainAPIsimpan = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer SiKerenITRGPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2j2a098002" + this.ipnya,
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            });
            await mainAPIsimpan.post("sikejam_pengajuan-Kerjasama", data).then(
                Response => {
                    if (Response.data.response == 'error') {
                        swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000
                        }).fire({
                            icon: 'warning',
                            title: Response.data.message,
                        });
                        this.showHuman = false;
                        document.getElementById('customSwitchAuth').checked = false;
                        return false;

                    }
                    else {
                        swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000
                        }).fire({
                            icon: 'success',
                            title: Response.data.message,
                        });
                        this.selamat = 'Data berhasil disimpan, silahkan melakukan cek progres pada menu Layanan --> Progres Pengajuan dengan memasukan nomor register anda..';
                        this.tulisregister = 'Kode Registrasi anda adalah: ' + koderegistrasi;
                        this.showHuman = false;
                        document.getElementById('customSwitchAuth').checked = false;
                        return false;
                    }
                }
            ).catch(
                error => {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: error,
                    });
                    document.getElementById('customSwitchAuth').checked = false;
                    this.showHuman = false;
                    return false;
                }
            )
        },

    },
    mounted() {
        this.halamanloading = true;
        this.ambildatareff();
    },
}
</script>

<style></style>