<template>
    <Top />
    <!-- Spinner Start -->
    <div id="spinner" v-if="halamanloading"
        class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        &nbsp;<img src="../../../../src/assets/img/BerandaNgumahLogo.png" height="40">
        &nbsp;<span class="text-danger">..Loading...</span>
    </div>
    <!-- Spinner End -->
    <!-- Header Start -->
    <div class="container-fluid bg-breadcrumb">
        <div class="container text-center py-5" style="max-width: 900px;">
            <h3 class="text-white display-3 mb-4 wow fadeInDown pmisputih" data-wow-delay="0.1s">
                <span v-if="(bahasa === null) || (bahasa == 'ina')">TANYA SIKEJAM</span>
                <span v-else>TANYA SIKEJAM</span>
            </h3>
            <ol class="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s">
                <li class="breadcrumb-item">
                    <a href="/">
                        <span v-if="(bahasa === null) || (bahasa == 'ina')">Beranda</span>
                        <span v-else>Beranda</span>
                    </a>
                </li>
                <li class="breadcrumb-item active text-primary">
                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Tajam</span>
                    <span v-else>Tajam</span>
                </li>
            </ol>
        </div>
    </div>
    <!-- Header End -->
    <!-- Contact Start -->
    <div class="container-fluid blog py-2">&nbsp;</div>
    <div class="container-fluid bg-breadcrumbberanda wow fadeInDown">
        <div class="section-title mb-2 wow fadeInDown" data-wow-delay="0.1s">
            <div class="col-lg-12 col-xl-12 wow fadeInRight" data-wow-delay="0.3s">
                <div class="col-lg-12 col-xl-12 wow fadeInRight" data-wow-delay="0.3s">
                    <a href="https://wa.me/628122935745" target="_blank"><img
                            src="../../../../src/assets/img/whatsapp.png" height="50" data-toggle="tooltip"
                            data-placement="top" title="Konsultasi via WhatsApp" style="cursor:pointer;"></a><br>
                    <span v-if="(bahasa === null) || (bahasa == 'ina')"> Konsultasi<br>
                    </span>
                    <span v-if="(bahasa === null) || (bahasa == 'ina')"> Senin-Kamis jam 08:00 - 15:00 WIB; Jumat jam
                        08:00 - 14:00 WIB<br>
                    </span>
                </div>
                <div class="col-lg-12 col-xl-12 wow fadeInDown" data-wow-delay="0.1s">&nbsp;</div>
            </div>
        </div>
    </div>
    <div class="container-fluid blog py-2">
        <div class="container py-2">
            <div class="section-title mb-2 wow fadeInDown" data-wow-delay="0.1s">
                <h1>FAQ</h1>
            </div>
            <div class="col-lg-12 col-xl-12 wow fadeInDown" data-wow-delay="0.1s">
                <div class="panel-group" id="accordion">
                    <div class="panel panel-default" v-for="(data, urutdata) in datanya" :key="urutdata">
                        <div class="panel-heading"
                            style="background-color:rgba(156, 156, 153, 0.5);padding-top: 5px;padding-left: 10px;padding-bottom: 1px;">
                            <h5 class="panel-title" data-toggle="collapse" :data-target="'#collapse1' + data.id"
                                style="cursor:pointer;">
                                <a data-toggle="collapse" data-parent="#accordion" :href="'#collapse1' + data.id"
                                    style="color:#FFFFFF;"> {{ urutdata + 1 }}. {{ data.nama }} </a>
                            </h5>
                        </div>
                        <div :id="'collapse1' + data.id" class="panel-collapse collapse">
                            <div class="panel-body" v-for="(detail, urutdetail) in data.detail" :key="urutdetail">
                                <span v-html="detail.nama"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Contact End -->
    <a href="#" class="btn btn-dangerbulet btn-lg-square back-to-top text-white"><i class="fa fa-arrow-up"></i></a>
    <Footer />
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import swal from 'sweetalert2';

import Top from "./Template/Top.vue";
import Footer from "./Template/Footer.vue";

export default {
    components: {
        Top, Footer, swal
    },
    data() {
        return {
            halamanloading: true,
            tahun: new Date().getFullYear(),
            bahasa: localStorage.getItem("bahasasistem"),
            datapesan: '',
            datanya: [],
            ipnya: '',
        };
    },
    methods: {
        async ambildata() {
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            var random = Math.random();

            await mainAPI.get("parastapainnovationip-Key?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.ipnya = Response.data.randkey;
                    }
                    else {
                        this.ipnya = '';
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datapesan = [];
                console.log(error);
            });

            const mainAPINew = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2" + this.ipnya,
                },
            });

            await mainAPINew.get("sikejam-Tajam?random=" + random).then(
                Response => {
                    this.datanya = [];
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datanya = Response.data.content.data;
                    }
                    else {
                        this.datapesan = "";
                        this.datanya = [];
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datapesan = "";
                console.log(error);
            });
        },
    },
    mounted() {
        this.halamanloading = true;
        this.ambildata();
    },
}
</script>

<style></style>