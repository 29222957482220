<template>
  <div class="content" v-if="route.name == 'Beranda'">
    <Beranda/>
  </div>
  <div class="content" v-if="route.name == 'DataProfil'">
    <DataProfil/>
  </div>
  <div class="content" v-if="route.name == 'DaftarSekilas'">
    <DaftarSekilas/>
  </div>
  <div class="content" v-if="route.name == 'DaftarBerita'">
    <DaftarBerita/>
  </div>
  <div class="content" v-if="route.name == 'DaftarInfografis'">
    <DaftarInfografis/>
  </div>
  <div class="content" v-if="route.name == 'DaftarPustaka'">
    <DaftarPustaka/>
  </div>
  <div class="content" v-if="route.name == 'DaftarProduk'">
    <DaftarProduk/>
  </div>
  <div class="content" v-if="route.name == 'DetailProduk'">
    <DetailProduk/>
  </div>
  <div class="content" v-if="route.name == 'Login'">
    <Login/>
  </div>
  <div class="content" v-if="route.name == 'KontakKami'">
    <KontakKami/>
  </div>
  <div class="content" v-if="route.name == 'DaftarAgenda'">
    <DaftarAgenda/>
  </div>
  <div class="content" v-if="route.name == 'TataCara'">
    <TataCara/>
  </div>
  <div class="content" v-if="route.name == 'Tajam'">
    <Tajam/>
  </div>
  <div class="content" v-if="route.name == 'Mitra'">
    <Mitra/>
  </div>
  <div class="content" v-if="route.name == 'DataSikejam'">
    <DataSikejam/>
  </div>
  <div class="content" v-if="route.name == 'DaftarAlbum'">
    <DaftarAlbum/>
  </div>
  <div class="content" v-if="route.name == 'DaftarGaleri'">
    <DaftarGaleri/>
  </div>
  <div class="content" v-if="route.name == 'Dokumen'">
    <Dokumen/>
  </div>
  <div class="content" v-if="route.name == 'Pengajuan'">
    <Pengajuan/>
  </div>
  <div class="content" v-if="route.name == 'Progres'">
    <Progres/>
  </div>
  <div class="content" v-if="route.name == 'DaftarPengedalian'">
    <DaftarPengedalian/>
  </div>
  <div class="content" v-if="route.name == 'DaftarRTH'">
    <DaftarRTH/>
  </div>
  <div class="content" v-if="route.name == 'DaftarRTR'">
    <DaftarRTR/>
  </div>
  <div class="content" v-if="route.name == 'Dashboard'">
    <Dashboard/>
  </div>
  <div class="content" v-if="route.name == 'Slider'">
    <Slider/>
  </div>
  <div class="content" v-if="route.name == 'ProsesSlider'">
    <ProsesSlider/>
  </div>
  <div class="content" v-if="route.name == 'Infografis'">
    <Infografis/>
  </div>
  <div class="content" v-if="route.name == 'ProsesInfografis'">
    <ProsesInfografis/>
  </div>
  <div class="content" v-if="route.name == 'ProsesIsiGaleri'">
    <ProsesIsiGaleri/>
  </div>
  <div class="content" v-if="route.name == 'InfoBeranda'">
    <InfoBeranda/>
  </div>
  <div class="content" v-if="route.name == 'ProsesInfoBeranda'">
    <ProsesInfoBeranda/>
  </div>
  <div class="content" v-if="route.name == 'Profil'">
    <Profil/>
  </div>
  <div class="content" v-if="route.name == 'ProsesProfil'">
    <ProsesProfil/>
  </div>
  <div class="content" v-if="route.name == 'Sekilas'">
    <Sekilas/>
  </div>
  <div class="content" v-if="route.name == 'ProsesSekilas'">
    <ProsesSekilas/>
  </div>
  <div class="content" v-if="route.name == 'Berita'">
    <Berita/>
  </div>
  <div class="content" v-if="route.name == 'ProsesBerita'">
    <ProsesBerita/>
  </div>
  <div class="content" v-if="route.name == 'Pustaka'">
    <Pustaka/>
  </div>
  <div class="content" v-if="route.name == 'ProsesPustaka'">
    <ProsesPustaka/>
  </div>
  <div class="content" v-if="route.name == 'Produk'">
    <Produk/>
  </div>
  <div class="content" v-if="route.name == 'ProsesProduk'">
    <ProsesProduk/>
  </div>
  <div class="content" v-if="route.name == 'Pelaksanaan'">
    <Pelaksanaan/>
  </div>
  <div class="content" v-if="route.name == 'ProsesPelaksanaan'">
    <ProsesPelaksanaan/>
  </div>
  <div class="content" v-if="route.name == 'User'">
    <User/>
  </div>
  <div class="content" v-if="route.name == 'ProsesUser'">
    <ProsesUser/>
  </div>
  <div class="content" v-if="route.name == 'Data'">
    <Datanya/>
  </div>
  <div class="content" v-if="route.name == 'Data3'">
    <Datanya3/>
  </div>
  <div class="content" v-if="route.name == 'Data4'">
    <Datanya4/>
  </div>
  <div class="content" v-if="route.name == 'Data5'">
    <Datanya5/>
  </div>
  <div class="content" v-if="route.name == 'Data6'">
    <Datanya6/>
  </div>
  <div class="content" v-if="route.name == 'Data7'">
    <Datanya7/>
  </div>
  <div class="content" v-if="route.name == 'GIS'">
    <GIS/>
  </div>
  <div class="content" v-if="route.name == 'KategoriData'">
    <KategoriData/>
  </div>
  <div class="content" v-if="route.name == 'ProsesKategoriData'">
    <ProsesKategoriData/>
  </div>
  <div class="content" v-if="route.name == 'LayerPeta'">
    <LayerPeta/>
  </div>
  <div class="content" v-if="route.name == 'ProsesLayerPeta'">
    <ProsesLayerPeta/>
  </div>
  <div class="content" v-if="route.name == 'DaftarRegulasi'">
    <DaftarRegulasi/>
  </div>
  <div class="content" v-if="route.name == 'Kerjasama'">
    <Kerjasama/>
  </div>
  <div class="content" v-if="route.name == 'ProsesKerjasama'">
    <ProsesKerjasama/>
  </div>
  <div class="content" v-if="route.name == 'PengajuanKerjasama'">
    <PengajuanKerjasama/>
  </div>
  <div class="content" v-if="route.name == 'ProsesPengajuanKerjasama'">
    <ProsesPengajuanKerjasama/>
  </div>
  <div class="content" v-if="route.name == 'Potensi'">
    <Potensi/>
  </div>
  <div class="content" v-if="route.name == 'ProsesPotensi'">
    <ProsesPotensi/>
  </div>
  <div class="content" v-if="route.name == 'Agenda'">
    <Agenda/>
  </div>
  <div class="content" v-if="route.name == 'ProsesAgenda'">
    <ProsesAgenda/>
  </div>
</template>

<script>
import Beranda from './components/views/Publik/Beranda.vue';
import DaftarRegulasi from './components/views/Publik/DaftarRegulasi.vue';
import DataProfil from './components/views/Publik/Profil.vue';
import DaftarSekilas from './components/views/Publik/Sekilas.vue';
import DaftarBerita from './components/views/Publik/Berita.vue';
import DaftarInfografis from './components/views/Publik/Infografis.vue';
import DaftarPustaka from './components/views/Publik/Pustaka.vue';
import DaftarProduk from './components/views/Publik/Produk.vue';
import DetailProduk from './components/views/Publik/DetailProduk.vue';
import Login from './components/views/Publik/Login.vue';
import KontakKami from './components/views/Publik/KontakKami.vue';
import DaftarAgenda from './components/views/Publik/DaftarAgenda.vue';
import TataCara from './components/views/Publik/TataCara.vue';
import Tajam from './components/views/Publik/Tajam.vue';
import Mitra from './components/views/Publik/Mitra.vue';
import DataSikejam from './components/views/Publik/DataSikejam.vue';
import DaftarAlbum from './components/views/Publik/DaftarAlbum.vue';
import DaftarGaleri from './components/views/Publik/DaftarGaleri.vue';
import Dokumen from './components/views/Publik/Dokumen.vue';
import Pengajuan from './components/views/Publik/Pengajuan.vue';
import Progres from './components/views/Publik/Progres.vue';
import GIS from './components/views/Publik/GIS.vue';
import DaftarPengedalian from './components/views/Publik/DaftarPengedalian.vue';
import DaftarRTH from './components/views/Publik/DaftarRTH.vue';
import DaftarRTR from './components/views/Publik/DaftarRTR.vue';
import Dashboard from './components/views/Dashboard/Dashboard.vue';
import Slider from './components/views/Backoffice/Slider/Slider.vue';
import ProsesSlider from './components/views/Backoffice/Slider/ProsesSlider.vue';
import Infografis from './components/views/Backoffice/Infografis/Infografis.vue';
import ProsesInfografis from './components/views/Backoffice/Infografis/ProsesInfografis.vue';
import ProsesIsiGaleri from './components/views/Backoffice/Infografis/ProsesIsiGaleri.vue';
import InfoBeranda from './components/views/Backoffice/InfoBeranda/InfoBeranda.vue';
import ProsesInfoBeranda from './components/views/Backoffice/InfoBeranda/ProsesInfoBeranda.vue';
import Profil from './components/views/Backoffice/Profil/Profil.vue';
import ProsesProfil from './components/views/Backoffice/Profil/ProsesProfil.vue';
import Sekilas from './components/views/Backoffice/Sekilas/Sekilas.vue';
import ProsesSekilas from './components/views/Backoffice/Sekilas/ProsesSekilas.vue';
import Berita from './components/views/Backoffice/Berita/Berita.vue';
import ProsesBerita from './components/views/Backoffice/Berita/ProsesBerita.vue';
import Pustaka from './components/views/Backoffice/Pustaka/Pustaka.vue';
import ProsesPustaka from './components/views/Backoffice/Pustaka/ProsesPustaka.vue';
import Produk from './components/views/Backoffice/Produk/Produk.vue';
import ProsesProduk from './components/views/Backoffice/Produk/ProsesProduk.vue';
import Pelaksanaan from './components/views/Backoffice/Pelaksanaan/Pelaksanaan.vue';
import ProsesPelaksanaan from './components/views/Backoffice/Pelaksanaan/ProsesPelaksanaan.vue';
import User from './components/views/Backoffice/User/User.vue';
import ProsesUser from './components/views/Backoffice/User/ProsesUser.vue';
import Datanya from './components/views/Backoffice/Data/Data.vue';
import Datanya3 from './components/views/Backoffice/Data/Data3.vue';
import Datanya4 from './components/views/Backoffice/Data/Data4.vue';
import Datanya5 from './components/views/Backoffice/Data/Data5.vue';
import Datanya6 from './components/views/Backoffice/Data/Data6.vue';
import Datanya7 from './components/views/Backoffice/Data/Data7.vue';
import LayerPeta from './components/views/Backoffice/LayerPeta/LayerPeta.vue';
import ProsesLayerPeta from './components/views/Backoffice/LayerPeta/ProsesLayerPeta.vue';
import KategoriData from './components/views/Backoffice/KategoriData/KategoriData.vue';
import ProsesKategoriData from './components/views/Backoffice/KategoriData/ProsesKategoriData.vue';
import Kerjasama from './components/views/Backoffice/Kerjasama/Kerjasama.vue';
import ProsesKerjasama from './components/views/Backoffice/Kerjasama/ProsesKerjasama.vue';
import Potensi from './components/views/Backoffice/Potensi/Potensi.vue';
import ProsesPotensi from './components/views/Backoffice/Potensi/ProsesPotensi.vue';
import Agenda from './components/views/Backoffice/Agenda/Agenda.vue';
import ProsesAgenda from './components/views/Backoffice/Agenda/ProsesAgenda.vue';
import PengajuanKerjasama from './components/views/Backoffice/PengajuanKerjasama/PengajuanKerjasama.vue';
import ProsesPengajuanKerjasama from './components/views/Backoffice/PengajuanKerjasama/ProsesPengajuanKerjasama.vue';

import { useRoute } from 'vue-router';
import { watch } from 'vue';

export default {
  name: 'App',
  components: {
    Beranda,Login,KontakKami,Tajam,Mitra,DataSikejam,TataCara,Dokumen,DaftarAlbum,DaftarGaleri
    ,DataProfil,DaftarSekilas,DaftarRTR,DaftarRTH,DaftarPengedalian,DaftarRegulasi
    ,DaftarBerita,DaftarPustaka,Dashboard,DaftarInfografis,DaftarProduk,DetailProduk
    ,Slider,ProsesSlider,InfoBeranda,ProsesInfoBeranda,Profil,ProsesProfil
    ,Infografis,ProsesInfografis,ProsesIsiGaleri
    ,User,ProsesUser,Datanya,Datanya3,Datanya4
    ,Datanya5,Datanya6,Datanya7,Sekilas,ProsesSekilas,Berita,ProsesBerita
    ,Pustaka,ProsesPustaka,Produk,ProsesProduk,GIS,Pelaksanaan,ProsesPelaksanaan
    ,KategoriData,ProsesKategoriData,LayerPeta,ProsesLayerPeta,Kerjasama,ProsesKerjasama
    ,Potensi,ProsesPotensi,Agenda,ProsesAgenda,DaftarAgenda,Pengajuan,PengajuanKerjasama
    ,ProsesPengajuanKerjasama,Progres
  },
  mounted() {
    document.title = "Sikejam";
  },
  setup() {
    const route = useRoute()
    watch(() => route.name, () => {
    });

    return { route };
  }
}
</script>

<style></style>
