<template>
    <Top />
    <!-- Spinner Start -->
    <div id="spinner" v-if="halamanloading"
        class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        &nbsp;<img src="../../../../src/assets/img/BerandaNgumahLogo.png" height="40">
        &nbsp;<span class="text-danger">..Loading...</span>
    </div>
    <!-- Spinner End -->
    <!-- Header Start -->
    <div class="container-fluid bg-breadcrumb">
        <div class="container text-center py-5" style="max-width: 900px;">
            <h3 class="text-white display-3 mb-4 wow fadeInDown pmisputih" data-wow-delay="0.1s">
                <span v-if="(bahasa === null) || (bahasa == 'ina')">DOKUMEN SIKEJAM</span>
                <span v-else>DOKUMEN SIKEJAM</span>
            </h3>
            <ol class="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s">
                <li class="breadcrumb-item">
                    <a href="/">
                        <span v-if="(bahasa === null) || (bahasa == 'ina')">Beranda</span>
                        <span v-else>Beranda</span>
                    </a>
                </li>
                <li class="breadcrumb-item active text-primary">
                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Daftar Dokumen</span>
                    <span v-else>Daftar Dokumen</span>
                </li>
            </ol>
        </div>
    </div>
    <!-- Header End -->
    <!-- Contact Start -->
    <div class="container-fluid blog py-2">&nbsp;</div>
    <div class="container-fluid blog py-2">
        <div class="container py-2">
            <div class="col-sm-12 wow fadeInDown">
                <div class="card cardputih">
                    <div class="card-header border-transparent">
                        <div class="card-tools">
                            <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                <i class="fas fa-minus"></i>
                            </button>
                        </div>
                        <div class="col-sm-12 float-left pb-2">&nbsp;</div>
                        <div class="col-sm-12 float-left pb-2 px-3 wow fadeInDown">
                            Jumlah Data {{ G_numFormat(jumlahdata) }}
                            <span style="float: right;">
                                <span>Halaman ke: </span>
                                <select v-model="halaman" @change="onChangeHalaman($event)">
                                    <option v-for="index in jumlahhalaman" :key="index">
                                        {{ index }}</option>
                                </select>
                                <span> dari </span> {{ jumlahhalaman }}
                            </span>
                        </div>
                        <div class="col-sm-12 float-left pb-2">

                        </div>
                    </div>
                    <div class="card-body p-2">
                        <div class="table-responsive table-wrapper">
                            <table class="table m-0" id="tabeldata" style="font-size: small;">
                                <thead>
                                    <tr>
                                        <th style="width: 50px;">No</th>
                                        <th style="width: 450px;">Dokumen</th>
                                    </tr>
                                    <tr>
                                        <th colspan="2">
                                            <input type="text" v-model="nama" class="form-control form-control-sm"
                                                placeholder="Masukkan Kata Kunci Pencarian" @keyup="ambildata()">
                                        </th>
                                    </tr>
                                </thead>
                                <template v-for="(datalist, urutlist) in datanya" :key="urutlist">
                                    <tbody>
                                        <tr>
                                            <td class="text-center">
                                                {{ (urutlist + 1) }}
                                            </td>
                                            <td class="text-justify">
                                                <a :href="folder_foto + datalist.dokumen" style="color:#000000;">
                                                    <i class="far fa-file-pdf"></i>
                                                    {{ (datalist.nama) }}
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                                <tbody v-if="(datapesan == 'data kosong')">
                                    <tr>
                                        <label>Data masih kosong</label>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="card-footer p-3">

                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Contact End -->
    <a href="#" class="btn btn-dangerbulet btn-lg-square back-to-top text-white"><i class="fa fa-arrow-up"></i></a>
    <Footer />
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import swal from 'sweetalert2';
import VueApexCharts from "vue3-apexcharts";
window.Apex.chart = { fontFamily: "Poppins-SemiBold, Arial, sans-serif" };
import Top from "./Template/Top.vue";
import Footer from "./Template/Footer.vue";

export default {
    components: {
        Top, Footer, swal
    },
    data() {
        return {
            halamanloading: true,
            tahun: new Date().getFullYear(),
            bahasa: localStorage.getItem("bahasasistem"),
            datapesan: '',
            datanya: [],
            carikata: '',
            jumlahdata: 0,
            jumlahhalaman: 0,
            mulai: 0,
            halaman: 1,
            folder_foto: process.env.VUE_APP_URL_API + 'parastapainnovation_dokumen-PDF?f=',
            nama: '',
            ipnya: '',
            
        };
    },
    computed: {
        filteredKataData() {
            const filteredWorkers = this.carikata === ""
                ? this.datanya
                : this.datanya.filter(wo => Object.values(wo).join("").toLowerCase().indexOf(this.carikata.toLowerCase()) !== -1);
            return filteredWorkers;
        },
        JumlahfilteredKataData() {
            var jumlah = 0;
            try {
                if (this.filteredKataData === undefined) {
                    jumlah = 0;
                } else {
                    jumlah = this.filteredKataData.length;
                }
            }
            catch {
                jumlah = 0;
            }
            return jumlah;
        },
    },
    methods: {
        onChangeHalaman(event) {
            this.pindahhalaman(event.target.value);
        },
        async pindahhalaman(halamannya) {
            this.halamanloading = true;

            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            var random = Math.random();

            await mainAPI.get("parastapainnovationip-Key?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.ipnya = Response.data.randkey;
                    }
                    else {
                        this.ipnya = '';
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datapesan = "";
                console.log(error);
            });

            const mainAPINew = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2" + this.ipnya,
                },
            });

            await mainAPINew.get("sikejam-Dokumen?random=" + random + "&nama=" + this.nama + "&halaman=" + halamannya).then(
                Response => {
                    this.datanya = [];
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datanya = Response.data.content.data;
                        this.jumlahdata = Response.data.content.jumlahdata;
                        this.jumlahhalaman = Response.data.content.jumlahhalaman;
                        this.mulai = Response.data.content.mulai;
                        if (this.mulai == 0) {
                            this.halaman = 1;
                        }
                        else {
                            this.halaman = (this.mulai / 20) + 1;
                        }
                    }
                    else {
                        this.datanya = [];
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datapesan = "";
                console.log(error);
            });
        },
        async ambildata() {
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            var random = Math.random();

            await mainAPI.get("parastapainnovationip-Key?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.ipnya = Response.data.randkey;
                    }
                    else {
                        this.ipnya = '';
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datapesan = "";
                console.log(error);
            });

            const mainAPINew = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2" + this.ipnya,
                },
            });

            await mainAPINew.get("sikejam-Dokumen?random=" + random + "&nama=" + this.nama).then(
                Response => {
                    this.datanya = [];
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datanya = Response.data.content.data;
                        this.jumlahdata = Response.data.content.jumlahdata;
                        this.jumlahhalaman = Response.data.content.jumlahhalaman;
                        this.mulai = Response.data.content.mulai;
                        if (this.mulai == 0) {
                            this.halaman = 1;
                        }
                        else {
                            this.halaman = (this.mulai / 20) + 1;
                        }
                    }
                    else {
                        this.datapesan = "";
                        this.datanya = [];
                        this.jumlahdata = 0;
                        this.jumlahhalaman = 0;
                        this.mulai = 0;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
            });
        },
    },
    mounted() {
        this.halamanloading = true;
        this.ambildata();
    },
}
</script>

<style></style>