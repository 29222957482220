<template>
    <Top />
    <!-- Spinner Start -->
    <div id="spinner" v-if="halamanloading"
        class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        &nbsp;<img src="../../../../src/assets/img/BerandaNgumahLogo.png" height="40">
        &nbsp;<span class="text-danger">..Loading...</span>
    </div>
    <!-- Spinner End -->
    <!-- Header Start -->
    <div id="myModal" class="modal">
        <span class="close">&times;</span>
        <img class="modal-content" id="img01">
        <div id="caption"></div>
    </div>
    <div class="container-fluid bg-breadcrumb">
        <div class="container text-center py-5" style="max-width: 900px;">
            <h3 class="text-white display-3 mb-4 wow fadeInDown pmisputih" data-wow-delay="0.1s">
                <span v-if="(bahasa === null) || (bahasa == 'ina')">MITRA SIKEJAM</span>
                <span v-else>MITRA SIKEJAM<</span>
            </h3>
            <ol class="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s">
                <li class="breadcrumb-item">
                    <a href="/">
                        <span v-if="(bahasa === null) || (bahasa == 'ina')">Beranda</span>
                        <span v-else>Beranda</span>
                    </a>
                </li>
                <li class="breadcrumb-item active text-primary">
                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Mitra</span>
                    <span v-else>Mitra</span>
                </li>
            </ol>
        </div>
    </div>
    <!-- Header End -->
    <div class="container-fluid blog py-5">
        <div class="container py-5">
            <div class="section-title mb-5 wow fadeInUp" data-wow-delay="0.1s"
                style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInUp;">
                <div class="section-title mb-2 wow fadeInDown" data-wow-delay="0.1s">
                <h1>Daftar Mitra Kerjasama Provinsi Jawa Tengah</h1>
            </div>
            </div>
            <div class="row g-4 justify-content-center">
                <div class="col-sm-12 float-left pb-2 px-3 wow fadeInDown">
                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Jumlah Data Mitra:</span> <span v-else>Jumlah Data Mitra:</span> {{ G_numFormat(jumlahdata) }}
                    <span style="float: right;">
                        <span v-if="(bahasa === null) || (bahasa == 'ina')">Halaman ke: </span> <span v-else>Page no:
                        </span><select v-model="halaman" @change="onChangeHalaman($event)">
                            <option v-for="index in jumlahhalaman" :key="index">
                                {{ index }}</option>
                        </select> <span v-if="(bahasa === null) || (bahasa == 'ina')"> dari </span> <span v-else> from
                        </span> {{ jumlahhalaman }}
                    </span>
                </div>
                <div class="col-sm-12 float-left wow fadeInDown">
                    <input type="text" v-model="nama" class="form-control form-control-sm"
                        placeholder="Masukkan Kata Kunci Pencarian" @keyup="ambildata()">
                </div>
                <div class="col-md-3 col-lg-3 col-xl-3 wow fadeInUp" data-wow-delay="0.1s"
                    style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInUp;"
                    v-for="(beranda, urutberanda) in datanya" :key="urutberanda">
                    <div class="blog-item" style="background-color:rgba(255, 255, 255, .1)">
                        <a href="javascript:void(0)" class="pmisjudulberita" @click="ambilgambar(beranda.alamat)" data-toggle="tooltip" data-placement="bottom"
                            title="Lihat Mitra..">
                        <div class="blog-img" style="background-color:rgba(255, 255, 255, .1);border-style: none !important;">
                            <img v-if="(bahasa === null) || (bahasa == 'ina')" :id="beranda.id"
                                :src="folder_foto + beranda.filenya" class="img-fluid w-100 rounded-circle w-100 h-100"
                                :alt="beranda.kontenfull" @click="ambilgambar(beranda.alamat)" style="min-height: 250px;max-height: 250px;">
                            <img v-else :id="beranda.id" ::src="folder_foto + beranda.filenya" class="img-fluid w-100 rounded-circle w-100 h-100"
                            :alt="beranda.kontenfull" @click="ambilgambar(beranda.alamat)" style="min-height: 250px;max-height: 250px;">
                        </div>
                        </a>
                        <div class="blog-centent p-4" style="background-color:rgba(255, 255, 255, .1);border-style: none !important;">
                            <a href="javascript:void(0)" class="pmisjudulberita" @click="ambilgambar(beranda.alamat)" data-toggle="tooltip" data-placement="bottom"
                            title="Lihat Mitra..">
                            {{ beranda.nama }}
                            </a>
                            
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <a href="#" class="btn btn-dangerbulet btn-lg-square back-to-top text-white"><i class="fa fa-arrow-up"></i></a>
    <Footer />
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import swal from 'sweetalert2';
import CryptoJS from 'crypto-js';
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'

import Top from "./Template/Top.vue";
import Footer from "./Template/Footer.vue";

export default {
    components: {
        Top, Footer, swal, Carousel, Slide, Pagination, Navigation,
    },
    data() {
        return {
            halamanloading: true,
            datapesan: '',
            carikata: '',
            datamaster: [],
            datanya: [],
            tahun: new Date().getFullYear(),
            bahasa: localStorage.getItem("bahasasistem"),
            folder_foto: process.env.VUE_APP_URL_API + 'parastapainnovation_mitra-Gambar?f=',
            jumlahdata: 0,
            jumlahhalaman: 0,
            mulai: 0,
            halaman: 1,
            nama:'',
            ipnya: '',
        };
    },
    methods: {
        ambilgambar(kode){
            window.open(kode, '_blank').focus();
        },
        onChangeHalaman(event) {
            this.pindahhalaman(event.target.value);
        },
        async pindahhalaman(halamannya) {
            this.halamanloading = true;

            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            var random = Math.random();

            await mainAPI.get("parastapainnovationip-Key?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.ipnya = Response.data.randkey;
                    }
                    else {
                        this.ipnya = '';
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datapesan = "";
                console.log(error);
            });
            
            const mainAPINew = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2" + this.ipnya,
                },
            });

            await mainAPINew.get("sikejam-Mitra?random=" + random + "&nama=" + this.nama+ "&halaman=" + halamannya).then(
                Response => {
                    this.datanya = [];
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datanya = Response.data.content.data;
                        this.jumlahdata = Response.data.content.jumlahdata;
                        this.jumlahhalaman = Response.data.content.jumlahhalaman;
                        this.mulai = Response.data.content.mulai;
                        if (this.mulai == 0) {
                            this.halaman = 1;
                        }
                        else {
                            this.halaman = (this.mulai / 20) + 1;
                        }
                    }
                    else {
                        this.datapesan = "";
                            this.datanya = [];
                            this.jumlahdata = 0;
                            this.jumlahhalaman = 0;
                            this.mulai = 0;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datapesan = "";
                console.log(error);
            });
        },
        async ambildata() {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            var random = Math.random();

            await mainAPI.get("parastapainnovationip-Key?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.ipnya = Response.data.randkey;
                    }
                    else {
                        this.ipnya = '';
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datapesan = "";
                console.log(error);
            });

            const mainAPINew = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2" + this.ipnya,
                },
            });

            await mainAPINew.get("sikejam-Mitra?random=" + random + "&nama=" + this.nama).then(
                Response => {
                    this.datanya = [];
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datanya = Response.data.content.data;
                        this.jumlahdata = Response.data.content.jumlahdata;
                        this.jumlahhalaman = Response.data.content.jumlahhalaman;
                        this.mulai = Response.data.content.mulai;
                        if (this.mulai == 0) {
                            this.halaman = 1;
                        }
                        else {
                            this.halaman = (this.mulai / 20) + 1;
                        }
                    }
                    else {
                        this.datapesan = "";
                            this.datanya = [];
                            this.jumlahdata = 0;
                            this.jumlahhalaman = 0;
                            this.mulai = 0;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datapesan = "";
                console.log(error);
            });
        },
    },
    mounted() {
        this.ambildata();
    },
}
</script>

<style></style>