<template>
    <Top />
    <!-- Spinner Start -->
    <div id="spinner" v-if="halamanloading"
        class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        &nbsp;<img src="../../../../src/assets/img/BerandaNgumahLogo.png" height="40">
        &nbsp;<span class="text-danger">..Loading...</span>
    </div>
    <!-- Spinner End -->
    <!-- Header Start -->
    <div class="container-fluid bg-breadcrumb">
        <div class="container text-center py-5" style="max-width: 900px;">
            <h3 class="text-white display-3 mb-4 wow fadeInDown pmisputih" data-wow-delay="0.1s">
                <span v-if="(bahasa === null) || (bahasa == 'ina')">PROGRES PENGAJUAN KERJASAMA</span>
                <span v-else>PROGRES PENGAJUAN KERJASAMA</span>
            </h3>
            <ol class="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s">
                <li class="breadcrumb-item">
                    <a href="/">
                        <span v-if="(bahasa === null) || (bahasa == 'ina')">Beranda</span>
                        <span v-else>Home</span>
                    </a>
                </li>
                <li class="breadcrumb-item active text-primary">
                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Progres Pengajuan Kerjasama</span>
                    <span v-else>Progres Pengajuan Kerjasama</span>
                </li>
            </ol>
        </div>
    </div>
    <!-- Header End -->
    <!-- Contact Start -->
    <div class="container-fluid blog py-2">&nbsp;</div>
    <div class="container-fluid bg-breadcrumbberanda px-5 wow fadeInDown">
        <div class="row">
            <div class="help-block text-center wow fadeInLeft">
                Silahkan masukkan kode registrasi yang dimiliki dan klik tombol cari untuk melihat progres pengajuan
                kerjasama.
            </div>
            <div class="col-sm-12">&nbsp;</div>
            <div class="col-sm-12">&nbsp;</div>
            <div class="col-sm-4">&nbsp;</div>
            <div class="col-sm-4">
                <div class="col-sm-12 text-center pb-2 wow fadeInDown">
                    <label>Kode Registrasi <small class="text-info"> (*wajib diisi)</small></label>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="fas fa-user-circle"></i></span>
                        </div>
                        <input type="text" class="form-control form-control-sm" id="koderegistrasi"
                            name="koderegistrasi" placeholder="Kode Registrasi" v-model="koderegistrasi">
                    </div>
                </div>
                <div class="col-sm-12 text-center pb-2 wow fadeInDown">
                    <label>Kode Keamanan</label>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="fas fa-clipboard-list"></i></span>
                        </div>
                        <input type="text" class="form-control form-control-sm" id="capcha" name="capcha"
                            placeholder="Kode Keamanan" v-model="capcha" disabled>
                    </div>
                </div>
                <div class="col-sm-12 text-center pb-2 wow fadeInDown">
                    <label>Ulangi Kode Keamanan</label>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="far fa-clone"></i></span>
                        </div>
                        <input type="text" class="form-control form-control-sm" id="capchainput" name="capchainput"
                            placeholder="Ulangi Kode Keamanan" v-model="capchainput" style="text-transform:uppercase">
                    </div>
                </div>
            </div>
            <div class="col-sm-4">&nbsp;</div>
        </div>
    </div>
    <div class="lockscreen-item wow fadeInLeft" style="background: transparent;">
        <div class="input-group">
            <div class="custom-control custom-switch custom-switch-on-primary">
                <input type="checkbox" class="custom-control-input" id="customSwitchAuth">
                <label class="custom-control-label" for="customSwitchAuth" @click="rubahisi()"
                    style="font-size: small;">
                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Autentikasi Pengguna Manusia</span>
                    <span v-else>Human User Authentication</span>
                </label>
            </div>
        </div>
        <div class="help-block text-center">&nbsp;</div>
        <button class="btn btn-danger2 btn-block text-white" placeholder="Kirim" data-toggle="tooltip"
            data-placement="left" title="Kirim aduan.." style="color: black;" v-on:click="CetakAct"
            :disabled="showHuman == false">
            <b><i class="fas fa-search"></i>
                Cari
            </b>
        </button>
    </div>

    <div class="help-block text-center">&nbsp;</div>
    <div class="row px-5" v-if="datamaster.length > 0">
        <div class="col-sm-12">
            <div class="table-responsive table-wrapper">
                <table class="table m-0" id="tabeldata" style="font-size: smaller;">
                    <thead>
                        <tr>
                            <th style="width: 50px;">No</th>
                            <th style="width: 100px;">Instansi</th>
                            <th style="width: 150px;">Rencana Judul Kerjasama</th>
                            <th style="width: 100px;">Tanggal Pengajuan</th>
                            <th style="width: 100px;">Tanggal Update</th>
                            <th style="width: 100px;">Status</th>
                            <th style="width: 200px;">Keterangan</th>
                        </tr>
                    </thead>
                    <template v-for="(datalist, urutlist) in datamaster" :key="urutlist">
                        <tbody>
                            <tr>
                                <td class="text-center">{{ (urutlist + 1) }}</td>
                                <td class="text-justify">
                                    {{ datalist.nama }}
                                </td>
                                <td class="text-justify">
                                    {{ datalist.kegiatan }}
                                </td>
                                <td class="text-center">
                                    {{ G_formatDateNonWIB(datalist.createddate) }}
                                </td>
                                <td class="text-center">
                                    {{ G_formatDateNonWIB(datalist.updatedate) }}
                                </td>
                                <td class="text-justify">
                                    {{ datalist.NAMASTATUS }}
                                </td>
                                <td class="text-justify">
                                    <span v-html="datalist.keterangan"></span>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                    <tbody v-if="(datapesan == 'data kosong')">
                        <tr>
                            <td colspan="4">Data masih kosong</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="container-fluid blog py-2">&nbsp;</div>
    <!-- Contact End -->
    <a href="#" class="btn btn-dangerbulet btn-lg-square back-to-top text-white"><i class="fa fa-arrow-up"></i></a>
    <Footer />
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import swal from 'sweetalert2';
import CryptoJS from 'crypto-js';
import Top from "./Template/Top.vue";
import Footer from "./Template/Footer.vue";

export default {
    components: {
        Top, Footer, swal
    },
    data() {
        return {
            halamanloading: true,
            tahun: new Date().getFullYear(),
            bahasa: localStorage.getItem("bahasasistem"),
            sourcenya: '',
            koderegistrasi: "", capcha: this.makeid(5), capchainput: "",
            showHuman: false,
            showPassword: false,
            datamaster: [],
            datapesan: '',
            ipnya: '',
        };
    },
    methods: {
        async CetakAct() {
            var md5Hash = CryptoJS.MD5(this.koderegistrasi.trim());
            const data = {
                koderegistrasi: md5Hash,
            };
            if (this.koderegistrasi == "") {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Isikan kolom secara lengkap..',
                });
                document.getElementById('customSwitchAuth').checked = false;
                document.getElementById("koderegistrasi").focus();
                this.showHuman = false;
                document.getElementById('customSwitchAuth').checked = false;
                return false;
            }
            if (this.capchainput == "") {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Isikan kolom secara lengkap..',
                });
                document.getElementById('customSwitchAuth').checked = false;
                document.getElementById("capchainput").focus();
                this.showHuman = false;
                document.getElementById('customSwitchAuth').checked = false;
                return false;
            }
            if (this.capchainput.toUpperCase() != this.capcha) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Kode Keamanan Tidak Sama..',
                });
                document.getElementById('customSwitchAuth').checked = false;
                document.getElementById("capchainput").focus();
                this.showHuman = false;
                document.getElementById('customSwitchAuth').checked = false;
                return false;
            }
            this.halamanloading = true;
            const mainAPIIP = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            var random = Math.random();
            await mainAPIIP.get("parastapainnovationip-Key?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.ipnya = Response.data.randkey;
                    }
                    else {
                        this.ipnya = '';
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datapesan = [];
                console.log(error);
            });
            this.halamanloading = false;

            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer SiKerenITRGPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2j2a098002" + this.ipnya,
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            });
            await mainAPI.post("sikejam_pengajuan-CariKerjasama", data).then(
                Response => {
                    if (Response.data.response == 'error') {
                        swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000
                        }).fire({
                            icon: 'warning',
                            title: Response.data.message,
                        });
                        document.getElementById("koderegistrasi").focus();
                        this.showHuman = false;
                        document.getElementById('customSwitchAuth').checked = false;
                        this.halamanloading = false;
                        return false;

                    }
                    else {
                        swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000
                        }).fire({
                            icon: 'success',
                            title: Response.data.message,
                        });
                        this.datapesan = Response.data.message;
                        if (this.datapesan == 'data diketemukan') {
                            this.datamaster = [];
                            this.datamaster = Response.data.content.data;
                        }
                        this.halamanloading = false;
                        this.showHuman = false;
                        document.getElementById('customSwitchAuth').checked = false;

                        return false;
                    }
                }
            ).catch(
                error => {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: error,
                    });
                    document.getElementById("koderegistrasi").focus();
                    document.getElementById('customSwitchAuth').checked = false;
                    this.showHuman = false;
                    this.halamanloading = false;
                    return false;
                }
            )
        },
        rubahisi() {
            if (this.showHuman == false) {
                this.showHuman = true;
            }
            else {
                this.showHuman = false;
            }
        },
    },
    mounted() {
        this.halamanloading = false;
    },
}
</script>

<style></style>