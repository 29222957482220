<template>
    <Top />
    <!-- Spinner Start -->
    <div id="spinner" v-if="halamanloading"
        class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        &nbsp;<img src="../../../../src/assets/img/BerandaNgumahLogo.png" height="40">
        &nbsp;<span class="text-danger">..Loading...</span>
    </div>
    <!-- Spinner End -->
    <!-- Header Start -->
    <div id="myModal" class="modal">
        <span class="close">&times;</span>
        <img class="modal-content" id="img01">
        <div id="caption"></div>
    </div>
    <div class="container-fluid bg-breadcrumb">
        <div class="container text-center py-5" style="max-width: 900px;">
            <h3 class="text-white display-3 mb-4 wow fadeInDown pmisputih" data-wow-delay="0.1s">
                <span v-if="(bahasa === null) || (bahasa == 'ina')">Daftar Penyusunan & Penetapan Rencana Tata
                    Ruang</span>
                <span v-else>Daftar Penyusunan & Penetapan Rencana Tata Ruang</span>
            </h3>
            <ol class="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s">
                <li class="breadcrumb-item">
                    <a href="/">
                        <span v-if="(bahasa === null) || (bahasa == 'ina')">Beranda</span>
                        <span v-else>Home</span>
                    </a>
                </li>
                <li class="breadcrumb-item active text-primary">
                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Penyusunan & Penetapan Rencana Tata Ruang</span>
                    <span v-else>Penyusunan & Penetapan Rencana Tata Ruang</span>
                </li>
            </ol>
        </div>
    </div>
    <!-- Header End -->
    <!-- Contact Start -->
    <div class="container-fluid blog py-2">&nbsp;</div>
    <div class="container-fluid bg-breadcrumbberanda wow fadeInDown">
        <div class="row g-5 align-items-center">
            <div class="col-sm-1 wow fadeInLeft" data-wow-delay="0.3s">&nbsp;</div>
            <div class="col-sm-5 wow fadeInLeft" data-wow-delay="0.3s">
                <img src="../../../../src/assets/img/rtrw.png" alt="Proses Penyusunan RTRW" id="rtrw" class="img-fluid w-100"
                    style="height: 90vh;cursor: pointer;" @click="ambilgambar('rtrw', 'Proses Penyusunan RTRW')">
            </div>
            <div class="col-sm-5 wow fadeInRight" data-wow-delay="0.3s">
                <img src="../../../../src/assets/img/rtr.png" alt="Proses Penyusunan RTR" id="rtr" class="img-fluid w-100"
                    style="height: 60vh;cursor: pointer;" @click="ambilgambar('rtr', 'Proses Penyusunan RTR')">
            </div>
            <div class="col-sm-1 wow fadeInLeft" data-wow-delay="0.3s">&nbsp;</div>
        </div>
        <div class="section-title mb-2 wow fadeInDown" data-wow-delay="0.1s">

            <div class="col-lg-12 col-xl-12 wow fadeInLeft" data-wow-delay="0.3s">
                <div class="col-lg-12 col-xl-12 wow fadeInLeft" data-wow-delay="0.3s">&nbsp;</div>
                <div class="col-lg-12 col-xl-12 wow fadeInLeft" data-wow-delay="0.3s">

                    <div class="d-flex justify-content-center mb-4">
                        <a class="btn btn-primary rounded-pill text-white py-3 px-5" href="/DaftarPengedalian">
                            Lihat Progres RTRW
                        </a>
                        <a class="btn btn-primary rounded-pill text-white py-3 px-5" href="/DaftarPengedalian">
                            Lihat Progres RTR
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Contact End -->
    <a href="#" class="btn btn-dangerbulet btn-lg-square back-to-top text-white"><i class="fa fa-arrow-up"></i></a>
    <Footer />
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import swal from 'sweetalert2';

import Top from "./Template/Top.vue";
import Footer from "./Template/Footer.vue";

export default {
    components: {
        Top, Footer, swal
    },
    data() {
        return {
            halamanloading: true,
            tahun: new Date().getFullYear(),
            bahasa: localStorage.getItem("bahasasistem"),
            sourcenya: '',
            datapesan: '',
            datamaster: [],
            datamasterrdtr: [],
        };
    },
    methods: {
        ambilgambar(id, text) {
            var modal = document.getElementById("myModal");
            // Get the image and insert it inside the modal - use its "alt" text as a caption
            var img = document.getElementById(id);
            var modalImg = document.getElementById("img01");
            var captionText = document.getElementById("caption");
            modal.style.display = "block";
            modalImg.src = img.src;
            captionText.innerHTML = text;

            // Get the <span> element that closes the modal
            var span = document.getElementsByClassName("close")[0];

            // When the user clicks on <span> (x), close the modal
            span.onclick = function () {
                modal.style.display = "none";
            }
        },
        async loadrtrw() {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });

            this.halamanloading = true;
            var random = Math.random();
            await mainAPI.get("parastapainnovationproses-GetRTRW?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datamaster = Response.data.content.data;
                    }
                    else {
                        this.datapesan = [];
                        this.datamaster = [];
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datapesan = [];
                this.datamaster = [];

                this.halamanloading = false;
            });
        },
        async loadrdtr() {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });

            this.halamanloading = true;
            var random = Math.random();
            await mainAPI.get("parastapainnovationproses-GetRDTR?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datamasterrdtr = Response.data.content.data;
                    }
                    else {
                        this.datapesan = [];
                        this.datamasterrdtr = [];
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datapesan = [];
                this.datamasterrdtr = [];

                this.halamanloading = false;
            });
        },
    },
    mounted() {
        this.loadrtrw();
        this.loadrdtr();
    },
}
</script>

<style></style>