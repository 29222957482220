<template>
    <Top />
    <!-- Spinner Start -->
    <div id="spinner" v-if="halamanloading"
        class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        &nbsp;<img src="../../../../src/assets/img/BerandaNgumahLogo.png" height="40">
        &nbsp;<span class="text-danger">..Loading...</span>
    </div>
    <!-- Spinner End -->
    <!-- Header Start -->
    <div class="container-fluid bg-breadcrumb">
        <div class="container text-center py-5" style="max-width: 900px;">
            <h3 class="text-white display-3 mb-4 wow fadeInDown pmisputih" data-wow-delay="0.1s">
                <span v-if="(bahasa === null) || (bahasa == 'ina')">PRODUK HUKUM</span>
                <span v-else>PRODUK HUKUM</span>
            </h3>
            <ol class="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s">
                <li class="breadcrumb-item">
                    <a href="/">
                        <span v-if="(bahasa === null) || (bahasa == 'ina')">Beranda</span>
                        <span v-else>Home</span>
                    </a>
                </li>
                <li class="breadcrumb-item active text-primary">
                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Produk Hukum Tata Ruang</span>
                    <span v-else>Produk Hukum Tata Ruang</span>
                </li>
            </ol>
        </div>
    </div>
    <!-- Header End -->
    <!-- Contact Start -->
    <div class="container-fluid blog py-2">&nbsp;</div>
    <div class="container-fluid blog py-2">
        <div class="container py-3">
            <div class="row g-4 justify-content-center">
                <div class="col-md-3 col-lg-3 col-xl-3 wow fadeInUp" data-wow-delay="0.1s">
                    <div class="service-item rounded">
                        <div class="service-img rounded-top text-center">
                            <a :href="'#'" class="mb-4">Pemerintah Pusat</a>
                            <img :id="'1'" :src="folder_foto + '001.jpg'" class="img-fluid rounded-top w-100 gambar"
                                :alt="'Pemerintah Pusat'" @click="ambilgambar('1')" style="min-height: 250px;max-height: 250px;">
                        </div>
                        <div class="service-content rounded-bottom bg-light p-4">
                            <div class="service-content-inner text-center">
                                <a :href="'#'" class="btn btn-primary rounded-pill text-white py-2 px-4 mb-2" @click="ambilgambar('1')">Lihat </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 col-lg-3 col-xl-3 wow fadeInUp" data-wow-delay="0.1s">
                    <div class="service-item rounded">
                        <div class="service-img rounded-top text-center">
                            <a :href="'#'" class="mb-4">Pemerintah Provinsi</a>
                            <img :id="'1'" :src="folder_foto + '002.jpg'" class="img-fluid rounded-top w-100 gambar"
                                :alt="'Pemerintah Provinsi'" @click="ambilgambar('2')" style="min-height: 250px;max-height: 250px;">
                        </div>
                        <div class="service-content rounded-bottom bg-light p-4">
                            <div class="service-content-inner text-center">
                                <a :href="'#'" class="btn btn-primary rounded-pill text-white py-2 px-4 mb-2" @click="ambilgambar('2')">Lihat </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 col-lg-3 col-xl-3 wow fadeInUp" data-wow-delay="0.1s">
                    <div class="service-item rounded">
                        <div class="service-img rounded-top text-center">
                            <a :href="'#'" class="mb-4">Pemerintah Kabupaten / Kota</a>
                            <img :id="'1'" :src="folder_foto + '003.jpg'" class="img-fluid rounded-top w-100 gambar"
                                :alt="'Pemerintah Kabupaten / Kota'" @click="ambilgambar('3')" style="min-height: 250px;max-height: 250px;">
                        </div>
                        <div class="service-content rounded-bottom bg-light p-4">
                            <div class="service-content-inner text-center">
                                <a :href="'#'" class="btn btn-primary rounded-pill text-white py-2 px-4 mb-2" @click="ambilgambar('3')">Lihat </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Contact End -->
    <a href="#" class="btn btn-dangerbulet btn-lg-square back-to-top text-white"><i class="fa fa-arrow-up"></i></a>
    <Footer />
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import swal from 'sweetalert2';
import CryptoJS from 'crypto-js';
import Top from "./Template/Top.vue";
import Footer from "./Template/Footer.vue";

export default {
    components: {
        Top, Footer, swal
    },
    data() {
        return {
            halamanloading: true,
            tahun: new Date().getFullYear(),
            bahasa: localStorage.getItem("bahasasistem"),
            sourcenya: '',
            folder_foto: process.env.VUE_APP_URL_API + 'parastapainnovation_produk-Gambar?f=',
        };
    },
    methods: {
        ambilgambar(kode) {
            var bytes = encodeURIComponent(CryptoJS.AES.encrypt(kode, 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString());
            window.location.replace("/DetailProduk/" + bytes);
        },
    },
    mounted() {
        this.halamanloading = false;
    },
}
</script>

<style></style>