<template>
    <Top />
    <!-- Spinner Start -->
    <div id="spinner" v-if="halamanloading"
        class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        &nbsp;<img src="../../../../src/assets/img/BerandaNgumahLogo.png" height="40">
        &nbsp;<span class="text-danger">..Loading...</span>
    </div>
    <!-- Spinner End -->
    <!-- Header Start -->
    <div class="container-fluid bg-breadcrumb">
        <div class="container text-center py-5" style="max-width: 900px;">
            <h3 class="text-white display-3 mb-4 wow fadeInDown pmisputih" data-wow-delay="0.1s">
                <span v-if="(bahasa === null) || (bahasa == 'ina')">KONTAK KAMI</span>
                <span v-else>CONTACT US</span>
            </h3>
            <ol class="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s">
                <li class="breadcrumb-item">
                    <a href="/">
                        <span v-if="(bahasa === null) || (bahasa == 'ina')">Beranda</span>
                        <span v-else>Home</span>
                    </a>
                </li>
                <li class="breadcrumb-item active text-primary">
                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Kontak Kami</span>
                    <span v-else>Contact Us</span>
                </li>
            </ol>
        </div>
    </div>
    <!-- Header End -->
    <!-- Contact Start -->
    <div class="container-fluid blog py-2">&nbsp;</div>
    <div class="container-fluid bg-breadcrumbberanda wow fadeInDown">
        <div class="section-title mb-2 wow fadeInDown" data-wow-delay="0.1s">
            <div class="col-lg-12 col-xl-12 wow fadeInRight" data-wow-delay="0.3s">
                <div class="col-lg-12 col-xl-12 wow fadeInRight" data-wow-delay="0.3s">
                    <img src="../../../../src/assets/img/logojateng.png" height="100"><br>
                    <h1 class="text-danger m-0 pmisdanger" style="font-size: x-large;">PEMERINTAH PROVINSI JAWA TENGAH
                    </h1>
                    <span v-if="(bahasa === null) || (bahasa == 'ina')"> Biro Pemerintah, Otonomi Daerah dan Kerjasama Sekretariat Daerah Provinsi Jawa Tengah<br>
                    </span>
                    <span v-if="(bahasa === null) || (bahasa == 'ina')"> Jl. Pahlawan No 9 Gedung A Lantai 11, Kantor Gubernur, Kota Semarang, Jawa Tengah<br>
                    </span>
                    <span v-if="(bahasa === null) || (bahasa == 'ina')"> Telp. (024) 8311174 ext. 340  Email : info@pemotdaks.jatengprov.go.id, biropemotdakerjasama@gmail.com<br>
                    </span><br>
                    <a href="https://wa.me/628122935745" target="_blank"><img src="../../../../src/assets/img/whatsapp.png" height="50" data-toggle="tooltip" data-placement="top" title="Konsultasi via WhatsApp" style="cursor:pointer;"></a><br>
                    <span v-if="(bahasa === null) || (bahasa == 'ina')"> Konsultasi<br>
                    </span>
                    <span v-if="(bahasa === null) || (bahasa == 'ina')"> Senin-Kamis jam 08:00 - 15:00 WIB; Jumat jam 08:00 - 14:00 WIB<br>
                    </span>
                </div>
                <div class="col-lg-12 col-xl-12 wow fadeInRight" data-wow-delay="0.3s">&nbsp;</div>
            </div>
        </div>
    </div>
    <div class="container-fluid blog py-2">
        <div class="container py-2">
            <div class="section-title mb-2 wow fadeInDown" data-wow-delay="0.1s">
                <div class="col-lg-12 col-xl-12 wow fadeInRight" data-wow-delay="0.3s">
                    <div class="rounded h-100">
                        <iframe class="rounded w-100" style="height: 500px;" :src="sourcenya" loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade" @load="loadkontak()"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Contact End -->
    <a href="#" class="btn btn-dangerbulet btn-lg-square back-to-top text-white"><i class="fa fa-arrow-up"></i></a>
    <Footer />
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import swal from 'sweetalert2';

import Top from "./Template/Top.vue";
import Footer from "./Template/Footer.vue";

export default {
    components: {
        Top, Footer, swal
    },
    data() {
        return {
            halamanloading: true,
            tahun: new Date().getFullYear(),
            bahasa: localStorage.getItem("bahasasistem"),
            sourcenya: '',
        };
    },
    methods: {
        loadkontak() {
            this.halamanloading = true;
            const message = this.linknya;
            const iframe = document.querySelector("iframe");
            this.sourcenya = '';
            this.sourcenya = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1980.0651489999057!2d110.41765470076376!3d-6.993931415580023!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e708b5ef6aa79fd%3A0x1bbe9b0a8e2c426a!2sSekretariat%20Daerah%20Provinsi%20Jawa%20Tengah!5e0!3m2!1sid!2sid!4v1682603608248!5m2!1sid!2sid';
            iframe.contentWindow.postMessage(message, "*");
            this.halamanloading = false;
        },
    },
    mounted() {
        this.halamanloading = true;
    },
}
</script>

<style></style>