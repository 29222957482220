<template>
    <div id="myModal" class="modal">
        <span class="close">&times;</span>
        <div id="modal-content">
            <div class="col-sm-12">
                <h1>DATA AGENDA</h1>
            </div>
            <div class="card" style="padding: 20px;">
                <h3>Nama Agenda: {{ agenda }}</h3>
                <h5>Mulai: {{ G_formatDateNonWIB(mulai) }}, {{ waktumulai }} WIB</h5>
                <h5>Selesai: {{ G_formatDateNonWIB(selesai) }}, {{ waktuselesai }} WIB</h5>
                <h3>Pelaksana: {{ pelaksana }}</h3>
                <h5>Hasil Agenda:</h5>
                <span v-html="keterangan">
                </span>
            </div>
        </div>
    </div>
    <Top />
    <!-- Spinner Start -->
    <div id="spinner" v-if="halamanloading"
        class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        &nbsp;<img src="../../../../src/assets/img/BerandaNgumahLogo.png" height="40">
        &nbsp;<span class="text-danger">..Loading...</span>
    </div>
    <!-- Spinner End -->
    <!-- Header Start -->
    <div class="container-fluid bg-breadcrumb">
        <div class="container text-center py-5" style="max-width: 900px;">
            <h3 class="text-white display-3 mb-4 wow fadeInDown pmisputih" data-wow-delay="0.1s">
                <span v-if="(bahasa === null) || (bahasa == 'ina')">AGENDA</span>
                <span v-else>AGENDA</span>
            </h3>
            <ol class="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s">
                <li class="breadcrumb-item">
                    <a href="/">
                        <span v-if="(bahasa === null) || (bahasa == 'ina')">Beranda</span>
                        <span v-else>Home</span>
                    </a>
                </li>
                <li class="breadcrumb-item active text-primary">
                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Agenda</span>
                    <span v-else>Agenda</span>
                </li>
            </ol>
        </div>
    </div>
    <!-- Header End -->
    <!-- Contact Start -->
    <div class="container-fluid blog py-2">&nbsp;</div>
    <div class="container-fluid blog py-2">
        <div class="container py-2">
            <div class="row">
                <div class="col-sm-4 wow fadeInLeft text-justify" data-wow-delay="0.3s">
                    <img src="../../../../src/assets/img/businesspeople.jpg" class="img-fluid w-100"><br>
                    <span>
                        Berisikan data agenda kegiatan, untuk melihat detail agenda silahkan klik pada tanggal yang
                        terisi oleh agenda pada kolom kalender, Data yang disajikan antara lain adalah:
                        <li>Nama Agenda</li>
                        <li>Waktu Agenda</li>
                        <li>Pelaksana Agenda</li>
                        <li>Hasil Agenda</li>
                    </span><br>
                    <img src="../../../../src/assets/img/event.jpg" class="img-fluid w-100">
                </div>
                <div class="col-sm-8 wow fadeInRight" data-wow-delay="0.3s">
                    <div class="rounded h-100">
                        <input type="hidden" class="form-control form-control-sm" id="detail" name="detail"
                            placeholder="Detail">
                        <button type="button" class="btn btn-tool" id="details" @click="details()"
                            style="display: none;"><i class="fas fa-minus text-white"></i></button>
                        <iframe :src="sourcenya" class="col-sm-12 text-center" style="min-height: 110vh;"
                            frameborder="0" id="maps" scrolling="no" @load="loadpeta()"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Contact End -->
    <a href="#" class="btn btn-dangerbulet btn-lg-square back-to-top text-white"><i class="fa fa-arrow-up"></i></a>
    <Footer />
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import swal from 'sweetalert2';
import { ref } from "vue";
import Top from "./Template/Top.vue";
import Footer from "./Template/Footer.vue";
const checkIn = ref(null);
const checkOut = ref(null);

export default {
    components: {
        Top, Footer, swal
    },
    data() {
        return {
            halamanloading: true,
            tahun: new Date().getFullYear(),
            bahasa: localStorage.getItem("bahasasistem"),
            sourcenya: '',
            linknya: process.env.VUE_APP_URL_API,
            ipnya: '',
            datanya: [],
            datapesan: '',
            agenda: '',
            mulai: '',
            selesai: '',
            waktumulai: '',
            waktuselesai: '',
            pelaksana: '',
            keterangan: '',
        };
    },
    methods: {
        loadkontak() {
            this.halamanloading = true;
            const message = this.linknya;
            const iframe = document.querySelector("iframe");
            this.sourcenya = '';
            this.sourcenya = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1980.0651489999057!2d110.41765470076376!3d-6.993931415580023!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e708b5ef6aa79fd%3A0x1bbe9b0a8e2c426a!2sSekretariat%20Daerah%20Provinsi%20Jawa%20Tengah!5e0!3m2!1sid!2sid!4v1682603608248!5m2!1sid!2sid';
            iframe.contentWindow.postMessage(message, "*");
            this.halamanloading = false;
        },
        loadpeta() {
            this.halamanloading = true;
            const message = this.linknya;
            const iframe = document.getElementById("maps");
            this.sourcenya = '';
            this.sourcenya = '/calendar.html';
            iframe.contentWindow.postMessage(message, "*");
            this.halamanloading = false;
        },
        async details() {
            //alert(document.getElementById("detail").value);
            var kodeagenda = document.getElementById("detail").value;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            var random = Math.random();

            await mainAPI.get("parastapainnovationip-Key?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.ipnya = Response.data.randkey;
                    }
                    else {
                        this.ipnya = '';
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datapesan = "";
                console.log(error);
            });

            const mainAPINew = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2" + this.ipnya,
                },
            });

            await mainAPINew.get("parastapainnovation-ShowDetailAgenda?random=" + random + "&id=" + kodeagenda).then(
                Response => {
                    this.datanya = [];
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datanya = Response.data.content.data;
                        this.agenda = Response.data.content.data[0].agenda;
                        this.pelaksana = Response.data.content.data[0].pelaksana;
                        this.mulai = Response.data.content.data[0].mulai;
                        this.selesai = Response.data.content.data[0].selesai;
                        this.waktumulai = Response.data.content.data[0].waktumulai;
                        this.waktuselesai = Response.data.content.data[0].waktuselesai;
                        this.keterangan = atob(Response.data.content.data[0].keterangan);
                    }
                    else {
                        this.datapesan = "";
                        this.datanya = [];
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
            });
            var modal = document.getElementById("myModal");
            // Get the image and insert it inside the modal - use its "alt" text as a caption
            //var img = document.getElementById(id);
            modal.style.display = "block";

            // Get the <span> element that closes the modal
            var span = document.getElementsByClassName("close")[0];

            // When the user clicks on <span> (x), close the modal
            span.onclick = function () {
                modal.style.display = "none";
            }
        },
    },
    mounted() {
        this.halamanloading = true;
    },
}
</script>

<style></style>