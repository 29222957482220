<template>
    <!-- Main Sidebar Container -->
    <aside class="main-sidebar elevation-4 sidebar-dark-marron">
        <a href="#" class="brand-link animation__shake wow fadeInDown"
            style="align-items: center;position: absolute;top: 25px;" data-widget="pushmenu" role="button">
            <center>
                <i class="fas fa-align-right text-white"></i>&nbsp;<img src="../../../assets/img/logojateng.png"
                    alt="Admin Logo" class="animation__shake wow fadeInDown" style="opacity: .8;height: 35px;">
            </center>
        </a>
        <!-- Sidebar -->
        <div class="sidebar wow fadeInLeft">
            <!-- Sidebar Menu -->
            <nav class="mt-2">
                <ul class="nav nav-pills nav-sidebar flex-column nav-flat" data-widget="treeview" role="menu"
                    data-accordion="false">
                    <li class="nav-item">&nbsp;</li>
                    <li class="nav-item">&nbsp;</li>
                    <li class="nav-item">
                        <router-link to="/Dashboard"
                            :class="[(route.name == 'Dashboard' ? 'nav-link active' : 'nav-link')]" id="Dashboard"><i
                                class="nav-icon fas fa-chart-line"></i>
                            <p>DASHBOARD</p>
                        </router-link>
                    </li>
                    <li :class="[((route.name == 'Kerjasama' || route.name == 'ProsesKerjasama' || route.name == 'Potensi'
                        || route.name == 'ProsesPotensi' || route.name == 'PengajuanKerjasama') ? 'nav-item menu-open' : 'nav-item')]">
                        <a href="#" :class="[((route.name == 'Kerjasama' || route.name == 'ProsesKerjasama' || route.name == 'Potensi'
                            || route.name == 'ProsesPotensi' || route.name == 'PengajuanKerjasama') ? 'nav-link active' : 'nav-link')]">
                            <i class="nav-icon fas fa-mail-bulk"></i>
                            <p>
                                DATA DAN POTENSI KERJASAMA
                                <i class="right fas fa-angle-left"></i>
                            </p>
                        </a>
                        <ul class="nav nav-treeview">
                            <li class="nav-item" v-if="(leveluser == 2) || (leveluser == 1) || (leveluser == 3)">
                                <router-link :to="'/Kerjasama'"
                                    :class="[((route.name == 'Kerjasama' || route.name == 'ProsesKerjasama') ? 'nav-link active' : 'nav-link')]"
                                    id="Kerjasama"><i class="nav-icon fab fa-leanpub text-warning2"></i>
                                    <p class="text-warning2">DATA KERJASAMA</p>
                                </router-link>
                            </li>
                            <li class="nav-item" v-if="(leveluser == 2) || (leveluser == 1) || (leveluser == 3)">
                                <router-link :to="'/Potensi'"
                                    :class="[((route.name == 'Potensi' || route.name == 'ProsesPotensi') ? 'nav-link active' : 'nav-link')]"
                                    id="Potensi"><i class="nav-icon fas fa-marker text-warning2"></i>
                                    <p class="text-warning2">POTENSI KERJASAMA</p>
                                </router-link>
                            </li>
                            <li class="nav-item" v-if="(leveluser == 1)">
                                <router-link :to="'/PengajuanKerjasama'"
                                    :class="[((route.name == 'PengajuanKerjasama' || route.name == 'ProsesPengajuanKerjasama') ? 'nav-link active' : 'nav-link')]"
                                    id="PengajuanKerjasama"><i class="nav-icon fas fa-file-import text-warning2"></i>
                                    <p class="text-warning2">PENGAJUAN KERJASAMA</p>
                                </router-link>
                            </li>
                        </ul>
                    </li>
                    <li :class="[((route.name == 'User' || route.name == 'ProsesUser' || route.name == 'Profil'
                        || route.name == 'ProsesProfil' || route.name == 'InfoBeranda'
                        || route.name == 'ProsesInfoBeranda' || route.name == 'Slider'
                        || route.name == 'ProsesSlider' || route.name == 'Sekilas'
                        || route.name == 'Pelaksanaan' || route.name == 'ProsesPelaksanaan'
                        || route.name == 'ProsesSekilas' || route.name == 'Berita'
                        || route.name == 'ProsesBerita' || route.name == 'Pustaka'
                        || route.name == 'ProsesPustaka' || route.name == 'Infografis'
                        || route.name == 'ProsesInfografis' || route.name == 'ProsesIsiGaleri' || route.name == 'Produk'
                        || route.name == 'ProsesProduk' || route.name == 'Agenda'
                        || route.name == 'ProsesAgenda') ? 'nav-item menu-open' : 'nav-item')]"
                        v-if="(leveluser == 1)">
                        <a href="#" :class="[((route.name == 'Data' || route.name == 'Data3' || route.name == 'Data4'
                            || route.name == 'Data5') ? 'nav-link active' : 'nav-link')]" v-if="(leveluser == 1)">
                            <i class="nav-icon fas fa-luggage-cart"></i>
                            <p>
                                PENGATURAN
                                <i class="right fas fa-angle-left"></i>
                            </p>
                        </a>
                        <ul class="nav nav-treeview">
                            <li class="nav-item" v-if="(leveluser == 1)">
                                <router-link to="/Slider"
                                    :class="[((route.name == 'Slider' || route.name == 'ProsesSlider') ? 'nav-link active' : 'nav-link')]"
                                    id="Slider"><i class="nav-icon fas fa-photo-video text-warning2"></i>
                                    <p class="text-warning2">SLIDER GAMBAR</p>
                                </router-link>
                            </li>
                            <li class="nav-item" v-if="(leveluser == 1)">
                                <router-link to="/InfoBeranda"
                                    :class="[((route.name == 'InfoBeranda' || route.name == 'ProsesInfoBeranda') ? 'nav-link active' : 'nav-link')]"
                                    id="InfoBeranda"><i class="nav-icon fas fa-box text-warning2"></i>
                                    <p class="text-warning2">INFO BERANDA</p>
                                </router-link>
                            </li>
                            <li class="nav-item" v-if="(leveluser == 1)" style="display: none;">
                                <router-link to="/Profil"
                                    :class="[((route.name == 'Profil' || route.name == 'ProsesProfil') ? 'nav-link active' : 'nav-link')]"
                                    id="Profil"><i class="nav-icon far fa-address-card text-warning2"></i>
                                    <p class="text-warning2">PROFIL TATA RUANG</p>
                                </router-link>
                            </li>
                            <li class="nav-item" v-if="(leveluser == 1)" style="display: none;">
                                <router-link to="/Sekilas"
                                    :class="[((route.name == 'Sekilas' || route.name == 'ProsesSekilas') ? 'nav-link active' : 'nav-link')]"
                                    id="Sekilas"><i class="nav-icon fas fa-marker text-warning2"></i>
                                    <p class="text-warning2">SEKILAS TATA RUANG</p>
                                </router-link>
                            </li>
                            <li class="nav-item" v-if="(leveluser == 1)" style="display: none;">
                                <router-link to="/Berita"
                                    :class="[((route.name == 'Berita' || route.name == 'ProsesBerita') ? 'nav-link active' : 'nav-link')]"
                                    id="Berita"><i class="nav-icon far fa-list-alt text-warning2"></i>
                                    <p class="text-warning2">BERITA TATA RUANG</p>
                                </router-link>
                            </li>
                            <li class="nav-item" v-if="(leveluser == 1)" style="display: none;">
                                <router-link to="/Pustaka"
                                    :class="[((route.name == 'Pustaka' || route.name == 'ProsesPustaka') ? 'nav-link active' : 'nav-link')]"
                                    id="Pustaka"><i class="nav-icon fab fa-leanpub text-warning2"></i>
                                    <p class="text-warning2">PUSTAKA TATA RUANG</p>
                                </router-link>
                            </li>
                            <li class="nav-item" v-if="(leveluser == 1)">
                                <router-link to="/Infografis"
                                    :class="[((route.name == 'Infografis' || route.name == 'ProsesInfografis' || route.name == 'ProsesIsiGaleri') ? 'nav-link active' : 'nav-link')]"
                                    id="Infografis"><i class="nav-icon fas fa-photo-video text-warning2"></i>
                                    <p class="text-warning2">GALERI</p>
                                </router-link>
                            </li>
                            <li class="nav-item" v-if="(leveluser == 1)" style="display: none;">
                                <router-link to="/Produk"
                                    :class="[((route.name == 'Produk' || route.name == 'ProsesProduk') ? 'nav-link active' : 'nav-link')]"
                                    id="Produk"><i class="nav-icon fab fa-leanpub text-warning2"></i>
                                    <p class="text-warning2">PRODUK HUKUM TATA RUANG</p>
                                </router-link>
                            </li>
                            <li class="nav-item" v-if="(leveluser == 1)" style="display: none;">
                                <router-link to="/Pelaksanaan"
                                    :class="[((route.name == 'Pelaksanaan' || route.name == 'ProsesPelaksanaan') ? 'nav-link active' : 'nav-link')]"
                                    id="Pelaksanaan"><i class="nav-icon fas fa-border-none text-warning2"></i>
                                    <p class="text-warning2">DOKUMEN PELAKSANAAN</p>
                                </router-link>
                            </li>
                            <li class="nav-item" v-if="(leveluser == 1)">
                                <router-link :to="'/Agenda'"
                                    :class="[((route.name == 'Agenda' || route.name == 'ProsesAgenda') ? 'nav-link active' : 'nav-link')]"
                                    id="Agenda"><i class="nav-icon far fa-calendar-alt text-warning2"></i>
                                    <p class="text-warning2">AGENDA</p>
                                </router-link>
                            </li>
                            <li class="nav-item" v-if="(leveluser == 1)">
                                <router-link to="/User"
                                    :class="[((route.name == 'User' || route.name == 'ProsesUser') ? 'nav-link active' : 'nav-link')]"
                                    id="User"><i class="nav-icon fas fa-user-friends text-warning2"></i>
                                    <p class="text-warning2">PENGATURAN PENGGUNA</p>
                                </router-link>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <a href="#" class="nav-link" @click="keluar()">
                            <i class="nav-icon fas fa-sign-out-alt"></i>
                            <p>KELUAR / LOGOUT</p>
                        </a>
                    </li>
                    <li>&nbsp;</li>
                </ul>
            </nav>
            <!-- /.sidebar-menu -->
        </div>
        <!-- /.sidebar -->
    </aside>
</template>

<script>
import { useRoute } from 'vue-router';
import { watch } from 'vue';
import swal from 'sweetalert2';
import CryptoJS from 'crypto-js';
export default {
    components: {
        swal
    },
    setup() {
        const route = useRoute()
        watch(() => route.name, () => {
        });

        return { route };
    },
    data() {
        return {
            tahun: new Date().getFullYear(),
            namauser: JSON.parse(localStorage.getItem("nmusistem")),
            leveluser: JSON.parse(localStorage.getItem("lsistem")),
            folderawal: encodeURIComponent(CryptoJS.AES.encrypt('0', 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString()),
        };
    },
    methods: {
        getOtentifikasi() {
            var time = Date.parse(localStorage.getItem("tsistem"));
            var timeakhir = time + (3 * 60 * 60 * 1000);
            var timesekarang = Date.parse(new Date());
            if (timesekarang > timeakhir) {
                localStorage.removeItem('tokensistem');
                localStorage.removeItem('usistem');
                localStorage.removeItem('lsistem');
                localStorage.removeItem('nmlsistem');
                localStorage.removeItem('emlsistem');
                localStorage.removeItem('nmusistem');
                localStorage.removeItem('tsistem');
                localStorage.removeItem('orsistem');
                localStorage.removeItem('bdlsistem');
                localStorage.removeItem('sbdsistem');
                localStorage.removeItem('jbtsistem');
            }
        },
        pindahawalnya1() {
            var bytes = encodeURIComponent(CryptoJS.AES.encrypt('0', 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString());
            window.location.replace("/Data/" + bytes);
        },
        pindahawalnya3() {
            var bytes = encodeURIComponent(CryptoJS.AES.encrypt('0', 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString());
            window.location.replace("/Data3/" + bytes);
        },
        pindahawalnya4() {
            var bytes = encodeURIComponent(CryptoJS.AES.encrypt('0', 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString());
            window.location.replace("/Data4/" + bytes);
        },
        pindahawalnya5() {
            var bytes = encodeURIComponent(CryptoJS.AES.encrypt('0', 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString());
            window.location.replace("/Data5/" + bytes);
        },
        pindahawalnya6() {
            var bytes = encodeURIComponent(CryptoJS.AES.encrypt('0', 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString());
            window.location.replace("/Data6/" + bytes);
        },
        pindahawalnya7() {
            var bytes = encodeURIComponent(CryptoJS.AES.encrypt('0', 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString());
            window.location.replace("/Data7/" + bytes);
        },
        keluar() {
            swal.fire({
                title: "Konfirmasi",
                text: "Yakin Hendak Keluar Aplikasi?",
                icon: "warning",
                showCancelButton: true,
                cancelButtonText: "Batal",
                confirmButtonColor: "#7e3806",
                cancelButtonColor: "#ffaa05",
                confirmButtonText: "Keluar"
            }).then((result) => {
                if (result.isConfirmed) {
                    this.ClearlocalStorage();
                }
            });

            return false;
        },
    },
    mounted() {
        this.getOtentifikasi();
        if ((localStorage.getItem("tokensistem") == null)) {
            window.location.replace("/Login");
        }
    }
}
</script>

<style></style>