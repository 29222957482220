<template>
    <div class="wrapper">
        <NavBar />
        <Menu />
        <!-- Spinner Start -->
        <div id="spinner" v-if="halamanloading"
            class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
            style="opacity: 0.5;">
            <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <!-- Spinner End -->
        <div class="content-wrapper py-3">
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6 wow fadeInDown">
                            <h5>Pengaturan</h5>
                        </div>
                        <div class="col-sm-6 wow fadeInDown">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a href="/Dashboard">Home </a></li>
                                <li class="breadcrumb-item"><a href="/Kerjasama">Data Kerjasama</a></li>
                                <li class="breadcrumb-item active">Pengaturan Data Kerjasama</li>
                            </ol>
                        </div>
                    </div><!-- /.row -->
                </div><!-- /.container-fluid -->
            </div>
            <!-- /.content-header -->
            <div class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-12 wow fadeInDown">
                            <div class="card cardputih2">
                                <div class="card-header border-transparent">
                                    <h3 class="card-title">Form Input Data Kerjasama</h3>
                                    <div class="card-tools">
                                        <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                            <i class="fas fa-minus"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="card-body p-3">
                                    <ul class="nav nav-tabs justify-content-center" id="custom-tabs-four-tab"
                                        role="tablist">
                                        <li class="nav-item">
                                            <a class="nav-link active" id="custom-tabs-four-home-tab" data-toggle="pill"
                                                href="#custom-tabs-four-home" role="tab"
                                                aria-controls="custom-tabs-four-home" aria-selected="true">
                                                Isian Data Kerjasama
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" id="custom-tabs-four-profile-tab" data-toggle="pill"
                                                href="#custom-tabs-four-profile" role="tab"
                                                aria-controls="custom-tabs-four-profile" aria-selected="false">
                                                Monitoring Data Kerjasama
                                            </a>
                                        </li>
                                    </ul>
                                    <div class="tab-content" id="custom-tabs-four-tabContent">
                                        <div class="tab-pane fade show active" id="custom-tabs-four-home"
                                            role="tabpanel" aria-labelledby="custom-tabs-four-home-tab">
                                            <div class="row">
                                                <div class="col-sm-12">&nbsp;</div>
                                                <div class="col-sm-12">
                                                    <label>Jenis Kerjasama
                                                        <small class="text-info"> (*wajib dipilih)</small>
                                                    </label>
                                                    <v-select :options="jeniskerjasama" :reduce="(label) => label.code"
                                                        label="label" v-model="defaultSelectedjeniskerjasama"
                                                        @update:modelValue="onjeniskerjasamaChange"
                                                        style="background-color: #ffffff;padding-bottom: 10px;"></v-select>
                                                </div>
                                                <div class="col-sm-12" v-if="selectedjeniskerjasama == 1">
                                                    <div class="row">
                                                        <div class="radio col-md-2">
                                                            <label>
                                                                <input type="radio" name="subsubbidang"
                                                                    id="subsubbidang" value="Wajib"
                                                                    v-model="subsubbidang">
                                                                Wajib
                                                            </label>
                                                        </div>
                                                        <div class="radio col-md-2">
                                                            <label>
                                                                <input type="radio" name="subsubbidang"
                                                                    id="subsubbidang" value="Sukarela"
                                                                    v-model="subsubbidang">
                                                                Sukarela
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12">
                                                    <label>Jenis Dokumen
                                                        <small class="text-info"> (*wajib dipilih)</small>
                                                    </label>
                                                    <v-select :options="jenisdokumen" :reduce="(label) => label.code"
                                                        label="label" v-model="defaultSelectedjenisdokumen"
                                                        @update:modelValue="onjenisdokumenChange"
                                                        style="background-color: #ffffff;padding-bottom: 10px;"></v-select>
                                                </div>
                                                <div class="col-sm-12">
                                                    <label>Judul Dokumen Perjanjian
                                                        <small class="text-info"> (*wajib diisi)</small>
                                                    </label>
                                                    <div class="input-group mb-3">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"><i
                                                                    class="fab fa-creative-commons-share"></i></span>
                                                        </div>
                                                        <input type="text" class="form-control form-control-sm"
                                                            id="tentang" name="tentang"
                                                            placeholder="Judul Dokumen Perjanjian" v-model="tentang">
                                                    </div>
                                                </div>
                                                <div class="col-sm-12">
                                                    <label>Mitra Kerjasama
                                                        <small class="text-info"> (*wajib diisi)</small>
                                                    </label>
                                                </div>
                                                <div class="col-sm-12 previous"
                                                    v-for="(mitrasetuju, countermitra) in mitra"
                                                    v-bind:key="countermitra">
                                                    <div class="row" style="padding-bottom: 20px;">
                                                        <div class="col-sm-11">
                                                            <input type="text" :id="'mitra' + (countermitra + 1)"
                                                                :name="'mitra' + (countermitra + 1)"
                                                                class="form-control form-control-sm"
                                                                :placeholder="'Mitra Kerjasama Ke- ' + (countermitra + 1)"
                                                                v-model="mitrasetuju.mitra">
                                                        </div>
                                                        <div class="col-sm-1 text-center">
                                                            <div class="col-sm-12 text-center">
                                                                <button type="button" class="btn btn-primary btn-sm"
                                                                    @click="addMitra"><i
                                                                        class="far fa-plus-square"></i></button>
                                                                <button type="button" class="btn btn-warning btn-sm"
                                                                    @click="deleteMitra(countermitra)"
                                                                    v-if="(countermitra > 0)"><i
                                                                        class="far fa-minus-square"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12">
                                                    <label>Nomor Dokumen
                                                        <small class="text-info"> (*wajib diisi)</small>
                                                    </label>
                                                </div>
                                                <div class="col-sm-12 previous"
                                                    v-for="(dokumensetuju, counterdokumen) in dokumen"
                                                    v-bind:key="counterdokumen">
                                                    <div class="row" style="padding-bottom: 20px;">
                                                        <div class="col-sm-11">
                                                            <input type="text" :id="'dokumen' + (counterdokumen + 1)"
                                                                :name="'dokumen' + (counterdokumen + 1)"
                                                                class="form-control form-control-sm"
                                                                :placeholder="'Nomor Dokumen Ke- ' + (counterdokumen + 1)"
                                                                v-model="dokumensetuju.dokumen">
                                                        </div>
                                                        <div class="col-sm-1 text-center">
                                                            <div class="col-sm-12 text-center">
                                                                <button type="button" class="btn btn-primary btn-sm"
                                                                    @click="addDokumen"><i
                                                                        class="far fa-plus-square"></i></button>
                                                                <button type="button" class="btn btn-warning btn-sm"
                                                                    @click="deleteDokumen(counterdokumen)"
                                                                    v-if="(counterdokumen > 0)"><i
                                                                        class="far fa-minus-square"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!--IKON PETA 1-->
                                                <div class="col-sm-12">
                                                    <label>Pembiayaan
                                                        <small class="text-info"> (*wajib dipilih)</small>
                                                    </label>
                                                    <v-select multiple :options="pembiayaan"
                                                        :reduce="(label) => label.code" label="label"
                                                        @option:selected="onpembiayaanChangeSelect($event)"
                                                        @option:deselected="onpembiayaanChangeDeselect($event)"
                                                        v-model="arraynamapembiayaan"
                                                        style="background-color: #ffffff;padding-bottom: 10px;"></v-select>
                                                </div>
                                                <div class="col-sm-6">
                                                    <label>Tanggal Mulai / Penetapan
                                                        <small class="text-info"> (*wajib diisi)</small>
                                                    </label>
                                                    <div class="input-group mb-3">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"><i
                                                                    class="fab fa-creative-commons-share"></i></span>
                                                        </div>
                                                        <input type="date" class="form-control form-control-sm"
                                                            id="tanggalmulai" name="tanggalmulai"
                                                            placeholder="Tanggal Mulai / Penetapan"
                                                            v-model="tanggalmulai">
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <label>Tanggal Selesai
                                                        <small class="text-info"> (*wajib diisi)</small>
                                                    </label>
                                                    <div class="input-group mb-3">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"><i
                                                                    class="fab fa-creative-commons-share"></i></span>
                                                        </div>
                                                        <input type="date" class="form-control form-control-sm"
                                                            id="tanggalselesai" name="tanggalselesai"
                                                            placeholder="Tanggal Selesai" v-model="tanggalselesai">
                                                    </div>
                                                </div>
                                                <div class="col-sm-12">
                                                    <label>Urusan
                                                        <small class="text-info"> (*wajib dipilih)</small>
                                                    </label>
                                                    <v-select :options="urusan" :reduce="(label) => label.code"
                                                        label="label" v-model="defaultSelectedurusan"
                                                        @update:modelValue="onurusanChange"
                                                        style="background-color: #ffffff;padding-bottom: 10px;"></v-select>
                                                </div>
                                                <div class="col-sm-12">
                                                    <input type="hidden" class="form-control form-control-sm"
                                                        id="filenya_produk" name="filenya_produk"
                                                        placeholder="File Produk">
                                                    <label>Dokumen<small class="text-info"> (*pdf)</small>
                                                        <span v-if="filenya_produk != ''">
                                                            <a :href="folder_foto + filenya_produk"
                                                                v-if="filenya_produk != '' && filenya_produk != null">
                                                                &nbsp;<i class="far fa-file-pdf"></i> Lihat Lampiran
                                                            </a>
                                                        </span>
                                                    </label>
                                                    <div class="col-sm-12">
                                                        <iframe :src="sourcenya" class="col-sm-12 text-center"
                                                            style="min-height: 60vh;" frameborder="0" id="maps"
                                                            scrolling="no" @load="loadpeta()"></iframe>
                                                    </div>
                                                </div>
                                                <!--IKON PETA 1 END-->
                                                <div class="col-sm-12">&nbsp;</div>
                                            </div>
                                            <!-- /.lockscreen-item -->
                                        </div>
                                        <div class="tab-pane fade" id="custom-tabs-four-profile" role="tabpanel"
                                            aria-labelledby="custom-tabs-four-profile-tab">
                                            <div class="row">
                                                <div class="col-sm-12">&nbsp;</div>
                                                <div class="col-sm-12">
                                                    <label>Status Pelaksanaan Kerjasama
                                                        <small class="text-info"> (*wajib dipilih)</small>
                                                    </label>
                                                    <v-select :options="statuskerjasama" :reduce="(label) => label.code"
                                                        label="label" v-model="defaultSelectedstatuskerjasama"
                                                        @update:modelValue="onstatuskerjasamaChange"
                                                        style="background-color: #ffffff;padding-bottom: 10px;"></v-select>
                                                </div>
                                                <div class="col-sm-12">
                                                    <label>Proges Pelaksanaan Kerja Sama</label>
                                                    <div class="row">
                                                        <div class="col-sm-12">
                                                            <quill-editor v-model:value="progres"
                                                                style="height: 25vh;background-color: #ffffff;">
                                                            </quill-editor>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12">&nbsp;</div>
                                                <div class="col-sm-12"><label>Hasil Kerja Sama</label></div>
                                                <div class="col-sm-4">
                                                    <label>Aset</label>
                                                    <div class="row">
                                                        <div class="col-sm-12">
                                                            <quill-editor v-model:value="hasil1"
                                                                style="height: 25vh;background-color: #ffffff;">
                                                            </quill-editor>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-4">
                                                    <label>PAD</label>
                                                    <div class="row">
                                                        <div class="col-sm-12">
                                                            <quill-editor v-model:value="hasil2"
                                                                style="height: 25vh;background-color: #ffffff;">
                                                            </quill-editor>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-4">
                                                    <label>Lain-lain</label>
                                                    <div class="row">
                                                        <div class="col-sm-12">
                                                            <quill-editor v-model:value="hasil3"
                                                                style="height: 25vh;background-color: #ffffff;">
                                                            </quill-editor>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12">&nbsp;</div>
                                                <div class="col-sm-12">Kendala & Tindak Lanjut</div>
                                                <div class="col-sm-6">
                                                    <label>Kendala</label>
                                                    <div class="row">
                                                        <div class="col-sm-12">
                                                            <quill-editor v-model:value="kendala"
                                                                style="height: 25vh;background-color: #ffffff;">
                                                            </quill-editor>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <label>Tindak Lanjut</label>
                                                    <div class="row">
                                                        <div class="col-sm-12">
                                                            <quill-editor v-model:value="tindaklanjut"
                                                                style="height: 25vh;background-color: #ffffff;">
                                                            </quill-editor>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12">&nbsp;</div>
                                                <div class="col-sm-12">
                                                    <label>Keterangan</label>
                                                    <div class="row">
                                                        <div class="col-sm-12">
                                                            <quill-editor v-model:value="keterangan"
                                                                style="height: 25vh;background-color: #ffffff;">
                                                            </quill-editor>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12">
                                                    <input type="hidden" class="form-control form-control-sm"
                                                        id="filenya_monev" name="filenya_monev"
                                                        placeholder="File Monev">
                                                </div>
                                                <!--IKON PETA 1-->
                                                <div class="col-sm-12">
                                                    <label>File Gambar Monev<small class="text-info"> (*jpg)</small>
                                                        <span v-if="filenya_monev != ''">
                                                            <a :href="folder_monev + filenya_monev" target="_blank"
                                                                v-if="filenya_monev != '' && filenya_monev != null">
                                                                &nbsp;<i class="far fa-file-image"></i> Lihat Gambar
                                                            </a>
                                                        </span>
                                                    </label>
                                                    <div class="col-sm-12">
                                                        <iframe :src="sourcenyamonev" class="col-sm-12 text-center"
                                                            style="min-height: 60vh;" frameborder="0" id="mapsmonev"
                                                            scrolling="no" @load="loadpetamonev()"></iframe>
                                                    </div>
                                                </div>
                                                <!--IKON PETA 1 END-->
                                                <div class="col-sm-12">&nbsp;</div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="card-footer p-3">
                                    <button type="button" class="btn btn-sm btn-success3 float-left text-white lebar2"
                                        @click="simpan()" v-if="(secretencData == 'Tambah')" data-toggle="tooltip"
                                        data-placement="left" title="Simpan data.."><i class="fas fa-plus-square"></i>
                                        Simpan</button>
                                    <button type="button" class="btn btn-sm btn-success3 float-left text-white lebar2"
                                        v-if="(secretencData != 'Tambah')" @click="update()" data-toggle="tooltip"
                                        data-placement="left" title="Update data.."><i class="fa fa-check-circle"></i>
                                        Update</button>
                                    <button type="button" class="btn btn-sm btn-danger3 float-left text-white lebar2"
                                        @click="batalproses()" data-toggle="tooltip" data-placement="left"
                                        title="Batalkan proses.."><i class="fas fa-window-close"></i> Batal</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid">&nbsp;</div>
        <div class="container-fluid">&nbsp;</div>
        <Footer />
    </div>
</template>

<script>
import $ from 'jquery';
import axios from 'axios';
import vSelect from 'vue-select';
import swal from 'sweetalert2';
import CryptoJS from 'crypto-js';
import { useRoute } from 'vue-router';
import { watch } from 'vue';
import { quillEditor } from 'vue3-quill';
import Footer from "../../componen/Footer.vue";
import NavBar from "../../componen/NavBar.vue";
import Menu from "../../componen/Menu.vue";

import VueApexCharts from "vue3-apexcharts";
window.Apex.chart = { fontFamily: "Poppins-SemiBold, Arial, sans-serif" };
export default {
    setup() {
        const route = useRoute()
        watch(() => route.name, () => {
        });

        return { route };
    },
    components: {
        Footer, NavBar, Menu, vSelect, swal, quillEditor
    },
    data() {
        return {
            halamanloading: true,
            iduser: JSON.parse(localStorage.getItem("usistem")),
            leveluser: JSON.parse(localStorage.getItem("lsistem")),
            namauser: JSON.parse(localStorage.getItem("nmusistem")),
            kewsistem: JSON.parse(localStorage.getItem("kewsistem")),
            secretencData: decodeURIComponent(CryptoJS.AES.decrypt(this.route.params.id, 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString(CryptoJS.enc.Utf8)),
            filenya_produk: '',
            validasi: false,
            sourcenya: '',
            sourcenyamonev: '',
            linknya: process.env.VUE_APP_URL_API,
            ipnya: '',

            tahun: [],
            datatahun: [],
            defaultSelectedtahun: {
                code: 0,
                label: 'Pilih Data',
                tag: '',
            },
            selectedtahun: '',

            daerah: [],
            datadaerah: [],
            defaultSelecteddaerah: {
                code: 0,
                label: 'Pilih Data',
                tag: '',
            },
            selecteddaerah: '',

            carikatamitra: '',

            jeniskerjasama: [],
            datajeniskerjasama: [],
            defaultSelectedjeniskerjasama: {
                code: 0,
                label: 'Pilih Data',
                tag: '',
            },
            selectedjeniskerjasama: '',

            jenisdokumen: [],
            datajenisdokumen: [],
            defaultSelectedjenisdokumen: {
                code: 0,
                label: 'Pilih Data',
                tag: '',
            },
            selectedjenisdokumen: '',

            urusan: [],
            dataurusan: [],
            defaultSelectedurusan: {
                code: 0,
                label: 'Pilih Data',
                tag: '',
            },
            selectedurusan: '',

            pembiayaan: [],
            datapembiayaan: [],
            defaultSelectedpembiayaan: {
                code: 0,
                label: 'Pilih Data',
                tag: '',
            },
            selectedpembiayaan: '',
            paramselectedpembiayaan: [],
            arraynamapembiayaan: [],

            subsubbidang: '',
            tentang: '',

            countermitra: 0,
            mitra: [
                {
                    previousmitra: '',
                    expirationmitra: ''
                }
            ],

            counterdokumen: 0,
            dokumen: [
                {
                    previousdokumen: '',
                    expirationdokumen: ''
                }
            ],

            tanggalmulai: '',
            tanggalselesai: '',
            folder_foto: process.env.VUE_APP_URL_API + 'parastapainnovation_produk-PDF?f=',
            folder_monev: process.env.VUE_APP_URL_API + 'parastapainnovation_monev-Gambar?f=',
            filenya_monev: '',
            statuskerjasama: [],
            datastatuskerjasama: [],
            defaultSelectedstatuskerjasama: {
                code: 0,
                label: 'Pilih Data',
                tag: '0',
            },
            selectedstatuskerjasama: '',
            progres: '',
            hasil1: '',
            hasil2: '',
            hasil3: '',
            kendala: '',
            tindaklanjut: '',
            keterangan: '',
        }
    },
    methods: {
        addMitra() {
            this.mitra.push({
                previousmitra: '',
                expirationmitra: ''
            })
        },
        deleteMitra(countermitra) {
            this.mitra.splice(countermitra, 1);
        },
        addDokumen() {
            this.dokumen.push({
                previousdokumen: '',
                expirationdokumen: ''
            })
        },
        deleteDokumen(counterdokumen) {
            this.dokumen.splice(counterdokumen, 1);
        },
        loadpeta() {
            this.halamanloading = true;
            const message = this.linknya + 'main/FilesUpload/upload.php|filenya_produk/';
            const iframe = document.getElementById("maps");
            this.sourcenya = '';
            this.sourcenya = '/dragfiles.html';
            iframe.contentWindow.postMessage(message, "*");
            this.halamanloading = false;
        },
        loadpetamonev() {
            this.halamanloading = true;
            const message = this.linknya + 'main/FilesUpload/upload.php|filenya_monev/';
            const iframe = document.getElementById("mapsmonev");
            this.sourcenyamonev = '';
            this.sourcenyamonev = '/dragfiles.html';
            iframe.contentWindow.postMessage(message, "*");
            this.halamanloading = false;
        },
        initialize() {
            //Initialize Select2 Elements
            $(".select2").select2();

            //Initialize Select2 Elements
            $(".select2bs4").select2({
                theme: "bootstrap4",
            });
            $('[data-mask]').inputmask();
            //$('#summernote').summernote();
        },
        getOtentifikasi() {
            var time = Date.parse(localStorage.getItem("tsistem"));
            var timeakhir = time + (3 * 60 * 60 * 1000);
            var timesekarang = Date.parse(new Date());
            if (timesekarang > timeakhir) {
                this.ClearlocalStorage();
            }
            this.halamanloading = false;
        },
        ontabelChange(a) {
            try {
                this.selectedtabel = this.tabel[a].tag;
            }
            catch (err) {
                return;
            }
        },
        batalproses() {
            window.location.replace("/Kerjasama");
        },
        async ambildatareff() {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            var random = Math.random();

            await mainAPI.get("parastapainnovationip-Key?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.ipnya = Response.data.randkey;
                    }
                    else {
                        this.ipnya = '';
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datapesan = [];
                console.log(error);
            });
            this.halamanloading = false;
            this.halamanloading = true;
            const mainAPI2 = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2" + this.ipnya,
                },
            });
            var random = Math.random();
            await mainAPI2.get("parastapainnovationreff-GetTahun?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datatahun = [];
                        this.tahun = [];
                        this.datatahun = Response.data.content.data;
                        this.datatahun.forEach((item) => {
                            this.tahun.push({
                                label: item.tahun,
                                code: this.datatahun.indexOf(item) + 1,
                                tag: item.tahun,
                            });
                        });
                        this.tahun.unshift({
                            label: 'Pilih Data',
                            code: 0,
                            tag: '',
                        });
                    }
                    else {
                        this.datatahun = [];
                        this.tahun = [];
                    }
                    this.halamanloading = false;

                }
            ).catch(error => {
                this.datatahun = [];
                this.tahun = [];
                this.halamanloading = false;
            });

            await mainAPI2.get("parastapainnovationreff-GetKabkotdanJateng?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datadaerah = [];
                        this.daerah = [];
                        this.datadaerah = Response.data.content.data;
                        this.datadaerah.forEach((item) => {
                            this.daerah.push({
                                label: item.NAMA,
                                code: this.datadaerah.indexOf(item) + 1,
                                tag: item.KD_KAB,
                            });
                        });
                        this.daerah.unshift({
                            label: 'Pilih Data',
                            code: 0,
                            tag: '',
                        });
                    }
                    else {
                        this.datadaerah = [];
                        this.daerah = [];
                    }
                    this.halamanloading = false;

                }
            ).catch(error => {
                this.datadaerah = [];
                this.daerah = [];
                this.halamanloading = false;
            });

            await mainAPI2.get("parastapainnovationreff-GetJenisKerjasama?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datajeniskerjasama = [];
                        this.jeniskerjasama = [];
                        this.datajeniskerjasama = Response.data.content.data;
                        this.datajeniskerjasama.forEach((item) => {
                            this.jeniskerjasama.push({
                                label: item.nama,
                                code: this.datajeniskerjasama.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.jeniskerjasama.unshift({
                            label: 'Pilih Data',
                            code: 0,
                            tag: '',
                        });
                    }
                    else {
                        this.datajeniskerjasama = [];
                        this.jeniskerjasama = [];
                    }
                    this.halamanloading = false;

                }
            ).catch(error => {
                this.datajeniskerjasama = [];
                this.jeniskerjasama = [];
                this.halamanloading = false;
            });

            await mainAPI2.get("parastapainnovationreff-GetJenisDokumen?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datajenisdokumen = [];
                        this.jenisdokumen = [];
                        this.datajenisdokumen = Response.data.content.data;
                        this.datajenisdokumen.forEach((item) => {
                            this.jenisdokumen.push({
                                label: item.nama,
                                code: this.datajenisdokumen.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.jenisdokumen.unshift({
                            label: 'Pilih Data',
                            code: 0,
                            tag: '',
                        });
                    }
                    else {
                        this.datajenisdokumen = [];
                        this.jenisdokumen = [];
                    }
                    this.halamanloading = false;

                }
            ).catch(error => {
                this.datajenisdokumen = [];
                this.jenisdokumen = [];
                this.halamanloading = false;
            });

            await mainAPI2.get("parastapainnovationreff-GetUrusan?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.dataurusan = [];
                        this.urusan = [];
                        this.dataurusan = Response.data.content.data;
                        this.dataurusan.forEach((item) => {
                            this.urusan.push({
                                label: item.nama,
                                code: this.dataurusan.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.urusan.unshift({
                            label: 'Pilih Data',
                            code: 0,
                            tag: '',
                        });
                    }
                    else {
                        this.dataurusan = [];
                        this.urusan = [];
                    }
                    this.halamanloading = false;

                }
            ).catch(error => {
                this.dataurusan = [];
                this.urusan = [];
                this.halamanloading = false;
            });

            await mainAPI2.get("parastapainnovationreff-GetPembiayaan?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datapembiayaan = [];
                        this.pembiayaan = [];
                        this.datapembiayaan = Response.data.content.data;
                        this.datapembiayaan.forEach((item) => {
                            this.pembiayaan.push({
                                label: item.nama,
                                code: this.datapembiayaan.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                    }
                    else {
                        this.datapembiayaan = [];
                        this.pembiayaan = [];
                    }
                    this.halamanloading = false;

                }
            ).catch(error => {
                this.datapembiayaan = [];
                this.pembiayaan = [];
                this.halamanloading = false;
            });

            await mainAPI2.get("parastapainnovationreff-GetStatusKerjasama?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datastatuskerjasama = [];
                        this.statuskerjasama = [];
                        this.datastatuskerjasama = Response.data.content.data;
                        this.datastatuskerjasama.forEach((item) => {
                            this.statuskerjasama.push({
                                label: item.nama,
                                code: this.datastatuskerjasama.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.statuskerjasama.unshift({
                            label: 'Pilih Data',
                            code: 0,
                            tag: '',
                        });
                    }
                    else {
                        this.datastatuskerjasama = [];
                        this.statuskerjasama = [];
                    }
                    this.halamanloading = false;

                }
            ).catch(error => {
                this.datastatuskerjasama = [];
                this.statuskerjasama = [];
                this.halamanloading = false;
            });
        },
        ontahunChange(a) {
            try {
                this.selectedtahun = this.tahun[a].tag;
            }
            catch (err) {
                return;
            }
        },
        ondaerahChange(a) {
            try {
                this.selecteddaerah = this.daerah[a].tag;
            }
            catch (err) {
                return;
            }
        },
        onjeniskerjasamaChange(a) {
            try {
                this.selectedjeniskerjasama = this.jeniskerjasama[a].tag;
            }
            catch (err) {
                return;
            }
        },
        onjenisdokumenChange(a) {
            try {
                this.selectedjenisdokumen = this.jenisdokumen[a].tag;
            }
            catch (err) {
                return;
            }
        },
        onurusanChange(a) {
            try {
                this.selectedurusan = this.urusan[a].tag;
            }
            catch (err) {
                return;
            }
        },
        onstatuskerjasamaChange(a) {
            try {
                this.selectedstatuskerjasama = this.statuskerjasama[a].tag;
            }
            catch (err) {
                return;
            }
        },
        onpembiayaanChangeSelect(events) {
            this.paramselectedpembiayaan = [];
            for (let i = 0; i < events.length; i++) {
                this.paramselectedpembiayaan.push(parseInt(events[i].tag));
            }
            //alert(JSON.stringify(this.paramselectedpembiayaan));
        },
        onpembiayaanChangeDeselect(events) {
            //alert(JSON.stringify(events));
            //this.paramselectedpembiayaan = [];
            var temp = [];
            temp.push(events);
            //alert(JSON.stringify(temp));
            var yghilang = '';
            for (let i = 0; i < temp.length; i++) {
                yghilang = parseInt(temp[i].tag);
            }
            //alert(yghilang);
            //alert(this.paramselectedpembiayaan);
            var temparray = [];
            for (let i = 0; i < this.paramselectedpembiayaan.length; i++) {
                //alert(this.paramselectedpembiayaan[i]);
                if (this.paramselectedpembiayaan[i] !== yghilang) {
                    temparray.push(parseInt(this.paramselectedpembiayaan[i]));
                }
            }
            this.paramselectedpembiayaan = temparray;
            //alert(JSON.stringify(this.paramselectedpembiayaan))
        },
        async AmbilDataSingel() {
            this.halamanloading = true;
            var mainAPIDataSingel = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPIDataSingel.get("parastapainnovation_kerjasama-GetKerjasamaByID?id=" + this.secretencData + "&random=" + random).then(
                Response => {
                    this.selectedjeniskerjasama = Response.data.content.data[0].kodejeniskerjasama;
                    this.defaultSelectedjeniskerjasama = {
                        code: Response.data.content.data[0].kodejeniskerjasama,
                        label: Response.data.content.data[0].JENISKERJASAMA,
                        tag: Response.data.content.data[0].kodejeniskerjasama,
                    };
                    this.subsubbidang = Response.data.content.data[0].subsubbidang;
                    this.selectedjenisdokumen = Response.data.content.data[0].kodejenisdokumen;
                    this.defaultSelectedjenisdokumen = {
                        code: Response.data.content.data[0].kodejenisdokumen,
                        label: Response.data.content.data[0].JENISDOKUMEN,
                        tag: Response.data.content.data[0].kodejenisdokumen,
                    };
                    this.tentang = Response.data.content.data[0].judul;
                    var mitra = Response.data.content.data[0].pihak1;
                    var jum_mitra = mitra.split("|");
                    this.mitra = [];
                    for (let i = 0; i < jum_mitra.length; i++) {
                        this.mitra.push({
                            previousmitra: '',
                            expirationmitra: '',
                            "mitra": jum_mitra[i],
                        });
                    }

                    var dokumen = Response.data.content.data[0].nomorkontrak;
                    var jum_dokumen = dokumen.split("|");
                    this.dokumen = [];
                    for (let i = 0; i < jum_dokumen.length; i++) {
                        this.dokumen.push({
                            previousdokumen: '',
                            expirationdokumen: '',
                            "dokumen": jum_dokumen[i],
                        });
                    }

                    this.paramselectedpembiayaan = [];
                    var biaya = Response.data.content.data[0].pembiayaan.split(",")
                    for (let i = 0; i < biaya.length; i++) {
                        if (biaya[i] != 'NaN') {
                            this.paramselectedpembiayaan.push(parseInt(biaya[i]));
                        }

                    }
                    //alert(Response.data.content.data[0].namapembiayaan);
                    //this.arraynamapembiayaan = Response.data.content.data[0].namapembiayaan;
                    Response.data.content.data[0].namapembiayaan.forEach((item) => {
                        this.arraynamapembiayaan.push({
                            label: item.Nama,
                            code: item.index,
                            tag: item.id,
                        });
                        if (item == 0) {
                            this.defaultSelectedpembiayaan = {
                                label: item.Nama,
                                code: item.index,
                                tag: item.id
                            };
                            this.selectedpembiayaan = item.id;
                        }
                    });

                    this.tanggalmulai = Response.data.content.data[0].tanggalmulai;
                    this.tanggalselesai = Response.data.content.data[0].tanggalselesai;
                    this.selectedurusan = Response.data.content.data[0].kodeurusan;
                    this.defaultSelectedurusan = {
                        code: Response.data.content.data[0].kodeurusan,
                        label: Response.data.content.data[0].URUSAN,
                        tag: Response.data.content.data[0].kodeurusan,
                    };
                    this.selectedstatuskerjasama = Response.data.content.data[0].statuskerjasama;
                    this.defaultSelectedstatuskerjasama = {
                        code: Response.data.content.data[0].statuskerjasama,
                        label: Response.data.content.data[0].namastatuskerjasama,
                        tag: Response.data.content.data[0].statuskerjasama,
                    };
                    this.filenya_produk = Response.data.content.data[0].filenya_produk;
                    this.progres = Response.data.content.data[0].progres;
                    this.hasil1 = Response.data.content.data[0].hasil1;
                    this.hasil2 = Response.data.content.data[0].hasil2;
                    this.hasil3 = Response.data.content.data[0].hasil3;
                    this.kendala = Response.data.content.data[0].kendala;
                    this.tindaklanjut = Response.data.content.data[0].tindaklanjut;
                    this.keterangan = Response.data.content.data[0].keterangan;
                    document.getElementById('filenya_produk').value = Response.data.content.data[0].filenya_produk;

                    this.filenya_monev = Response.data.content.data[0].filenya_monev;
                    document.getElementById('filenya_monev').value = Response.data.content.data[0].filenya_monev;
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })
        },
        validasidata() {
            //alert(this.subsubbidang);
            //return false;
            if (this.selectedjeniskerjasama == '' || this.selectedjeniskerjasama == null || this.selectedjeniskerjasama == undefined) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Pilih Jenis Kerjasama',
                });
                return false;
            }
            if (this.selectedjeniskerjasama == 1 && this.subsubbidang == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Pilih Jenis Kerjasama Wajib / Sukarela',
                });
                return false;
            }
            if (this.selectedjenisdokumen == '' || this.selectedjenisdokumen == null || this.selectedjenisdokumen == undefined) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Pilih Jenis Dokumen',
                });
                return false;
            }

            if (document.getElementById('tentang').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Judul Kerjasama',
                });
                document.getElementById('tentang').focus();
                return false;
            }

            if (this.mitra.length == 0) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Mitra Kerjasama',
                });
                return false;
            }
            if (this.dokumen.length == 0) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Nomor Dokumen Kerjasama',
                });
                return false;
            }

            if (this.paramselectedpembiayaan.length == 0) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Pilih Pembiayaan',
                });
                return false;
            }

            if (document.getElementById('tanggalmulai').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Tanggal Mulai',
                });
                document.getElementById('tanggalmulai').focus();
                return false;
            }

            if (document.getElementById('tanggalselesai').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Tanggal Selesai',
                });
                document.getElementById('tanggalselesai').focus();
                return false;
            }

            if (this.selectedurusan == '' || this.selectedurusan == null || this.selectedurusan == undefined) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Pilih Urusan',
                });
                return false;
            }

            if (document.getElementById('filenya_produk').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Dokumen',
                });
                return false;
            }
            this.validasi = true;
            return;
        },
        simpan() {
            this.validasidata();
            if (this.validasi == false) {
                return false;
            }

            var mitra = "";
            for (let i = 1; i <= this.mitra.length; i++) {
                if (mitra == "") {
                    mitra = mitra + document.getElementById("mitra" + i).value;
                }
                else {
                    mitra = mitra + "|" + document.getElementById("mitra" + i).value;
                }
            }

            var dokumen = "";
            for (let i = 1; i <= this.dokumen.length; i++) {
                if (dokumen == "") {
                    dokumen = dokumen + document.getElementById("dokumen" + i).value;
                }
                else {
                    dokumen = dokumen + "|" + document.getElementById("dokumen" + i).value;
                }
            }

            var fd = new FormData();
            fd.append("jeniskegiatan", this.selectedjeniskerjasama);
            fd.append("subsubbidang", this.subsubbidang);
            fd.append("bidang", this.selectedjenisdokumen);
            fd.append("tentang", this.tentang);
            fd.append("pihak1", mitra);
            fd.append("nomorkontrak", dokumen);
            fd.append("pembiayaan", this.paramselectedpembiayaan);
            fd.append("tanggalmulai", this.tanggalmulai);
            fd.append("tanggalselesai", this.tanggalselesai);
            fd.append("subbidang", this.selectedurusan);
            fd.append("filenya_produk", document.getElementById('filenya_produk').value.trim());
            fd.append("filenya_monev", document.getElementById('filenya_monev').value.trim());
            fd.append("statuskerjasama", this.selectedstatuskerjasama);
            fd.append("progres", this.progres);
            fd.append("hasil1", this.hasil1);
            fd.append("hasil2", this.hasil2);
            fd.append("hasil3", this.hasil3);
            fd.append("kendala", this.kendala);
            fd.append("tindaklanjut", this.tindaklanjut);
            fd.append("keterangan", this.keterangan);
            fd.append("createdby", JSON.parse(localStorage.getItem("usistem")));
            fd.append("kabkot", JSON.parse(localStorage.getItem("kewsistem")));


            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    "Content-Type": "form-data",
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            mainAPI.post("parastapainnovation_kerjasama-AddKerjasama", fd).then(
                Response => {
                    if (Response.data.response == 'error') {
                        swal.fire('Peringatan', Response.data.message, 'error');
                    }
                    else {
                        swal.fire('Informasi', 'Data telah tersimpan..', 'success').then(function () {
                            window.location.replace("/Kerjasama");
                        });
                    }
                }
            ).catch(function (error) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: error
                });
            }
            )
        },
        update() {
            this.validasidata();
            if (this.validasi == false) {
                return false;
            }

            var mitra = "";
            for (let i = 1; i <= this.mitra.length; i++) {
                if (mitra == "") {
                    mitra = mitra + document.getElementById("mitra" + i).value;
                }
                else {
                    mitra = mitra + "|" + document.getElementById("mitra" + i).value;
                }
            }

            var dokumen = "";
            for (let i = 1; i <= this.dokumen.length; i++) {
                if (dokumen == "") {
                    dokumen = dokumen + document.getElementById("dokumen" + i).value;
                }
                else {
                    dokumen = dokumen + "|" + document.getElementById("dokumen" + i).value;
                }
            }

            var fd = new FormData();
            fd.append("jeniskegiatan", this.selectedjeniskerjasama);
            fd.append("subsubbidang", this.subsubbidang);
            fd.append("bidang", this.selectedjenisdokumen);
            fd.append("tentang", this.tentang);
            fd.append("pihak1", mitra);
            fd.append("nomorkontrak", dokumen);
            fd.append("pembiayaan", this.paramselectedpembiayaan);
            fd.append("tanggalmulai", this.tanggalmulai);
            fd.append("tanggalselesai", this.tanggalselesai);
            fd.append("subbidang", this.selectedurusan);
            fd.append("filenya_produk", document.getElementById('filenya_produk').value.trim());
            fd.append("filenya_monev", document.getElementById('filenya_monev').value.trim());
            fd.append("statuskerjasama", this.selectedstatuskerjasama);
            fd.append("progres", this.progres);
            fd.append("hasil1", this.hasil1);
            fd.append("hasil2", this.hasil2);
            fd.append("hasil3", this.hasil3);
            fd.append("kendala", this.kendala);
            fd.append("tindaklanjut", this.tindaklanjut);
            fd.append("keterangan", this.keterangan);
            fd.append("kabkot", JSON.parse(localStorage.getItem("kewsistem")));
            fd.append("updatedby", JSON.parse(localStorage.getItem("usistem")));
            fd.append("id", this.secretencData);
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    "Content-Type": "form-data",
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            mainAPI.post("parastapainnovation_kerjasama-UpdateKerjasama", fd).then(
                Response => {
                    if (Response.data.response == 'error') {
                        swal.fire('Peringatan', Response.data.message, 'error');
                    }
                    else {
                        swal.fire('Informasi', 'Data telah terupdate..', 'success').then(function () {
                            window.location.replace("/Kerjasama");
                        });
                    }
                }
            ).catch(function (error) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: error
                });
            }
            )
            return false;
        },
    },
    mounted() {
        this.getOtentifikasi();
        this.initialize();
        this.ambildatareff();
        if (this.secretencData != 'Tambah') {
            this.AmbilDataSingel();
        }
    }
}
</script>
<style></style>