<template>
    <Top />
    <!-- Spinner Start -->
    <div id="spinner" v-if="halamanloading"
        class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        &nbsp;<img src="../../../../src/assets/img/BerandaNgumahLogo.png" height="40">
        &nbsp;<span class="text-danger">..Loading...</span>
    </div>
    <!-- Spinner End -->
    <!-- Header Start -->
    <div class="container-fluid bg-breadcrumb">
        <div class="container text-center py-5" style="max-width: 900px;">
            <h3 class="text-white display-3 mb-4 wow fadeInDown pmisputih" data-wow-delay="0.1s">
                <span v-if="(bahasa === null) || (bahasa == 'ina')">PENGENDALIAN PEMANFAATAN RUANG</span>
                <span v-else>PENGENDALIAN PEMANFAATAN RUANG</span>
            </h3>

        </div>
    </div>
    <!-- Header End -->
    <!-- Contact Start -->
    <div class="container-fluid blog py-2">&nbsp;</div>
    <div class="container-fluid py-3">
        <div class="container py-3">
            <div class="row g-5 align-items-center">
                <div class="col-lg-5 wow fadeInLeft" data-wow-delay="0.4s">
                    <img src="../../../../src/assets/img/download.jpg" class="img-fluid rounded-circle w-60 h-60"
                        alt="Image">
                </div>
                <div class="col-lg-7 wow fadeInRight" data-wow-delay="0.4s">
                    <div class="section-title text-start">
                        <h4 class="sub-title pe-3 mb-0 pmistitelberita">
                            <a href="/DaftarRegulasi">PELAKSANAAN</a>
                        </h4>
                        <p class="mb-4">
                            <a href="/DaftarRegulasi" style="color: black;">
                                Dokumen Pelaksanaan berisi informasi yang disusun dalam bentuk tertulis, tercetak, atau
                                digital yang
                                menyampaikan data, informasi, atau fakta untuk tujuan tertentu. Dokumen bisa berupa
                                surat, laporan, kontrak, arsip, atau berbagai jenis informasi lain yang disimpan dan
                                digunakan untuk keperluan administrasi, komunikasi, atau referensi.
                                Dokumen ini adalah dokumen yang memuat rencana dan ketentuan mengenai penggunaan ruang
                                atau
                                wilayah dalam suatu daerah. Dokumen ini bertujuan untuk mengatur pengelolaan ruang
                                secara terencana, terarah, dan berkelanjutan, dengan memperhatikan berbagai faktor
                                seperti aspek sosial, ekonomi, lingkungan, serta budaya.
                            </a>
                        </p>
                    </div>
                </div>
            </div>
            <div class="container-fluid blog py-2">&nbsp;</div>
            <div class="row g-5 align-items-center">
                <div class="col-lg-7 wow fadeInLeft" data-wow-delay="0.4s">
                    <div class="section-title text-start">
                        <h4 class="sub-title pe-3 mb-0 pmistitelberita">
                            <a href="https://tataruang.pusdataru.jatengprov.go.id/sippr/" target="_blank">SIPPR (Sistem
                                Informasi Pengendalian Pemanfaatan Ruang)</a>
                        </h4>
                        <a href="https://tataruang.pusdataru.jatengprov.go.id/sippr/" target="_blank" style="color: black;">
                            Sistem Informasi Pengendalian Pemanfaatan Ruang (SIPPR) adalah sebuah sistem informasi
                            berbasis GIS yang dirancang untuk memfasilitasi kegiatan pengendalian pemanfaatan ruang,
                            khususnya dalam menyediakan basis data pengenaan sanksi administratif yang berasal dari
                            temuan dari petugas yang membidangi pengendalian pemanfaatan ruang dan/atau pengawasan
                            penataan ruang, hasil penilaian KKPR, hasil pengawasan penataan ruang, audit tata ruang,
                            ataupun rekomendasi forum penataan ruang.
                        </a>
                    </div>
                </div>
                <div class="col-lg-5 wow fadeInRight" data-wow-delay="0.4s">
                    <img src="../../../../src/assets/img/download1.jpg" class="img-fluid rounded-circle w-60 h-60"
                        alt="Image">
                </div>
            </div>
        </div>
    </div>

    <!-- Contact End -->
    <a href="#" class="btn btn-dangerbulet btn-lg-square back-to-top text-white"><i class="fa fa-arrow-up"></i></a>
    <Footer />
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import swal from 'sweetalert2';

import Top from "./Template/Top.vue";
import Footer from "./Template/Footer.vue";

export default {
    components: {
        Top, Footer, swal
    },
    data() {
        return {
            halamanloading: true,
            tahun: new Date().getFullYear(),
            bahasa: localStorage.getItem("bahasasistem"),
            sourcenya: '',
        };
    },
    methods: {
        loadkontak() {
            this.halamanloading = true;
            const message = this.linknya;
            const iframe = document.querySelector("iframe");
            this.sourcenya = '';
            this.sourcenya = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3960.399301076007!2d110.39384567499688!3d-6.962134093038324!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e70f4c6a1903aeb%3A0x12730ab5d16ca5d0!2sDinas%20Pekerjaan%20Umum%20Sumber%20Daya%20Air%20Dan%20Penataan%20Ruang%20Provinsi%20Jawa%20Tengah!5e0!3m2!1sid!2sid!4v1723676434945!5m2!1sid!2sid';
            iframe.contentWindow.postMessage(message, "*");
            this.halamanloading = false;
        },
    },
    mounted() {
        this.halamanloading = false;
    },
}
</script>

<style></style>