import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Beranda',
    component: () => import('../../components/views/Publik/Beranda.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/DataProfil',
    name: 'DataProfil',
    component: () => import('../../components/views/Publik/Profil.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/DaftarSekilas',
    name: 'DaftarSekilas',
    component: () => import('../../components/views/Publik/Sekilas.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/DaftarBerita',
    name: 'DaftarBerita',
    component: () => import('../../components/views/Publik/Berita.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/DaftarInfografis',
    name: 'DaftarInfografis',
    component: () => import('../../components/views/Publik/Infografis.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/DaftarPustaka',
    name: 'DaftarPustaka',
    component: () => import('../../components/views/Publik/Pustaka.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/DaftarRegulasi',
    name: 'DaftarRegulasi',
    component: () => import('../../components/views/Publik/DaftarRegulasi.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/DaftarProduk',
    name: 'DaftarProduk',
    component: () => import('../../components/views/Publik/Produk.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/DetailProduk/:id',
    name: 'DetailProduk',
    component: () => import('../../components/views/Publik/DetailProduk.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Login',
    name: 'Login',
    component: () => import('../../components/views/Publik/Login.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/KontakKami',
    name: 'KontakKami',
    component: () => import('../../components/views/Publik/KontakKami.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/DaftarAgenda',
    name: 'DaftarAgenda',
    component: () => import('../../components/views/Publik/DaftarAgenda.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/TataCara',
    name: 'TataCara',
    component: () => import('../../components/views/Publik/TataCara.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Tajam',
    name: 'Tajam',
    component: () => import('../../components/views/Publik/Tajam.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Mitra',
    name: 'Mitra',
    component: () => import('../../components/views/Publik/Mitra.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/DataSikejam',
    name: 'DataSikejam',
    component: () => import('../../components/views/Publik/DataSikejam.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/DaftarAlbum',
    name: 'DaftarAlbum',
    component: () => import('../../components/views/Publik/DaftarAlbum.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/DaftarGaleri/:id',
    name: 'DaftarGaleri',
    component: () => import('../../components/views/Publik/DaftarGaleri.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Dokumen',
    name: 'Dokumen',
    component: () => import('../../components/views/Publik/Dokumen.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Pengajuan',
    name: 'Pengajuan',
    component: () => import('../../components/views/Publik/Pengajuan.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Progres',
    name: 'Progres',
    component: () => import('../../components/views/Publik/Progres.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/GIS/:id',
    name: 'GIS',
    component: () => import('../../components/views/Publik/GIS.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/DaftarPengedalian',
    name: 'DaftarPengedalian',
    component: () => import('../../components/views/Publik/DaftarPengedalian.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/DaftarRTH',
    name: 'DaftarRTH',
    component: () => import('../../components/views/Publik/DaftarRTH.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/DaftarRTR',
    name: 'DaftarRTR',
    component: () => import('../../components/views/Publik/DaftarRTR.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Dashboard',
    name: 'Dashboard',
    component: () => import('../../components/views/Dashboard/Dashboard.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Slider',
    name: 'Slider',
    component: () => import('../../components/views/Backoffice/Slider/Slider.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesSlider/:id',
    name: 'ProsesSlider',
    component: () => import('../../components/views/Backoffice/Slider/ProsesSlider.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Infografis',
    name: 'Infografis',
    component: () => import('../../components/views/Backoffice/Infografis/Infografis.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesInfografis/:id',
    name: 'ProsesInfografis',
    component: () => import('../../components/views/Backoffice/Infografis/ProsesInfografis.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesIsiGaleri/:id',
    name: 'ProsesIsiGaleri',
    component: () => import('../../components/views/Backoffice/Infografis/ProsesIsiGaleri.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/InfoBeranda',
    name: 'InfoBeranda',
    component: () => import('../../components/views/Backoffice/InfoBeranda/InfoBeranda.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesInfoBeranda/:id',
    name: 'ProsesInfoBeranda',
    component: () => import('../../components/views/Backoffice/InfoBeranda/ProsesInfoBeranda.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Profil',
    name: 'Profil',
    component: () => import('../../components/views/Backoffice/Profil/Profil.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesProfil/:id',
    name: 'ProsesProfil',
    component: () => import('../../components/views/Backoffice/Profil/ProsesProfil.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Sekilas',
    name: 'Sekilas',
    component: () => import('../../components/views/Backoffice/Sekilas/Sekilas.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesSekilas/:id',
    name: 'ProsesSekilas',
    component: () => import('../../components/views/Backoffice/Sekilas/ProsesSekilas.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Berita',
    name: 'Berita',
    component: () => import('../../components/views/Backoffice/Berita/Berita.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesBerita/:id',
    name: 'ProsesBerita',
    component: () => import('../../components/views/Backoffice/Berita/ProsesBerita.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Pustaka',
    name: 'Pustaka',
    component: () => import('../../components/views/Backoffice/Pustaka/Pustaka.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesPustaka/:id',
    name: 'ProsesPustaka',
    component: () => import('../../components/views/Backoffice/Pustaka/ProsesPustaka.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Produk',
    name: 'Produk',
    component: () => import('../../components/views/Backoffice/Produk/Produk.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesProduk/:id',
    name: 'ProsesProduk',
    component: () => import('../../components/views/Backoffice/Produk/ProsesProduk.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Pelaksanaan',
    name: 'Pelaksanaan',
    component: () => import('../../components/views/Backoffice/Pelaksanaan/Pelaksanaan.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesPelaksanaan/:id',
    name: 'ProsesPelaksanaan',
    component: () => import('../../components/views/Backoffice/Pelaksanaan/ProsesPelaksanaan.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/User',
    name: 'User',
    component: () => import('../../components/views/Backoffice/User/User.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesUser/:id',
    name: 'ProsesUser',
    component: () => import('../../components/views/Backoffice/User/ProsesUser.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Data/:id',
    name: 'Data',
    component: () => import('../../components/views/Backoffice/Data/Data.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Data3/:id',
    name: 'Data3',
    component: () => import('../../components/views/Backoffice/Data/Data3.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Data4/:id',
    name: 'Data4',
    component: () => import('../../components/views/Backoffice/Data/Data4.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Data5/:id',
    name: 'Data5',
    component: () => import('../../components/views/Backoffice/Data/Data5.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Data6/:id',
    name: 'Data6',
    component: () => import('../../components/views/Backoffice/Data/Data6.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Data7/:id',
    name: 'Data7',
    component: () => import('../../components/views/Backoffice/Data/Data7.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/KategoriData',
    name: 'KategoriData',
    component: () => import('../../components/views/Backoffice/KategoriData/KategoriData.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesKategoriData/:id',
    name: 'ProsesKategoriData',
    component: () => import('../../components/views/Backoffice/KategoriData/ProsesKategoriData.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/LayerPeta',
    name: 'LayerPeta',
    component: () => import('../../components/views/Backoffice/LayerPeta/LayerPeta.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesLayerPeta/:id',
    name: 'ProsesLayerPeta',
    component: () => import('../../components/views/Backoffice/LayerPeta/ProsesLayerPeta.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Kerjasama',
    name: 'Kerjasama',
    component: () => import('../../components/views/Backoffice/Kerjasama/Kerjasama.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesKerjasama/:id',
    name: 'ProsesKerjasama',
    component: () => import('../../components/views/Backoffice/Kerjasama/ProsesKerjasama.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/PengajuanKerjasama',
    name: 'PengajuanKerjasama',
    component: () => import('../../components/views/Backoffice/PengajuanKerjasama/PengajuanKerjasama.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesPengajuanKerjasama/:id',
    name: 'ProsesPengajuanKerjasama',
    component: () => import('../../components/views/Backoffice/PengajuanKerjasama/ProsesPengajuanKerjasama.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Potensi',
    name: 'Potensi',
    component: () => import('../../components/views/Backoffice/Potensi/Potensi.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesPotensi/:id',
    name: 'ProsesPotensi',
    component: () => import('../../components/views/Backoffice/Potensi/ProsesPotensi.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Agenda',
    name: 'Agenda',
    component: () => import('../../components/views/Backoffice/Agenda/Agenda.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesAgenda/:id',
    name: 'ProsesAgenda',
    component: () => import('../../components/views/Backoffice/Agenda/ProsesAgenda.vue'),
    meta: {
      transition: "zoom",
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  mode: 'history',
  routes: routes,
  linkActiveClass: 'active',
})

export default router;
