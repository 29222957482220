<template>
    <Top />
    <!-- Spinner Start -->
    <div id="spinner" v-if="halamanloading"
        class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        &nbsp;<img src="../../../../src/assets/img/BerandaNgumahLogo.png" height="40">
        &nbsp;<span class="text-danger">..Loading...</span>
    </div>
    <!-- Spinner End -->
    <!-- Header Start -->
    <div id="myModal" class="modal">
        <span class="close">&times;</span>
        <img class="modal-content" id="img01">
        <div id="caption"></div>
    </div>
    <div class="container-fluid bg-breadcrumb">
        <div class="container text-center py-5" style="max-width: 900px;">
            <h3 class="text-white display-3 mb-4 wow fadeInDown pmisputih" data-wow-delay="0.1s">
                <span v-if="(bahasa === null) || (bahasa == 'ina')">GALERI</span>
                <span v-else>GALLERY</span>
            </h3>
            <ol class="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s">
                <li class="breadcrumb-item">
                    <a href="/">
                        <span v-if="(bahasa === null) || (bahasa == 'ina')">Beranda</span>
                        <span v-else>Home</span>
                    </a>
                </li>
                <li class="breadcrumb-item active text-primary">
                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Galeri</span>
                    <span v-else>Gallery</span>
                </li>
            </ol>
        </div>
    </div>
    <!-- Header End -->
    <div class="container-fluid blog py-5">
        <div class="container py-5">
            <div class="section-title mb-5 wow fadeInUp" data-wow-delay="0.1s"
                style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInUp;">
                <div class="sub-style">
                    <h1 class="sub-title px-3 mb-0 labelpmis" v-if="(bahasa === null) || (bahasa == 'ina')">
                        DAFTAR ALBUM GALERI
                    </h1>
                    <h1 class="sub-title px-3 mb-0 labelpmis" v-else>
                        Gallery Album List
                    </h1>
                </div>
                <p class="mb-0" v-if="(bahasa === null) || (bahasa == 'ina')">
                    Berisikan daftar galeri dan dokumentasi kegiatan
                </p>
                <p class="mb-0" v-else>
                    Contains a list of galleries and documentation of activities
                </p>
            </div>
            <div class="row g-4 justify-content-center">
                <div class="col-sm-12 float-left pb-2 px-3 wow fadeInDown">
                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Jumlah Data Galeri:</span> <span v-else>Number
                        of Gallery Data:</span> {{ G_numFormat(jumlahdata) }}
                    <span style="float: right;">
                        <span v-if="(bahasa === null) || (bahasa == 'ina')">Halaman ke: </span> <span v-else>Page no:
                        </span><select v-model="halaman" @change="onChangeHalaman($event)">
                            <option v-for="index in jumlahhalaman" :key="index">
                                {{ index }}</option>
                        </select> <span v-if="(bahasa === null) || (bahasa == 'ina')"> dari </span> <span v-else> from
                        </span> {{ jumlahhalaman }}
                    </span>
                </div>
                <div class="col-sm-11 float-left wow fadeInDown">
                    <input type="text" v-model="carikata" class="form-control form-control-sm"
                        placeholder="Masukkan Kata Kunci Pencarian">
                </div>
                <div class="col-sm-1 float-left wow fadeInDown">
                    <button type="button" class="btn btn-success3 btn-sm text-white" @click="caridata()"
                        data-toggle="tooltip" data-placement="left" title="Cari data..">
                        <i class="fas fa-search"></i>
                    </button>
                </div>
                <div class="col-md-3 col-lg-3 col-xl-3 wow fadeInUp" data-wow-delay="0.1s"
                    style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInUp;"
                    v-for="(beranda, urutberanda) in databeranda" :key="urutberanda">
                    <div class="blog-item rounded">
                        <div class="blog-img gmbpmis">
                            <img :id="beranda.id"
                                :src="folder_foto + beranda.filenya_berita" class="img-fluid w-100"
                                :alt="beranda.kontenfull" @click="ambilgambar(beranda.id)" style="min-height: 200px;max-height: 200px;">
                        </div>
                        <div class="blog-centent p-4">
                            <a href="javascript:void(0)" class="pmisjudulberita" @click="ambilgambar(beranda.id)" data-toggle="tooltip" data-placement="bottom"
                            title="Lihat Isi Album..">
                                {{ beranda.nama }}
                            </a>
                            
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <a href="#" class="btn btn-dangerbulet btn-lg-square back-to-top text-white"><i class="fa fa-arrow-up"></i></a>
    <Footer />
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import swal from 'sweetalert2';
import CryptoJS from 'crypto-js';
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'

import Top from "./Template/Top.vue";
import Footer from "./Template/Footer.vue";

export default {
    components: {
        Top, Footer, swal, Carousel, Slide, Pagination, Navigation,
    },
    data() {
        return {
            halamanloading: true,
            bahasa: localStorage.getItem("bahasasistem"),
            tabaktif: encodeURIComponent(CryptoJS.AES.encrypt('', 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString()),
            datapesan: '',
            carikata: '',
            datamaster: [],
            tahun: new Date().getFullYear(),
            slides: [],
            breakpoints: {
                // 700px and up
                700: {
                    itemsToShow: 1,
                    snapAlign: 'center',
                },
                // 1024 and up
                1024: {
                    itemsToShow: 1,
                    snapAlign: 'start',
                },
            },
            bahasa: localStorage.getItem("bahasasistem"),
            databeranda: [],
            folder_foto: process.env.VUE_APP_URL_API + 'parastapainnovation_galeri-Gambar?f=',
            jumlahdata: 0,
            jumlahhalaman: 0,
            mulai: 0,
            halaman: 1,
            ipnya: '',
        };
    },
    methods: {
        ambilgambar(id) {
            var bytes = encodeURIComponent(CryptoJS.AES.encrypt(id, 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString());
            //alert(bytes);
            window.location.replace("/DaftarGaleri/" + bytes);
        },
        async caridata() {
            this.halamanloading = true;
            var random = Math.random();
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });

            await mainAPI.get("parastapainnovationip-Key?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.ipnya = Response.data.randkey;
                    }
                    else {
                        this.ipnya = '';
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datapesan = "";
                console.log(error);
            });

            const mainAPINew = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2" + this.ipnya,
                },
            });

            await mainAPINew.get("parastapainnovation-AlbumGaleri?random=" + random + "&nama=" + this.carikata + "&project_id=" + this.project_id).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        var datasementara = [];
                        this.databeranda = [];
                        datasementara = Response.data.content.data;
                        datasementara.forEach((item) => {
                            this.databeranda.push({
                                id: item.id,
                                nama: item.nama,
                                tanggal: item.tanggal,
                                filenya_berita: item.file_name,
                            });
                        });
                        this.jumlahdata = Response.data.content.jumlahdata;
                        this.jumlahhalaman = Response.data.content.jumlahhalaman;
                        this.mulai = Response.data.content.mulai;
                        if (this.mulai == 0) {
                            this.halaman = 1;
                        }
                        else {
                            this.halaman = (this.mulai / 20) + 1;
                        }
                    }
                    else {
                        this.jumlahdata = 0;
                        this.jumlahhalaman = 0;
                        this.mulai = 0;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datapesan = [];
                this.datamaster = [];
                this.slides = [];
                console.log(error);
                this.halamanloading = false;
            });
        },
        onChangeHalaman(event) {
            this.pindahhalaman(event.target.value);
        },
        async pindahhalaman(halamannya) {
            this.halamanloading = true;
            var random = Math.random();
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });

            await mainAPI.get("parastapainnovationip-Key?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.ipnya = Response.data.randkey;
                    }
                    else {
                        this.ipnya = '';
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datapesan = "";
                console.log(error);
            });

            const mainAPINew = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2" + this.ipnya,
                },
            });
            await mainAPINew.get("parastapainnovation-AlbumGaleri?random=" + random + "&halaman=" + halamannya + "&nama=" + this.carikata + "&project_id=" + this.project_id).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        var datasementara = [];
                        this.databeranda = [];
                        datasementara = Response.data.content.data;
                        datasementara.forEach((item) => {
                            this.databeranda.push({
                                id: item.id,
                                nama: item.nama,
                                tanggal: item.tanggal,
                                filenya_berita: item.file_name,
                            });
                        });
                        this.jumlahdata = Response.data.content.jumlahdata;
                        this.jumlahhalaman = Response.data.content.jumlahhalaman;
                        this.mulai = Response.data.content.mulai;
                        if (this.mulai == 0) {
                            this.halaman = 1;
                        }
                        else {
                            this.halaman = (this.mulai / 20) + 1;
                        }
                    }
                    else {
                        this.jumlahdata = 0;
                        this.jumlahhalaman = 0;
                        this.mulai = 0;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datapesan = [];
                this.datamaster = [];
                this.slides = [];
                console.log(error);
                this.halamanloading = false;
            });
        },
        async ambildata() {
            this.halamanloading = true;
            var random = Math.random();

            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });

            await mainAPI.get("parastapainnovationip-Key?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.ipnya = Response.data.randkey;
                    }
                    else {
                        this.ipnya = '';
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datapesan = "";
                console.log(error);
            });

            const mainAPINew = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2" + this.ipnya,
                },
            });

            await mainAPINew.get("parastapainnovation-AlbumGaleri?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    //alert(this.datapesan);
                    if (this.datapesan == 'data diketemukan') {
                        var datasementara = [];
                        this.databeranda = [];
                        datasementara = Response.data.content.data;
                        datasementara.forEach((item) => {
                            this.databeranda.push({
                                id: item.id,
                                nama: item.nama,
                                tanggal: item.tanggal,
                                filenya_berita: item.file_name,
                            });
                        });
                        this.jumlahdata = Response.data.content.jumlahdata;
                        this.jumlahhalaman = Response.data.content.jumlahhalaman;
                        this.mulai = Response.data.content.mulai;
                        if (this.mulai == 0) {
                            this.halaman = 1;
                        }
                        else {
                            this.halaman = (this.mulai / 20) + 1;
                        }
                    }
                    else {
                        this.jumlahdata = 0;
                        this.jumlahhalaman = 0;
                        this.mulai = 0;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datapesan = [];
                this.datamaster = [];
                this.slides = [];
                //console.log(error);
                this.halamanloading = false;
            });
        },
    },
    mounted() {
        this.ambildata();
    },
}
</script>

<style></style>