<template>
    <div id="myModal" class="modal">
        <span class="close">&times;</span>
        <div id="modal-content" v-if="pilihan == 'potensi'">
            <div class="col-sm-12">
                <h1>POTENSI KERJASAMA</h1>
            </div>
            <div class="card cardputih" style="padding: 20px;">
                <template v-for="(datalist, urutlist) in datanyapotensi" :key="urutlist">
                    <h5>{{ datalist.KABKOT }}</h5>
                    <span>Alamat web: <a :href="datalist.alamatweb" target="_blank"> {{ datalist.alamatweb }}</a></span>
                    <span>Potensi Kerjasama: </span>
                    <span v-html="datalist.potensi">
                    </span>
                    <span>Hasil Pemetaan: </span><br>
                    <img :src="folder_potensi + datalist.filenya_potensi" class="img-fluid w-100">
                </template>
            </div>
        </div>
        <div id="modal-content" v-if="pilihan == 'data'">
            <div class="col-sm-12">
                <h1>STATISTIK</h1>
            </div>
            <div class="col-sm-12">
                <BarChartKerjasama type="bar" :options="chartOptions" :series="series" height="500">
                </BarChartKerjasama>
            </div>
            <div class="card cardputih">
                <div class="card-header border-transparent">
                    <h1>DATA</h1>
                    <div class="card-tools">
                        <button type="button" class="btn btn-tool" data-card-widget="collapse">
                            <i class="fas fa-minus"></i>
                        </button>
                    </div>
                    <div class="col-sm-12 float-left pb-2">&nbsp;</div>
                    <div class="col-sm-12 float-left pb-2 px-3">
                        Jumlah Data {{ G_numFormat(jumlahdata) }}
                        <span style="float: right;">
                            <span>Halaman ke: </span>
                            <select v-model="halaman" @change="onChangeHalaman($event)">
                                <option v-for="index in jumlahhalaman" :key="index">
                                    {{ index }}</option>
                            </select>
                            <span> dari </span> {{ jumlahhalaman }}
                        </span>
                    </div>
                    <div class="col-sm-12 float-left pb-2">

                    </div>
                </div>
                <div class="card-body p-2">
                    <div class="table-responsive table-wrapper">
                        <table class="table m-0" id="tabeldata" style="font-size: small;">
                            <thead>
                                <tr>
                                    <th style="width: 50px;">No</th>
                                    <th style="width: 150px;">Daerah</th>
                                    <th style="width: 250px;">Mitra Kerjasama</th>
                                    <th style="width: 250px;">Judul Kerjasama</th>
                                    <th style="width: 250px;">Jenis Kerjasama</th>
                                    <th style="width: 150px;">Tanggal Mulai</th>
                                    <th style="width: 50px;">Jangka Waktu (Tahun)</th>
                                    <th style="width: 50px;">Dokumen</th>
                                </tr>
                                <tr>
                                    <th colspan="2">
                                    </th>
                                    <th>
                                        <input type="text" v-model="mitra" class="form-control form-control-sm"
                                            placeholder="Masukkan Kata Kunci Pencarian" @keyup="ambildata()">
                                    </th>
                                    <th>
                                        <input type="text" v-model="judul" class="form-control form-control-sm"
                                            placeholder="Masukkan Kata Kunci Pencarian" @keyup="ambildata()">
                                    </th>
                                    <th>
                                        <input type="text" v-model="jenis" class="form-control form-control-sm"
                                            placeholder="Masukkan Kata Kunci Pencarian" @keyup="ambildata()">
                                    </th>
                                    <th colspan="3"></th>
                                </tr>
                            </thead>
                            <template v-for="(datalist, urutlist) in datanya" :key="urutlist">
                                <tbody>
                                    <tr>
                                        <td class="text-center">
                                            {{ (urutlist + 1) }}
                                        </td>
                                        <td class="text-justify">
                                            {{ (datalist.KABKOT) }}
                                        </td>
                                        <td class="text-left">
                                            <li v-for="(datadetail, urutdetail) in datalist.MITRA" :key="urutdetail">
                                                {{ datadetail.Mitra }}
                                            </li>
                                        </td>
                                        <td class="text-justify">
                                            {{ (datalist.JUDUL) }}
                                        </td>
                                        <td class="text-justify">
                                            {{ (datalist.JENISKERJASAMA) }}
                                        </td>
                                        <td class="text-center">
                                            {{ G_formatDateNonWIB(datalist.tanggalmulai) }}
                                        </td>
                                        <td class="text-center">
                                            {{ (datalist.jangkawaktu) }}
                                        </td>
                                        <td class="text-center">
                                            <a :href="folder_foto + datalist.lampiran"><i
                                                    class="far fa-file-pdf"></i></a>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                            <tbody v-if="(datapesan == 'data kosong')">
                                <tr>
                                    <label>Data masih kosong</label>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="card-footer p-3">

                </div>
            </div>
        </div>
    </div>
    <Top />
    <!-- Spinner Start -->
    <div id="spinner" v-if="halamanloading"
        class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        &nbsp;<img src="../../../../src/assets/img/BerandaNgumahLogo.png" height="40">
        &nbsp;<span class="text-danger">..Loading...</span>
    </div>
    <!-- Spinner End -->
    <!-- Header Start -->
    <div class="container-fluid bg-breadcrumbpeta">

    </div>
    <!-- Header End -->
    <div class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12">
                    <div class="custom-control custom-radio" style="display: none;">
                        <input class="custom-control-input custom-control-input-warning" type="radio" id="customRadioJ"
                            name="customRadioLokasi" value="jateng" @click="loadpeta()">
                        <label for="customRadioJ" class="custom-control-label" style="color: #000000;">Jawa
                            Tengah</label>
                    </div>&nbsp;&nbsp;
                    <div class="custom-control custom-radio" style="display: none;">
                        <input class="custom-control-input custom-control-input-warning" type="radio" id="customRadioK"
                            name="customRadioLokasi" value="kabkot" checked @click="loadpeta()">
                        <label for="customRadioK" class="custom-control-label" style="color: #000000;">Kabupaten /
                            Kota</label>
                    </div>
                    <input type="hidden" class="form-control form-control-sm" id="detail" name="detail"
                        placeholder="Detail">
                    <input type="hidden" class="form-control form-control-sm" id="Latitude" name="Latitude"
                        placeholder="Latitude">
                    <input type="hidden" class="form-control form-control-sm" id="Longitude" name="Longitude"
                        placeholder="Longitude">
                    <button type="button" class="btn btn-tool" id="details" @click="details()" style="display: none;"><i
                            class="fas fa-minus text-white"></i></button>
                    <button type="button" class="btn btn-tool" id="detailspotensi" @click="detailspotensi()"
                        style="display: none;"><i class="fas fa-minus text-white"></i></button>
                    <button type="button" class="btn btn-tool" id="kosong" @click="datakosong()"
                        style="display: none;"><i class="fas fa-minus text-white"></i></button>
                    <button type="button" class="btn btn-tool" id="koordinat" @click="koordinatkosong()"
                        style="display: none;"><i class="fas fa-minus text-white"></i></button>
                    <iframe :src="sourcenya" class="col-sm-12 text-center" style="min-height: 120vh;" frameborder="0"
                        id="maps" scrolling="no" @load="loadpeta()"></iframe>
                </div>
            </div>
        </div>
    </div>
    <!-- Header End -->
    <a href="#" class="btn btn-dangerbulet btn-lg-square back-to-top text-white"><i class="fa fa-arrow-up"></i></a>
    <Footer />
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import swal from 'sweetalert2';
import CryptoJS from 'crypto-js';
import VueApexCharts from "vue3-apexcharts";
window.Apex.chart = { fontFamily: "Poppins-SemiBold, Arial, sans-serif" };
import { useRoute } from 'vue-router';
import { watch } from 'vue';
import Top from "./Template/Top.vue";
import Footer from "./Template/Footer.vue";

export default {
    setup() {
        const route = useRoute()
        watch(() => route.name, () => {
        });

        return { route };
    },
    components: {
        Top, Footer, swal, BarChartKerjasama: VueApexCharts
    },
    data() {
        return {
            halamanloading: true,
            pilihan: '',
            showHuman: false,
            tahun: new Date().getFullYear(),
            linknya: process.env.VUE_APP_URL_API,
            sourcenya: '',
            sourcenyamapdetails: '',
            secretencData: decodeURIComponent(CryptoJS.AES.decrypt(this.route.params.id, 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString(CryptoJS.enc.Utf8)),
            datapesan: '',
            datanya: [],
            datanyapotensi: [],
            carikata: '',
            jumlahdata: 0,
            jumlahhalaman: 0,
            mulai: 0,
            halaman: 1,
            folder_foto: process.env.VUE_APP_URL_API + 'parastapainnovation_produk-PDF?f=',
            folder_potensi: process.env.VUE_APP_URL_API + 'parastapainnovation_potensi-Gambar?f=',
            kabkot: '',
            mitra: '',
            judul: '',
            jenis: '',
            ipnya: '',
            series: [],
            chartOptions: {
                chart: {
                    type: 'bar',
                    height: 350
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '55%',
                        endingShape: 'rounded',
                        dataLabels: {
                            position: 'top', // top, center, bottom
                        },
                    },
                },
                dataLabels: {
                    enabled: true,
                    offsetY: -20,
                    style: {
                        fontSize: '5px',
                        fontWeight: 'bold',
                        colors: undefined
                    },
                    background: {
                        enabled: true,
                        foreColor: '#000',
                        padding: 4,
                        borderRadius: 2,
                        borderWidth: 1,
                        borderColor: '#000',
                        opacity: 0.3,
                        dropShadow: {
                            enabled: false,
                            top: 1,
                            left: 1,
                            blur: 1,
                            color: '#000',
                            opacity: 0.45
                        }
                    },
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                xaxis: {
                    categories: [],
                },
                yaxis: {
                    title: {
                        text: 'Jumlah Kerjasama'
                    }
                },
                fill: {
                    opacity: 1
                },
                tooltip: {
                    enabled: true,
                    formatter: function (value) {
                        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                    }
                },
            },
        };
    },
    methods: {
        kembali() {
            window.location.replace("/");
        },
        rubahisi() {
            if (this.showHuman == false) {
                this.showHuman = true;
            }
            else {
                this.showHuman = false;
            }
        },
        loadpeta() {
            this.halamanloading = true;
            var pilih = 'kabkot';
            if (document.getElementById('customRadioK').checked == true) {
                pilih = 'kabkot';
            }
            if (document.getElementById('customRadioJ').checked == true) {
                pilih = 'jateng';
            }
            //alert(pilih);
            const message = this.linknya + '|' + pilih;
            const iframe = document.getElementById("maps");
            this.sourcenya = '';
            this.sourcenya = '/map.html';
            iframe.contentWindow.postMessage(message, "*");
            this.halamanloading = false;
        },
        koordinatkosong() {
            swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000
            }).fire({
                icon: 'warning',
                title: 'Koordinat belum diisi..',
            });
            $('.swal2-container').css("z-index", '999999');
        },
        datakosong() {
            swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000
            }).fire({
                icon: 'warning',
                title: 'Data Layer Gagal Diunduh..',
            });
            $('.swal2-container').css("z-index", '999999');
        },
        onChangeHalaman(event) {
            this.pindahhalaman(event.target.value);
        },
        async pindahhalaman(halamannya) {
            this.halamanloading = true;
            var kodekabkot = document.getElementById("detail").value;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            var random = Math.random();

            await mainAPI.get("parastapainnovationip-Key?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.ipnya = Response.data.randkey;
                    }
                    else {
                        this.ipnya = '';
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datapesan = "";
                console.log(error);
            });

            const mainAPINew = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2" + this.ipnya,
                },
            });

            await mainAPINew.get("sikejam-Data?random=" + random + "&kabkot=" + this.kabkot + "&mitra=" + this.mitra + "&judul=" + this.judul + "&jenis=" + this.jenis + "&halaman=" + halamannya + "&kodekabkot=" + kodekabkot).then(
                Response => {
                    this.datanya = [];
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datanya = Response.data.content.data;
                        this.jumlahdata = Response.data.content.jumlahdata;
                        this.jumlahhalaman = Response.data.content.jumlahhalaman;
                        this.mulai = Response.data.content.mulai;
                        if (this.mulai == 0) {
                            this.halaman = 1;
                        }
                        else {
                            this.halaman = (this.mulai / 20) + 1;
                        }
                    }
                    else {
                        this.datanya = [];
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datapesan = "";
                console.log(error);
            });
        },
        async detailspotensi() {
            //alert(document.getElementById("detail").value);
            var kodekabkot = document.getElementById("detail").value;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            var random = Math.random();

            await mainAPI.get("parastapainnovationip-Key?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.ipnya = Response.data.randkey;
                    }
                    else {
                        this.ipnya = '';
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datapesan = "";
                console.log(error);
            });

            const mainAPINew = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2" + this.ipnya,
                },
            });

            await mainAPINew.get("sikejam-Potensi?random=" + random + "&kodekabkot=" + kodekabkot).then(
                Response => {
                    this.datanyapotensi = [];
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datanyapotensi = Response.data.content.data;
                    }
                    else {
                        this.datapesan = "";
                        this.datanyapotensi = [];
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
            });
            this.pilihan = 'potensi';
            var modal = document.getElementById("myModal");
            // Get the image and insert it inside the modal - use its "alt" text as a caption
            //var img = document.getElementById(id);
            modal.style.display = "block";

            // Get the <span> element that closes the modal
            var span = document.getElementsByClassName("close")[0];

            // When the user clicks on <span> (x), close the modal
            span.onclick = function () {
                modal.style.display = "none";
                this.pilihan = '';
            }
        },
        async details() {
            //alert(document.getElementById("detail").value);
            var kodekabkot = document.getElementById("detail").value;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            var random = Math.random();

            await mainAPI.get("parastapainnovationip-Key?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.ipnya = Response.data.randkey;
                    }
                    else {
                        this.ipnya = '';
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datapesan = "";
                console.log(error);
            });

            const mainAPINew = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2" + this.ipnya,
                },
            });

            await mainAPINew.get("sikejam-Data?random=" + random + "&kabkot=" + this.kabkot + "&mitra=" + this.mitra + "&judul=" + this.judul + "&jenis=" + this.jenis + "&kodekabkot=" + kodekabkot).then(
                Response => {
                    this.datanya = [];
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datanya = Response.data.content.data;
                        this.jumlahdata = Response.data.content.jumlahdata;
                        this.jumlahhalaman = Response.data.content.jumlahhalaman;
                        this.mulai = Response.data.content.mulai;
                        if (this.mulai == 0) {
                            this.halaman = 1;
                        }
                        else {
                            this.halaman = (this.mulai / 20) + 1;
                        }
                    }
                    else {
                        this.datapesan = "";
                        this.datanya = [];
                        this.jumlahdata = 0;
                        this.jumlahhalaman = 0;
                        this.mulai = 0;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
            });

            await mainAPINew.get("sikejam-Grafik?random=" + random + "&kodekabkot=" + kodekabkot).then(
                Response => {
                    this.datapesan = Response.data.message;
                    this.chartOptions = {
                        xaxis: {
                            categories: [],
                        },
                    };
                    this.series = [];
                    var datagrafik = [];
                    var seriestahun = [];

                    var datajumlah = [];
                    if (this.datapesan == 'data diketemukan') {
                        datagrafik = Response.data.content.tahun;
                        datajumlah = Response.data.content.data;
                        for (let i = 0; i < datagrafik.length; i++) {
                            seriestahun.push(datagrafik[i].TAHUN);
                        }
                        this.chartOptions = {
                            xaxis: {
                                categories: seriestahun,
                            },
                        };
                        //alert(JSON.stringify(datajumlah));
                        for (let i = 0; i < datajumlah.length; i++) {
                            this.series.push({
                                name: datajumlah[i].Jenis,
                                data: datajumlah[i].Jumlah,
                            });
                        }
                    }
                    else {
                        this.datapesan = "";
                        this.series = [];
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
            });

            this.pilihan = 'data';

            var modal = document.getElementById("myModal");
            // Get the image and insert it inside the modal - use its "alt" text as a caption
            //var img = document.getElementById(id);
            modal.style.display = "block";

            // Get the <span> element that closes the modal
            var span = document.getElementsByClassName("close")[0];

            // When the user clicks on <span> (x), close the modal
            span.onclick = function () {
                modal.style.display = "none";
                this.pilihan = '';
            }
        },
    },
    mounted() {
        this.halamanloading = true;
    },
}
</script>

<style></style>