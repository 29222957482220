<template>
    <div class="container-fluid bg-dark px-5 d-none d-lg-block" style="background-color: #7e3806 !important;">
        <div class="row gx-0 align-items-center" style="height: 45px;">
            <div class="col-lg-12 text-center text-lg-start mb-lg-0">
                <div class="d-flex flex-wrap">
                    <a href="#" class="text-light me-4">Sistem Informasi Kerja Sama Daerah Provinsi Jawa Tengah (SiKejam Provinsi Jateng)</a>
                </div>
            </div>
        </div>
    </div>
    <!-- Topbar End -->
    <div class="container-fluid position-relative p-0">
        <nav class="navbar navbar-expand-lg navbar-light bg-white px-4 px-lg-5 py-3 py-lg-0">
            <a href="/" class="navbar-brand p-0">
                <img src="../../../../../src/assets/img/berandaNgumahNew.png" height="100">
                <!-- <img src="img/logo.png" alt="Logo"> -->
            </a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                <span class="fa fa-bars"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarCollapse">
                <div class="navbar-nav ms-auto py-0">
                    <a href="/"
                        :class="[((route.name == 'Beranda') ? 'nav-item nav-link active' : 'nav-item nav-link')]">
                        BERANDA
                    </a>
                    <a href="/DaftarAgenda"
                        :class="[((route.name == 'DaftarAgenda') ? 'nav-item nav-link active' : 'nav-item nav-link')]">
                        AGENDA
                    </a>
                    <a href="/DaftarAlbum"
                        :class="[((route.name == 'DaftarAlbum') || (route.name == 'DaftarGaleri') ? 'nav-item nav-link active' : 'nav-item nav-link')]">
                        GALERI
                    </a>
                    <a :href="'/GIS/' + bytes"
                        :class="[((route.name == 'GIS') ? 'nav-item nav-link active' : 'nav-item nav-link')]">
                        POTENSI
                    </a>
                    <a href="/Dokumen"
                        :class="[((route.name == 'Dokumen') ? 'nav-item nav-link active' : 'nav-item nav-link')]">
                        DOKUMEN
                    </a>
                    <div class="nav-item dropdown">
                        <a href="#" data-bs-toggle="dropdown"
                            :class="[(((route.name == 'TataCara') || (route.name == 'Pengajuan')
                                || (route.name == 'Progres')) ? 'nav-link active dropdown-toggle' : 'nav-link dropdown-toggle')]">
                            LAYANAN
                        </a>
                        <div class="dropdown-menu m-0">
                            <a :href="'/TataCara'"
                                :class="[((route.name == 'TataCara') ? 'dropdown-item active' : 'dropdown-item')]">
                                TATA CARA PENGAJUAN KERJASAMA
                            </a>
                            <a :href="'/Pengajuan'"
                                :class="[((route.name == 'Pengajuan') ? 'dropdown-item active' : 'dropdown-item')]">
                                PENGAJUAN KERJASAMA
                            </a>
                            <a :href="'/Progres'"
                                :class="[((route.name == 'Progres') ? 'dropdown-item active' : 'dropdown-item')]">
                                PROGRES PENGAJUAN
                            </a>
                        </div>
                    </div>
                    <a href="/Mitra"
                        :class="[((route.name == 'Mitra') ? 'nav-item nav-link active' : 'nav-item nav-link')]">
                        MITRA
                    </a>
                    <a href="/DataSikejam"
                        :class="[((route.name == 'DataSikejam') ? 'nav-item nav-link active' : 'nav-item nav-link')]">
                        DATA
                    </a>
                    <a href="/Tajam"
                        :class="[((route.name == 'Tajam') ? 'nav-item nav-link active' : 'nav-item nav-link')]">
                        TAJAM
                    </a>
                    <a href="/KontakKami"
                        :class="[((route.name == 'KontakKami') ? 'nav-item nav-link active' : 'nav-item nav-link')]">
                        KONTAK KAMI
                    </a>
                </div>
            </div>
        </nav>
    </div>
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import { useRoute } from 'vue-router';
import { watch } from 'vue';
import swal from 'sweetalert2';
import CryptoJS from 'crypto-js';

export default {
    setup() {
        const route = useRoute()
        watch(() => route.name, () => {
        });

        return { route };
    },
    components: {
        swal
    },
    data() {
        return {
            tabaktif: encodeURIComponent(CryptoJS.AES.encrypt('', 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString()),
            tahun: new Date().getFullYear(),
            bahasa: localStorage.getItem("bahasasistem"),
            bytes: encodeURIComponent(CryptoJS.AES.encrypt('Kosong', 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString()),
        };
    },
    methods: {
        gantibahasa(bahasanya) {
            localStorage.setItem("bahasasistem", bahasanya);
            location.reload();
        },
    },
    mounted() {
        localStorage.removeItem('bahasasistem');
    },
}
</script>

<style></style>